import api from './api';

export const changeImage = async (clientID: any, locationID: any, data: any) => {
	try {
		const fileData = {
			file: data,
		};
		const res = await api
			.patch(`/clients/${clientID}/locations/${locationID}/image`, fileData)
			.then((res) => res.data);
		return res;
	} catch (error) {
		console.log('ChangeImage', error);
		throw error;
	}
};
export const changeLogo = async (clientID: any, locationID: any, data: any) => {
	try {
		const fileData = {
			file: data,
		};
		const res = await api
			.patch(`/clients/${clientID}/locations/${locationID}/logo`, fileData)
			.then((res) => res.data);
		return res;
	} catch (error) {
		console.log('ChangeLogo', error);
		throw error;
	}
};
export const changeColors = async (clientID: any, locationID: any, data: any) => {
	try {
		const res = await api.patch(`/clients/${clientID}/locations/${locationID}/color`, data).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('ChangeColor', error);
		throw error;
	}
};
export const changeText = async (clientID: any, locationID: any, data: any) => {
	try {
		const res = await api.patch(`/clients/${clientID}/locations/${locationID}/text`, data).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('ChangeText', error);
		throw error;
	}
};

export const getLinks = async (clientID: any, locationID: any) => {
	try {
		const res = await api.get(`/clients/${clientID}/locations/${locationID}/links`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('GetLinks', error);
		throw error;
	}
};
export const postLink = async (clientID: any, locationID: any, data: any) => {
	try {
		const res = await api.post(`/clients/${clientID}/locations/${locationID}/links`, data).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('PostLink', error);
		throw error;
	}
};
export const changeLinkOrder = async (clientID: any, locationID: any, data: any) => {
	try {
		const sortData = {
			sort: data.destination.index,
		};
		const res = await api
			.patch(`/clients/${clientID}/locations/${locationID}/links/${data.draggableId}/order`, sortData)
			.then((res) => res.data);
		return res;
	} catch (error) {
		console.log('ChangeLinkOrder', error);
		throw error;
	}
};

export const getClickStats = async (clientID: any, locationID: any, dates?: any, selectedDevice?: any) => {
	let params: any = {};
	if (selectedDevice) params = { ...params, deviceId: selectedDevice };
	try {
		const res = await api
			.get(
				`/clients/${clientID}/locations/${locationID}/statistics/clicks?dateFrom=${dates.dateFrom}&dateTo=${dates.dateTo}`,
				{ params },
			)
			.then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getClickStats');
		throw error;
	}
};

export const getReviewStats = async (clientID: any, locationID: any, dates?: any) => {
	try {
		const res = await api
			.get(
				`/clients/${clientID}/locations/${locationID}/statistics/reviews?dateFrom=${dates.dateFrom}&dateTo=${dates.dateTo}`,
			)
			.then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getReviewStats');
		throw error;
	}
};
export const deleteOneLink = async (clientID: any, locationID: any, linkID: any) => {
	try {
		const res = await api
			.delete(`/clients/${clientID}/locations/${locationID}/links/${linkID}`)
			.then((res) => res.data);
		return res;
	} catch (error) {
		console.log('DeleteOneLink');
		throw error;
	}
};

export const getSources = async (clientID: any, locationID: any) => {
	try {
		const res = await api
			.get(`/clients/${clientID}/locations/${locationID}/reviews/distinct/name`)
			.then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getSources');
		throw error;
	}
};

export const getAllReviews = async (
	clientID: any,
	locationID: any,
	skip: any,
	limit: any,
	rating: any,
	word: any,
	isMarked: any,
	dates: any,
	device: any,
	source: any,
) => {
	try {
		let params: any = {};
		if (skip) params = { ...params, skip };
		if (limit) params = { ...params, limit };
		if (word) params = { ...params, word };
		if (rating) {
			rating = JSON.stringify(rating);
			params = { ...params, rating };
		}
		if (isMarked) params = { ...params, isMarked };
		if (device) params = { ...params, device };
		if (source) params = { ...params, name: source };
		if (dates) params = { ...params, dateFrom: dates.dateFrom, dateTo: dates.dateTo };
		const res = await api
			.get(`/clients/${clientID}/locations/${locationID}/reviews`, { params })
			.then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getAllReviews');
		throw error;
	}
};

export const updateVCard = async (clientID: any, locationID: any, vcard: any) => {
	try {
		const res = await api
			.put(`/clients/${clientID}/locations/${locationID}/links/vcard`, vcard)
			.then((res) => res.data);
		return res;
	} catch (error) {
		console.log('updateVCard');
		throw error;
	}
};
export const getReviewsWords = async (
	clientID: any,
	locationID: any,
	isMarked: boolean,
	dates: any,
	source: any,
	device: any,
) => {
	try {
		let params = {};
		if (isMarked) params = { ...params, isMarked };
		if (source) params = { ...params, name: source };
		if (device) params = { ...params, device };
		if (dates) params = { ...params, dateFrom: dates.dateFrom, dateTo: dates.dateTo };

		const res = await api
			.get(`/clients/${clientID}/locations/${locationID}/reviews/words`, { params })
			.then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getReviewsWors');
		throw error;
	}
};
