import api from './api';

export const login = async (email: string, password: string) => {
	try {
		const res = await api.post('/auth/login', { email, password }).then((res) => res.data);
		return res.token;
	} catch (err) {
		console.error('LOGIN SERVICE', err);
		return null;
	}
};

export const getMyInfo = async () => {
	try {
		const res = await api.get('/auth/me').then((res) => res.data);
		return res;
	} catch (err) {
		console.error('GET MY INFO SERVICE', err);
		return null;
	}
};
export const updateDevice = async (name: string, deviceId: string) => {
	try {
		const res = await api.patch('/auth/me/updateDevice', { name, deviceId }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('updateDevice', error);
		throw error;
	}
};
export const addMyDevice = async (name: string, deviceId: string) => {
	try {
		const res = await api.put('/auth/me/device', { name, deviceId }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('addMyDevice', error);
		throw error;
	}
};
export const removeMyDevice = async (deviceId: string) => {
	try {
		const res = await api.delete(`/auth/me/device/${deviceId}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('removeMyDevice', error);
		throw error;
	}
};

export const getMyLocation = async () => {
	try {
		const res = await api.get('/auth/me/location').then((res) => res.data);
		return res;
	} catch (error) {
		console.error('Get my location', error);
		return null;
	}
};
export const getDashStats = async (daysBefore?: number) => {
	try {
		const res = await api.get(`/auth/me/dashboard?daysBefore=${daysBefore}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.error('GET DASH STATS SERVICE', getDashStats);
		return null;
	}
};
export const changeMyInfo = async (data: any) => {
	try {
		const res = api.patch('/auth/me', data).then((res) => res.data);
	} catch (error) {
		console.log('changeMyInfo', error);
		throw error;
	}
};

export const logout = () => {
	localStorage.removeItem('token');
};
