import React, { useCallback, useRef, useState } from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';

import useClickOutside from 'hooks/useClickOutside';
import styles from './PopoverPicker.module.scss';
import useEyeDropper from 'use-eye-dropper';
import { ReactComponent as EyedropperIcon } from 'assets/eyedropper.svg';
const PopoverPicker = ({ color, onChange, onBlur, disabled }: any) => {
	const popover = useRef() as React.MutableRefObject<HTMLDivElement>;
	const [isOpen, toggle] = useState(false);
	const { open, close, isSupported } = useEyeDropper();
	const closePopover = useCallback(() => toggle(false), []);

	useClickOutside(popover, closePopover);

	const pickColor = () => {
		open()
			.then((color) => onChange(color.sRGBHex))
			.catch((e) => {
				console.log(e);
				// Ensures component is still mounted
				// before calling setState
			});
	};

	return (
		<div className={styles.picker}>
			<div
				className={styles.swatch}
				style={{ backgroundColor: color }}
				onClick={() => {
					if (disabled == true) return;
					toggle(true);
				}}
			/>

			{isOpen && (
				<div className={styles.popover} ref={popover} style={{ zIndex: '2' }} onBlur={(event) => onBlur(event)}>
					<HexColorPicker color={color} onChange={onChange} />
					<div
						style={{
							width: '98%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginBottom: '10px',
							marginTop: '10px',
						}}>
						<label htmlFor="hexInput" style={{ width: '40%' }}>
							#
							<HexColorInput style={{ width: '80%' }} id="hexInput" color={color} onChange={onChange} />
						</label>
						{isSupported() && (
							<button className={styles.eyedropPicker} onClick={pickColor}>
								<EyedropperIcon />
							</button>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default PopoverPicker;
