import { userState } from 'recoil/user';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddSurveyIcon } from '../../../assets/add-gray.svg';
import { ReactComponent as TrashCanGray } from '../../../assets/trash-can-gray.svg';
import { ReactComponent as SurveysIcon } from 'assets/surveys-gray.svg';
import { ReactComponent as EditSurvey } from '../../../assets/edit-survey.svg';
import styles from './Surveys.module.scss';
import { Switch } from '@mui/material';
import ConfirmPopup from '../../../components/ConfirmPopup/ConfirmPopup';
import { getAllSurveys, createNewSurvey, activateSurvey, deleteSurvey } from 'services/survey.service';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router';
type SurveysProps = {
	setSelectedSurvey: any;
	setOneSurveyActive: any;
	selectedLocation: any;
};

const Surveys = ({ setSelectedSurvey, setOneSurveyActive, selectedLocation }: SurveysProps) => {
	const { t } = useTranslation('translation');
	const [showDeleteSurveyPopup, setShowDeleteSurveyPopup] = useState(false);
	const [showNewSurveyPopup, setShowNewSurveyPopup] = useState(false);
	const [allSurveys, setAllSurveys] = useState([]);
	const [loggedUser, setLoggedUser] = useRecoilState(userState);
	const [newSurveyText, setNewSurveyText] = useState('');
	const [surveyForRemoval, setSurveyForRemoval] = useState('');
	const navigate = useNavigate();
	if (!loggedUser) return null;
	useEffect(() => {
		try {
			(async () => {
				const all = await getAllSurveys(selectedLocation._id, loggedUser?._id);
				setAllSurveys(all);
			})();
		} catch (error) {
			console.log(error);
		}
	}, [selectedLocation]);

	const createSurvey = async () => {
		try {
			await createNewSurvey(selectedLocation._id, newSurveyText, loggedUser?._id);
			const all = await getAllSurveys(selectedLocation._id, loggedUser?._id);
			setAllSurveys(all);
			setNewSurveyText('');
			setShowNewSurveyPopup(false);
		} catch (error) {
			console.log(error);
		}
	};
	const deleteOneSurvey = async () => {
		try {
			await deleteSurvey(selectedLocation._id, surveyForRemoval, loggedUser?._id);
			const all = await getAllSurveys(selectedLocation._id, loggedUser?._id);
			setAllSurveys(all);
			setShowDeleteSurveyPopup(false);
		} catch (error) {
			console.log(error);
		}
	};
	const updateStatus = async (surveyID: any, status: any) => {
		try {
			await activateSurvey(selectedLocation._id, status ? null : surveyID, loggedUser?._id);
			const all = await getAllSurveys(selectedLocation._id, loggedUser?._id);
			setAllSurveys(all);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<ConfirmPopup
				show={showDeleteSurveyPopup}
				confirmText={t('Delete').toString()}
				onConfirm={() => deleteOneSurvey()}
				onCancel={() => setShowDeleteSurveyPopup(false)}
			/>
			<ConfirmPopup
				show={showNewSurveyPopup}
				title={t('Create new survey').toString()}
				onConfirm={() => createSurvey()}
				onCancel={() => {
					setShowNewSurveyPopup(false);
					setNewSurveyText('');
				}}>
				<p className={`${styles.newSurveyText}`}>{t('Survey Name')}</p>
				<div className={`${styles.newSurveyInputHolder}`}>
					<input
						type="text"
						className={`${styles.newSurveyInput}`}
						value={newSurveyText}
						onChange={(e) => setNewSurveyText(e.target.value)}
					/>
				</div>
			</ConfirmPopup>
			<div className={`${styles.allSurveys}`}>
				{allSurveys &&
					allSurveys.map((survey: any, i: any) => {
						return (
							<div key={i} className={`${styles.oneSurvey} ${styles.block}`}>
								<div className={`${styles.info}`}>
									<p className={`${styles.title}`}>{survey?.title}</p>
								</div>

								<div className={styles.downInfo}>
									<div className={`${styles.createdDiv}`}>
										<p className={`${styles.createdDate}`}>{t('Created on')}:</p>

										<p className={`${styles.createdDate}`}>
											{t(
												moment(survey.createdAt).format(
													'MMM',
												) as unknown as TemplateStringsArray,
											)}{' '}
											{moment(survey.createdAt).format('YYYY')}
											{/* {moment(survey.createdAt).format('MMM')}{' '}
											{moment(survey.createdAt).format('YYYY')} */}
										</p>
									</div>
									<div className={`${styles.editButtons}`}>
										<div>
											{!survey.active && (
												<TrashCanGray
													onClick={() => {
														setShowDeleteSurveyPopup(true);
														setSurveyForRemoval(survey._id);
													}}
												/>
											)}
											<EditSurvey
												onClick={() => {
													setSelectedSurvey(survey);
													setOneSurveyActive(true);
												}}
											/>
											<SurveysIcon
												height="18"
												onClick={() =>
													navigate(`/surveys/${survey._id}`, {
														state: { selectedLocation, surveyID: survey._id },
													})
												}
											/>
										</div>
										<div style={{ marginBottom: '2px' }}>
											<Switch
												checked={survey?.active ? true : false}
												onChange={() => updateStatus(survey._id, survey.active)}
												size="small"
											/>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				<div
					onClick={() => setShowNewSurveyPopup(true)}
					className={`${styles.addSurvey} ${styles.block} ${
						allSurveys?.length === 0 ? styles.noMargin : ''
					}`}>
					<div className={`${styles.addCircle}`}>
						<AddSurveyIcon />
					</div>
					<p>{t('Create new survey')}</p>
				</div>
			</div>
		</>
	);
};

export default Surveys;
