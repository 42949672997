import { useEffect, useState, useCallback } from 'react';
import styles from './Map.module.scss';
import Modal from 'react-modal';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { GoogleMap, useJsApiLoader, Autocomplete, Marker, InfoWindow } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
type MapProps = {
	isOpen: boolean;
	toggleMap: any;
	setGoogleLink: any;
	saveLink: any;
};
type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[];
const libraries: Libraries = ['places'];

const Map = ({ isOpen, toggleMap, setGoogleLink, saveLink }: MapProps) => {
	const key: any = process.env.REACT_APP_GOOGLE_MAP_KEY;
	const [autocomplete, setAutocomplete] = useState<any | null>(null);
	const [isOpened, setIsOpened] = useState(isOpen);
	const [center, setCenter] = useState({
		lat: 46.8182,
		lng: 8.2275,
	});
	const [zoom, setZoom] = useState(7.5);
	const [markerPos, setMarkerPos] = useState(null);
	const [infoWindowOpen, setInfoWindowOpen] = useState(true);
	// const [place, setPlace] = useState<any>(null)
	const [placeName, setPlaceName] = useState('No Name');
	const [placeID, setPlaceID] = useState(null);
	const { t } = useTranslation('translation');
	let place: any = null;
	// useEffect(() => {
	//   setIsOpened(isOpen)
	// }, [isOpen])

	const containerStyle = {
		width: 'fit-parent',
		height: '100%',
	};

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		libraries: libraries,
		googleMapsApiKey: key,
	});

	const [map, setMap] = useState(null);

	const onLoad = (autocomp: any) => {
		setAutocomplete(autocomp);
	};

	const onUnmount = useCallback(function callback(map: any) {
		setMap(null);
	}, []);

	const done = () => {
		if (placeID) {
			setGoogleLink(`https://search.google.com/local/writereview?placeid=${placeID}`);
			saveLink('google', `https://search.google.com/local/writereview?placeid=${placeID}`);
		}

		toggleMap();
	};

	const onPlaceChanged = () => {
		if (autocomplete !== null) {
			place = autocomplete.getPlace();
			// setPlace(autocomplete.getPlace())
			setMarkerPos(place.geometry.location);
			// console.log(markerPos)

			if (!place?.geometry || !place?.geometry?.location) {
				return;
			}
			if (place.geometry.viewport) {
				setCenter(place?.geometry?.viewport);
			}
			if (place && place?.geometry?.location) {
				setCenter(place?.geometry?.location);
				setZoom(17);
				setMarkerPos(place?.geometry?.location);
				setInfoWindowOpen(true);
			}
			if (place?.name && place?.place_id) {
				setPlaceName(place.name);
				setPlaceID(place.place_id);
			}
		} else {
			console.log('Autocomplete is not loaded yet!');
		}
	};

	return (
		<Modal isOpen={isOpen} ariaHideApp={false}>
			<div className={styles.mapComponentContainer}>
				{isLoaded ? (
					<GoogleMap id="searchbox-example" mapContainerStyle={containerStyle} center={center} zoom={zoom}>
						<Autocomplete
							onLoad={onLoad}
							onPlaceChanged={onPlaceChanged}
							fields={['place_id', 'geometry', 'name']}>
							<input
								type="text"
								placeholder="Search for a place"
								style={{
									boxSizing: 'border-box',
									border: '1px solid transparent',
									width: '240px',
									height: '32px',
									padding: '0 12px',
									marginTop: '12px',
									borderRadius: '3px',
									boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
									fontSize: '14px',
									outline: 'none',
									textOverflow: 'ellipses',
									position: 'absolute',
									left: '50%',
									marginLeft: '-120px',
								}}
							/>
						</Autocomplete>

						{markerPos != null ? (
							<>
								<Marker position={markerPos} onClick={() => setInfoWindowOpen(true)} />
								{infoWindowOpen ? (
									<InfoWindow
										position={markerPos}
										// options={{ closeBoxURL: ``, enableEventPropagation: true }}
										onPositionChanged={() => {
											setInfoWindowOpen(true);
										}}
										onCloseClick={() => setInfoWindowOpen(false)}>
										<div>
											<div style={{ fontSize: '16px' }}>
												<b>{placeName}</b>
												<p>
													{t('Place ID')}: {placeID}
												</p>
											</div>
										</div>
									</InfoWindow>
								) : null}
							</>
						) : null}
						<></>
					</GoogleMap>
				) : (
					<></>
				)}
				<div className={styles.doneDiv}>
					<button className={styles.toggleBtn} onClick={() => toggleMap()}>
						{t('Cancel').toUpperCase()}
					</button>
					<button onClick={() => done()} className={styles.doneBtn}>
						{t('Done').toUpperCase()}
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default Map;
