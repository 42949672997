import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeft } from '../../../../assets/arrow-left-white.svg';
import { ReactComponent as ArrowUp } from '../../../../assets/arrow-down.svg';
import styles from './Survey.module.scss';
import { ReactComponent as EditSurvey } from '../../../../assets/edit-survey.svg';
import { ReactComponent as AddQuestionIcon } from '../../../../assets/add-gray-bolder.svg';
import { ReactComponent as TrashCanGray } from '../../../../assets/trash-can-gray.svg';
import { ReactComponent as CheckmarkIcon } from '../../../../assets/checkmark.svg';
import { ReactComponent as MoveIcon } from '../../../../assets/move.svg';
import { ReactComponent as TrashCanChoice } from '../../../../assets/survey-trashcan.svg';
import { ReactComponent as CheckWhiteIcon } from '../../../../assets/checkmark-white.svg';
import { ReactComponent as PlusBlueIcon } from '../../../../assets/plus-blue.svg';
import { ReactComponent as CancelGrayIcon } from '../../../../assets/cancel-gray.svg';
import ConfirmPopup from '../../../../components/ConfirmPopup/ConfirmPopup';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import uuid from 'react-uuid';
import { updateSurvey } from 'services/survey.service';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { userState } from 'recoil/user';
type SurveyProps = {
	setSelectedSurvey: any;
	setOneSurveyActive: any;
	selectedSurvey: any;
	selectedLocation: any;
	setActiveTab: any;
};

const Survey = ({
	selectedLocation,
	setSelectedSurvey,
	selectedSurvey,
	setOneSurveyActive,
	setActiveTab,
}: SurveyProps) => {
	const { t } = useTranslation('translation');
	const [survey, setSurvey] = useState<any>({
		title: '',
		description: '',
	});

	const [questions, setQuestions] = useState<any>([]);
	const [selectedIndex, setSelectedIndex] = useState<any>();
	const [newQuestion, setNewQuestion] = useState({
		question: '',
		type: 'Checkboxes',
		required: true,
		choices: [],
	});
	const [loggedUser, setLoggedUser] = useRecoilState(userState);
	const [newQuestionError, setNewQuestionError] = useState(false);
	const [showDeleteQuestionPopup, setShowDeleteQuestionPopup] = useState(false);
	const [editableQuestion, setEditableQuestion] = useState('');
	const [addQuestionVisible, setAddQuestionVisible] = useState(false);
	useEffect(() => {
		setSurvey(selectedSurvey);
		setQuestions(selectedSurvey?.questions);
	}, []);
	const updateStatus = async (surveyID: any) => {
		try {
			const surveyCopy = survey;
			delete surveyCopy.questions;
			delete surveyCopy.__v;
			delete surveyCopy._id;
			delete surveyCopy.location;
			delete surveyCopy.client;

			let emptyChoice = false;
			console.log(selectedSurvey._id);

			for (let i = 0; i < questions.length; i++) {
				for (let l = 0; l < questions[i]?.choices?.length; l++) {
					if (questions[i]?.choices[l]?.text.trim() === '') {
						toast.error(t('A choice cannot be empty'));
						emptyChoice = true;
					}
				}
			}

			if (emptyChoice) return;
			const newSurvey = await updateSurvey(
				selectedLocation._id,
				surveyID,
				{
					...surveyCopy,
					questions,
				},
				loggedUser?._id,
			);
			setSurvey(newSurvey);
			setSelectedSurvey(newSurvey);

			toast.success(t('Changes saved'));
		} catch (error) {
			console.log(error);
		}
	};
	const setNewQuestionToDefault = () => {
		setNewQuestion({
			question: '',
			type: 'Checkboxes',
			required: true,
			choices: [],
		});
	};
	const addQuestion = () => {
		if (!newQuestion.question.trim()) return setNewQuestionError(true);
		const newID = uuid();
		setQuestions((prevState: any) => [...prevState, { ...newQuestion, tempID: newID }]);
		setAddQuestionVisible(false);
		setNewQuestionToDefault();
	};
	const reorder = (list: any, startIndex: any, endIndex: any) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		console.log('start', list, 'finish', result);
		return result;
	};
	const getListStyle = (isDraggingOver: any) => ({
		// background: isDraggingOver ? "lightblue" : "lightgrey",
		// paddingLeft: grid,
		// paddingLeft: '20px',
		width: '100%',
		height: 'auto',
	});
	const getListStyleChoice = (isDraggingOver: any) => ({
		// background: isDraggingOver ? "lightblue" : "lightgrey",
		// paddingLeft: grid,
		// paddingLeft: '20px',
		width: '100%',
		height: 'auto',
	});
	const onDragEnd = async (result: any) => {
		// dropped outside the list

		if (!result.destination) {
			return;
		}

		if (result.type === 'question') {
			const items = reorder(questions, result.source.index, result.destination.index);
			setQuestions(items);
		}

		if (result.type === 'choice') {
			const newChoices = reorder(
				questions[editableQuestion].choices,
				result.source.index,
				result.destination.index,
			);
			setQuestions((prevState: any) => {
				const newQuestions = questions.map((question: any, i: any) =>
					questions.indexOf(question) === editableQuestion ? { ...question, choices: newChoices } : question,
				);
				console.log('New Quesionss', newQuestions);
				return newQuestions;
			});
		}
		if (result.type === 'newChoice') {
			const newChoices = reorder(newQuestion.choices, result.source.index, result.destination.index);
			setNewQuestion((prevState: any) => ({
				...prevState,
				choices: newChoices,
			}));
		}
	};

	const removeQuestion = () => {
		const newArray = [...questions];
		const question = questions[selectedIndex];
		const index = questions.indexOf(question);
		if (index !== -1) {
			newArray.splice(index, 1);
			setQuestions(newArray);
		}
	};

	const getItemStyle = (isDragging: any, draggableStyle: any) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: 'none',
		// padding: grid * 2,
		// margin: `0 0 ${grid}px 0`,

		// change background colour if dragging
		position: 'relative',
		width: '100%',
		// marginLeft: '10px',
		marginTop: '10px',
		height: 'auto',
		padding: '15px 20px',
		// float: 'left',
		borderRadius: '4px',
		backgroundColor: 'white',
		// styles we need to apply on draggables
		...draggableStyle,
	});
	const getItemStyleChoice = (isDragging: any, draggableStyle: any) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: 'none',
		// padding: grid * 2,
		// margin: `0 0 ${grid}px 0`,

		// change background colour if dragging
		position: 'relative',
		width: 'fit-content',
		// marginLeft: '10px',
		height: 'auto',
		// float: 'left',
		paddingLeft: '15px',
		borderRadius: '4px',
		backgroundColor: 'white',
		marginBottom: '8px',
		// styles we need to apply on draggables
		...draggableStyle,
	});

	return (
		<section>
			<ConfirmPopup
				show={showDeleteQuestionPopup}
				confirmText={t('Delete').toString()}
				onCancel={() => {
					setShowDeleteQuestionPopup(false);
				}}
				onConfirm={() => {
					setShowDeleteQuestionPopup(false);
					removeQuestion();
				}}
			/>
			<div className={`${styles.surveyButtonHolder}`}>
				<button
					onClick={() => {
						setSelectedSurvey('');
						setActiveTab('surveys');
						setOneSurveyActive(false);
					}}
					className={`${styles.backButton}`}>
					<div className={`${styles.backButtonContent}`}>
						<ArrowLeft height="100%" />
						<p>{t('Go Back')}</p>
					</div>
				</button>
			</div>
			<div className={`${styles.surveyInfo}`}>
				<div className={`${styles.surveyAllInfo}`}>
					<div className={`${styles.surveyMainInfo}`}>
						<p>{t('Survey Name')}</p>
						<input
							value={survey?.title}
							onChange={(e) =>
								setSurvey((prevState: any) => ({
									...prevState,
									title: e.target.value,
								}))
							}
							type="text"
						/>
						<p>{t('Welcome Message')}</p>
						<input
							value={survey?.description}
							onChange={(e) =>
								setSurvey((prevState: any) => ({
									...prevState,
									description: e.target.value,
								}))
							}
							type="text"
						/>
					</div>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable type="question" droppableId="droppable1">
							{(provided, snapshot) => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}>
									{questions &&
										questions.map((question: any, i: any) => {
											return (
												<Draggable
													key={question?._id || question?.tempID}
													draggableId={question?._id || question?.tempID}
													index={i}>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															style={getItemStyle(
																snapshot.isDragging,
																provided.draggableProps.style,
															)}>
															<div key={i} className={`${styles.surveyQuestion}`}>
																{editableQuestion === '' && (
																	<div
																		{...provided.dragHandleProps}
																		className={`${styles.questionDrag}`}>
																		<MoveIcon />
																	</div>
																)}
																{editableQuestion !== i && (
																	<>
																		<div className={`${styles.questionHeader}`}>
																			<div
																				className={`${styles.questionHeaderLeft}`}>
																				<div
																					className={`${styles.questionIndex}`}>
																					{i + 1}
																				</div>

																				<p>{question.question}</p>
																			</div>
																			<div
																				className={`${styles.questionHeaderRight}`}>
																				<button
																					className={`${styles.editQuestion}`}
																					onClick={() => {
																						if (editableQuestion === i) {
																							setEditableQuestion('');
																							return;
																						}
																						setEditableQuestion(i);
																					}}>
																					<EditSurvey />
																				</button>
																				<button
																					onClick={() => {
																						setSelectedIndex(i);
																						setShowDeleteQuestionPopup(
																							true,
																						);
																					}}
																					className={`${styles.editQuestion} ${styles.trash}`}>
																					<TrashCanGray />
																				</button>
																			</div>
																		</div>
																		{(question.type === 'Checkboxes' ||
																			question.type === 'Dropdown') && (
																			<div className={`${styles.choicesPreview}`}>
																				{question?.choices?.length > 0 &&
																					question?.choices.map(
																						(choice: any, i: any) => {
																							return (
																								<div
																									key={i}
																									className={`${styles.oneChoicePreviewCover}`}>
																									<div
																										className={`${styles.oneChoicePreview}`}>
																										{choice?.text}
																									</div>
																								</div>
																							);
																						},
																					)}
																			</div>
																		)}
																	</>
																)}

																{editableQuestion === i && (
																	<>
																		<div className={`${styles.questionHeader}`}>
																			<div className={`${styles.questionIndex}`}>
																				{i + 1}
																			</div>
																			<button
																				className={`${styles.editQuestion}`}
																				onClick={() => {
																					if (editableQuestion === i) {
																						setEditableQuestion('');
																						return;
																					}
																					setEditableQuestion(i);
																				}}>
																				<ArrowUp
																					className={`${styles.closeQuestion}`}
																				/>
																			</button>
																		</div>
																		<p
																			className={`${styles.surveyEditableQuestionP}`}>
																			{t('Question')}
																		</p>
																		<input
																			type="text"
																			value={question.question}
																			onChange={(e) => {
																				setQuestions((prevState: any) => {
																					// const allQuestions = prevState;
																					// allQuestions[i].title = e.target.value;
																					const newArray = prevState.map(
																						(question: any, q: any) => {
																							if (i === q) {
																								return {
																									...question,
																									question:
																										e.target.value,
																								};
																							} else {
																								return question;
																							}
																						},
																					);
																					return newArray;
																				});
																			}}
																		/>
																		<div className={`${styles.typeAndRequired}`}>
																			<div
																				className={`${styles.questionTypeDiv}`}>
																				<p>{t('Question type')}</p>
																				<select
																					value={question.type}
																					onChange={(e) => {
																						setQuestions(
																							(prevState: any) => {
																								// const allQuestions = prevState;
																								// allQuestions[i].title = e.target.value;
																								let newArray;
																								if (
																									e.target.value ===
																										'File upload' ||
																									e.target.value ===
																										'Text'
																								) {
																									newArray =
																										prevState.map(
																											(
																												question: any,
																												q: any,
																											) => {
																												if (
																													i ===
																													q
																												) {
																													return {
																														...question,
																														type: e
																															.target
																															.value,
																														choices:
																															[],
																													};
																												} else {
																													return question;
																												}
																											},
																										);
																								} else {
																									newArray =
																										prevState.map(
																											(
																												question: any,
																												q: any,
																											) => {
																												if (
																													i ===
																													q
																												) {
																													return {
																														...question,
																														type: e
																															.target
																															.value,
																													};
																												} else {
																													return question;
																												}
																											},
																										);
																								}
																								return newArray;
																							},
																						);
																					}}
																					name=""
																					id="">
																					<option value="Text">
																						{t('Text')}
																					</option>

																					<option value="Dropdown">
																						{t('Dropdown')}
																					</option>
																					<option value="Checkboxes">
																						{t('Checkboxes')}
																					</option>
																					<option value="File upload">
																						{t('File upload')}
																					</option>
																				</select>
																			</div>
																			<div
																				className={`${styles.questionRequiredDiv}`}>
																				<p>{t('Required field')}</p>
																				<div
																					className={`${styles.surveyRequiredButtons}`}>
																					<button
																						className={
																							question.required
																								? styles.active
																								: ''
																						}
																						onClick={() => {
																							setQuestions(
																								(prevState: any) => {
																									// const allQuestions = prevState;
																									// allQuestions[i].title = e.target.value;
																									const newArray =
																										prevState.map(
																											(
																												question: any,
																												q: any,
																											) => {
																												if (
																													i ===
																													q
																												) {
																													return {
																														...question,
																														required:
																															true,
																													};
																												} else {
																													return question;
																												}
																											},
																										);
																									return newArray;
																								},
																							);
																						}}>
																						<CheckWhiteIcon />
																						{t('Yes')}
																					</button>
																					<button
																						className={
																							!question.required
																								? styles.active
																								: ''
																						}
																						onClick={() => {
																							setQuestions(
																								(prevState: any) => {
																									// const allQuestions = prevState;
																									// allQuestions[i].title = e.target.value;
																									const newArray =
																										prevState.map(
																											(
																												question: any,
																												q: any,
																											) => {
																												if (
																													i ===
																													q
																												) {
																													return {
																														...question,
																														required:
																															false,
																													};
																												} else {
																													return question;
																												}
																											},
																										);
																									return newArray;
																								},
																							);
																						}}>
																						<CancelGrayIcon />
																						{t('No')}
																					</button>
																				</div>
																			</div>
																		</div>
																		{(question.type === 'Dropdown' ||
																			question.type === 'Checkboxes') && (
																			<Droppable
																				type="choice"
																				droppableId="droppable2">
																				{(provided, snapshot) => (
																					<div
																						{...provided.droppableProps}
																						ref={provided.innerRef}
																						style={getListStyleChoice(
																							snapshot.isDraggingOver,
																						)}
																						className={`${styles.choicesDiv}`}>
																						{question?.choices?.length >
																							0 && (
																							<p
																								className={`${styles.choicesTitle}`}>
																								{t('Choices')}
																							</p>
																						)}
																						{question?.choices.map(
																							(choice: any, qu: any) => {
																								return (
																									<Draggable
																										key={
																											choice?._id ||
																											choice?.tempID
																										}
																										draggableId={
																											choice?._id ||
																											choice?.tempID
																										}
																										index={qu}>
																										{(
																											provided,
																											snapshot,
																										) => (
																											<div
																												ref={
																													provided.innerRef
																												}
																												{...provided.draggableProps}
																												style={getItemStyleChoice(
																													snapshot.isDragging,
																													provided
																														.draggableProps
																														.style,
																												)}
																												className={`${styles.oneChoice}`}>
																												<div
																													{...provided.dragHandleProps}
																													className={`${styles.moveChoice}`}>
																													<MoveIcon />
																												</div>
																												<input
																													className={`${styles.choiceInput}`}
																													type="text"
																													value={
																														choice.text
																													}
																													onChange={(
																														e: any,
																													) => {
																														setQuestions(
																															(
																																prevState: any,
																															) => {
																																// const allQuestions = prevState;
																																// allQuestions[i].title = e.target.value;

																																const newArray =
																																	prevState.map(
																																		(
																																			question: any,
																																			q: any,
																																		) => {
																																			if (
																																				i ===
																																				q
																																			) {
																																				const question =
																																					questions[
																																						q
																																					];
																																				const newChoices =
																																					question.choices.map(
																																						(
																																							currentChoice: any,
																																						) =>
																																							question.choices.indexOf(
																																								currentChoice,
																																							) ==
																																							question.choices.indexOf(
																																								choice,
																																							)
																																								? {
																																										...choice,
																																										text: e
																																											.target
																																											.value,
																																								  }
																																								: currentChoice,
																																					);
																																				return {
																																					...question,
																																					choices:
																																						newChoices,
																																				};
																																			} else {
																																				return question;
																																			}
																																		},
																																	);
																																return newArray;
																															},
																														);
																													}}
																												/>
																												<TrashCanChoice
																													className={`${styles.optionTrash}`}
																													onClick={() => {
																														setQuestions(
																															(
																																prevState: any,
																															) => {
																																// const allQuestions = prevState;
																																// allQuestions[i].title = e.target.value;

																																const newArray =
																																	prevState.map(
																																		(
																																			question: any,
																																			q: any,
																																		) => {
																																			if (
																																				i ===
																																				q
																																			) {
																																				const question =
																																					questions[
																																						q
																																					];
																																				const newChoices =
																																					question.choices.filter(
																																						(
																																							e: any,
																																						) =>
																																							e !==
																																							choice,
																																					);
																																				return {
																																					...question,
																																					choices:
																																						newChoices,
																																				};
																																			} else {
																																				return question;
																																			}
																																		},
																																	);
																																return newArray;
																															},
																														);
																													}}
																												/>
																											</div>
																										)}
																									</Draggable>
																								);
																							},
																						)}
																						{provided.placeholder}

																						<button
																							className={`${styles.addChoiceButton}`}
																							onClick={() => {
																								setQuestions(
																									(
																										prevState: any,
																									) => {
																										// const allQuestions = prevState;
																										// allQuestions[i].title = e.target.value;
																										const newArray =
																											prevState.map(
																												(
																													question: any,
																													q: any,
																												) => {
																													if (
																														i ===
																														q
																													) {
																														return {
																															...question,
																															choices:
																																[
																																	...question.choices,
																																	{
																																		text: '',
																																		tempID: uuid(),
																																	},
																																],
																														};
																													} else {
																														return question;
																													}
																												},
																											);
																										return newArray;
																									},
																								);
																							}}>
																							<PlusBlueIcon />
																							{t('Add choice')}
																						</button>
																					</div>
																				)}
																			</Droppable>
																		)}
																	</>
																)}
															</div>
														</div>
													)}
												</Draggable>
											);
										})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
					{addQuestionVisible && (
						<div className={`${styles.surveyNewQuestion}`}>
							<div className={`${styles.questionHeader}`}>
								<div className={`${styles.questionIndex}`}>{questions?.length + 1}</div>
							</div>
							<p>{t('Question')}</p>
							<input
								type="text"
								value={newQuestion.question}
								className={newQuestionError ? styles.error : ''}
								onChange={(e) => {
									setNewQuestionError(false);
									setNewQuestion((prevState: any) => ({
										...prevState,
										question: e.target.value,
									}));
								}}
							/>
							<div className={`${styles.typeAndRequired}`}>
								<div className={`${styles.questionTypeDiv}`}>
									<p>{t('Question type')}</p>
									<select
										value={newQuestion.type}
										onChange={(e) => {
											if (e.target.value === 'Text' || e.target.value === 'File upload') {
												setNewQuestion((prevState: any) => ({
													...prevState,
													type: e.target.value,
													choices: [],
												}));
											} else {
												setNewQuestion((prevState: any) => ({
													...prevState,
													type: e.target.value,
												}));
											}
										}}
										name=""
										id="">
										<option value="Text">{t('Text')}</option>

										<option value="Dropdown">{t('Dropdown')}</option>
										<option value="Checkboxes">{t('Checkboxes')}</option>
										<option value="File upload">{t('File upload')}</option>
									</select>
								</div>
								<div className={`${styles.questionRequiredDiv}`}>
									<p>{t('Required field')}</p>
									<div className={`${styles.surveyRequiredButtons}`}>
										<button
											className={newQuestion.required ? styles.active : ''}
											onClick={() => {
												setNewQuestion((prevState: any) => ({
													...prevState,
													required: true,
												}));
											}}>
											<CheckWhiteIcon />
											{t('Yes')}
										</button>
										<button
											className={!newQuestion.required ? styles.active : ''}
											onClick={() => {
												setNewQuestion((prevState: any) => ({
													...prevState,
													required: false,
												}));
											}}>
											<CancelGrayIcon />
											{t('No')}
										</button>
									</div>
								</div>
							</div>
							{(newQuestion.type === 'Dropdown' || newQuestion.type === 'Checkboxes') && (
								<DragDropContext onDragEnd={onDragEnd}>
									<Droppable type="newChoice" droppableId="droppable3">
										{(provided, snapshot) => (
											<div
												{...provided.droppableProps}
												ref={provided.innerRef}
												style={getListStyleChoice(snapshot.isDraggingOver)}
												className={`${styles.choicesDiv}`}>
												{newQuestion.choices.length > 0 && (
													<p className={`${styles.choicesTitle}`}>{t('Choices')}</p>
												)}
												{newQuestion?.choices.map((choice: any, qu: any) => {
													return (
														<Draggable
															key={choice?._id || choice?.tempID}
															draggableId={choice?._id || choice?.tempID}
															index={qu}>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	style={getItemStyleChoice(
																		snapshot.isDragging,
																		provided.draggableProps.style,
																	)}
																	className={`${styles.oneChoice}`}>
																	<div
																		{...provided.dragHandleProps}
																		className={`${styles.moveChoice}`}>
																		<MoveIcon />
																	</div>
																	<input
																		className={`${styles.choiceInput}`}
																		type="text"
																		value={choice.text}
																		onChange={(e: any) => {
																			setNewQuestion((prevState: any) => {
																				const newChoices =
																					prevState.choices.map(
																						(oneChoice: any) =>
																							prevState.choices.indexOf(
																								choice,
																							) ===
																							prevState.choices.indexOf(
																								oneChoice,
																							)
																								? {
																										...oneChoice,
																										text: e.target
																											.value,
																								  }
																								: oneChoice,
																					);
																				return {
																					...prevState,
																					choices: newChoices,
																				};
																			});
																		}}
																	/>
																	<TrashCanChoice
																		className={`${styles.optionTrash}`}
																		onClick={() => {
																			setNewQuestion((prevState: any) => {
																				// const allQuestions = prevState;
																				// allQuestions[i].title = e.target.value;

																				const newChoices =
																					prevState.choices.filter(
																						(e: any) => e !== choice,
																					);
																				return {
																					...prevState,
																					choices: newChoices,
																				};
																			});
																		}}
																	/>
																</div>
															)}
														</Draggable>
													);
												})}
												{provided.placeholder}

												<button
													className={`${styles.addChoiceButton}`}
													onClick={() => {
														setNewQuestion((prevState: any) => ({
															...prevState,
															choices: [
																...prevState.choices,
																{ text: '', tempID: uuid() },
															],
														}));
													}}>
													<PlusBlueIcon />
													{t('Add choice')}
												</button>
											</div>
										)}
									</Droppable>
								</DragDropContext>
							)}
							<div className={`${styles.newQuestionButtons}`}>
								<button
									className={`${styles.cancelQuestion}`}
									onClick={() => {
										setAddQuestionVisible(false);
										setNewQuestionToDefault();
										setNewQuestionError(false);
									}}>
									{t('Cancel')}
								</button>
								<button className={`${styles.addQuestion}`} onClick={() => addQuestion()}>
									{t('Add question')} <CheckmarkIcon height="7px" />
								</button>
							</div>
						</div>
					)}

					{!addQuestionVisible && (
						<div className={`${styles.newQuestionAndSaveBtns}`}>
							<button className={`${styles.newSurveyButton}`} onClick={() => setAddQuestionVisible(true)}>
								{' '}
								<AddQuestionIcon />{' '}
								{questions?.length > 0 ? t('Add another question') : t('Add question')}
							</button>
							<button
								onClick={() => updateStatus(selectedSurvey._id)}
								className={`${styles.saveSurveyButton}`}>
								<CheckWhiteIcon />
								{t('Save')}
							</button>
						</div>
					)}
				</div>
				<div className={`${styles.phonePreview}`}>
					<div className={`${styles.phoneContent}`}>
						<div className={`${styles.allPhoneContent}`}>
							<div className={`${styles.banner}`}>
								<img
									className={`${styles.banner}`}
									src={selectedLocation?.pageSetup?.image?.link}
									alt=""
								/>
							</div>
							<div className={`${styles.title}`}>
								<p>{survey?.title}</p>
							</div>
							<div className={`${styles.welcomeMessage}`}>
								{survey?.description?.length > 0 && (
									<p className={`${styles.message}`}>{survey?.description}</p>
								)}
							</div>
							{questions &&
								questions.map((question: any, i: any) => {
									if (question.question) {
										return (
											<div>
												<div className={`${styles.surveyQuestionPreview}`}>
													<div className={`${styles.message}`}>
														<div className={`${styles.messageTop}`}>
															<div className={`${styles.questionIndex}`}>{i + 1}</div>

															<p>{question.question}</p>
														</div>
														{(question.type === 'Checkboxes' ||
															question.type === 'Dropdown') &&
															question?.choices?.length > 0 && (
																<div className={`${styles.answersHolderPreview}`}>
																	{question.choices.map((choice: any, i: any) => {
																		return (
																			<div
																				key={i}
																				className={`${
																					styles.oneAnswerPreview
																				} ${i === 0 ? styles.active : ''}`}>
																				{choice?.text}
																			</div>
																		);
																	})}
																</div>
															)}
													</div>

													<div className={`${styles.answerHolder}`}>
														<div className={`${styles.message} ${styles.answer}`}>
															{(question.type === 'Checkboxes' ||
																question.type === 'Dropdown') &&
															question?.choices?.length > 0 ? (
																<div>{question?.choices[0]?.text}</div>
															) : (
																t('Answer')
															)}
															{/* {t('Answer')} */}
														</div>
													</div>
												</div>
											</div>
										);
									}
								})}
						</div>
					</div>
					<img src={require('../../../../assets/iphone-transparent.png')} width="100%" alt="" />
				</div>
			</div>
		</section>
	);
};

export default Survey;
