import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getSurveyStats } from '../../../../services/survey.service';
import styles from './SurveyStat.module.scss';
import { useLocation } from 'react-router-dom';
import { ReactComponent as BackArrow } from 'assets/arrow-left-white.svg';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { userState } from 'recoil/user';
import ImagePreview from '../../../../components/ImagePreview/ImagePreview';
type LocationState = {
	selectedLocation: any;
	surveyID: string;
};
const SurveyStat = () => {
	const [loggedUser, setLoggedUser] = useRecoilState(userState);
	const navigate = useNavigate();
	const [showPreview, setShowPreview] = useState(false);
	const [currentUrl, setCurrentUrl] = useState('');
	const location = useLocation();
	const [questions, setQuestions] = useState([]);
	const [emails, setEmails] = useState([]);
	const { t } = useTranslation('translation');
	if (!loggedUser) return null;

	useEffect(() => {
		(async () => {
			try {
				const { selectedLocation, surveyID } = location.state as LocationState;
				if (location) {
					const response = await getSurveyStats(selectedLocation._id, surveyID, loggedUser._id);
					setQuestions(response.data.questions);
					setEmails(response.data.emails);
				}
			} catch (error) {
				console.log(error);
				navigate('/my-device');
			}
		})();
	}, []);
	return (
		<section>
			<ImagePreview show={showPreview} setShow={setShowPreview} url={currentUrl} />
			<div className={styles.backButtonDiv}>
				<button onClick={() => navigate(-1)} className={styles.backButton}>
					<div className={styles.backButtonContent}>
						<BackArrow />
						<p>{t('Go Back')}</p>
					</div>
				</button>
			</div>
			<div className={styles.questionsStats}>
				{questions.length === 0 && <div>{t('No Data')}</div>}
				{questions &&
					questions.map((question: any, i: any) => {
						return (
							<div key={i} className={styles.oneQuestionStat}>
								<div className={styles.statHeader}>
									<div className={styles.statHeaderLeft}>
										<p className={styles.statIndex}>{i + 1}</p>
										<p className={styles.statTitle}>{question.question}</p>
									</div>
									{/* <div className="statHeaderRight">
                    <ArrowDown />
                  </div> */}
								</div>
								<div
									className={`${styles.statBody} ${
										question.type === 'Text' ? styles.maxHeight : ''
									}`}>
									{(question.type === 'Dropdown' || question.type === 'Checkboxes') &&
										question.answers.map((answer: any, i: any) => {
											return (
												<div key={i} className={styles.statHolder}>
													<div className={styles.progressHolder}>
														<ProgressBar
															index={i + 1}
															option={answer.name}
															percentage={answer.percentage}
														/>
													</div>
												</div>
											);
										})}
									{question.type === 'Text' && (
										<div className={styles.textAnswers}>
											<table>
												<thead>
													<tr className={styles.header}>
														<th className={styles.statNum}>#</th>
														<th>Response</th>
													</tr>
												</thead>
												<tbody>
													{question.answers.map((answer: any, i: any) => {
														return (
															<tr key={i} className={styles.oneData}>
																<td className={styles.data}>{i + 1}</td>
																<td className={styles.data}>{answer.name}</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										</div>
									)}
									{question.type === 'File upload' && (
										<div className={styles.allImages}>
											{question.answers.map((answer: any, i: any) => {
												if (answer.name) {
													return (
														<img
															onClick={() => {
																setCurrentUrl(answer.name);
																setShowPreview(true);
															}}
															className={styles.oneImage}
															src={answer.name}
															loading="lazy"
														/>
													);
												}
											})}
										</div>
									)}
								</div>
							</div>
						);
					})}
			</div>
		</section>
	);
};

export default SurveyStat;
