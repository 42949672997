
import en from 'locales/en/en.json';
import de from 'locales/de/de.json';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
export const resources = {  
	en: {
		translation: en
	},
	de: {
		translation: de
	}
} as const;

i18n.use(initReactI18next).init({
	resources,
	nsSeparator: false,
	keySeparator: false,
	// do not load a fallback
	fallbackLng: 'en'
});