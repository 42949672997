import { useEffect, useState } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import styles from './Header.module.scss';
import profilePNG from 'assets/profile.png';
import headerArrowDownSVG from 'assets/header-arrow-down.svg';
import {
	Business,
	PieChart,
	Folder,
	Campaign,
	People,
	AccountCircle,
	AccountBoxRounded,
	PhoneAndroid,
	PhoneIphone,
} from '@mui/icons-material';
import { ReactComponent as ClientsIcon } from 'assets/clients-gray.svg';
import { ReactComponent as ClientsIconColor } from 'assets/clients-color.svg';

import { ReactComponent as LeadsIcon } from 'assets/leads-gray.svg';
import { ReactComponent as LeadsIconColor } from 'assets/leads-color.svg';

import { ReactComponent as LeadsStatisticsIcon } from 'assets/lead-statistics-gray.svg';
import { ReactComponent as LeadsStatisticsIconColor } from 'assets/lead-statistics-color.svg';

import { ReactComponent as DocumentsIcon } from 'assets/documents-grey.svg';
import { ReactComponent as DocumentsIconColor } from 'assets/documents-color.svg';

import { ReactComponent as StatisticsIcon } from 'assets/statistics.svg';
import { ReactComponent as StatisticsIconColor } from 'assets/statistics-color2.svg';
import logoSVG from 'assets/logo.svg';

import { ReactComponent as CompanyIcon } from 'assets/company-grey.svg';
import { ReactComponent as CompanyIconColor } from 'assets/company-color.svg';

import { ReactComponent as DeviceIcon } from 'assets/device-gray.svg';
import { ReactComponent as DeviceIconColor } from 'assets/device.svg';

import { ReactComponent as UserDeviceIcon } from 'assets/user-device-gray.svg';
import { ReactComponent as UserDeviceIconColor } from 'assets/user-device-color.svg';

import { ReactComponent as UsersIcon } from 'assets/users-gray.svg';
import { ReactComponent as UsersIconColor } from 'assets/users-color.svg';
import { ReactComponent as BurgerMenu } from 'assets/burger-menu-link.svg';
import { ReactComponent as Settings } from 'assets/settings.svg';
import { ReactComponent as SettingsColor } from 'assets/settings-color.svg';
import { ReactComponent as Logout } from 'assets/logout.svg';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { logout } from 'services/auth.service';
import { userState } from 'recoil/user';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { Tooltip } from '@mui/material';

const Header = () => {
	const [user, setUser] = useRecoilState(userState);
	const { t, i18n } = useTranslation('translation');
	const location = useLocation();
	const navigate = useNavigate();
	const [mobileNavOpened, setMobileNavOpened] = useState(false);
	const [storedValue, setValue] = useLocalStorage('language', 'en');
	const changeLanguage = (lang: string) => {
		i18n.changeLanguage(lang);
		setValue(lang);
	};
	useEffect(() => {
		i18n.changeLanguage(storedValue);
	}, [storedValue]);

	const colorNav = (path: string) => {
		if (location.pathname.startsWith(path)) {
			return 'primary';
		}
		return 'inherit';
	};

	if (!user) return null;
	const logOut = () => {
		logout();
		setUser(null);
		navigate('/');
	};

	return (
		<header className={`${styles.header}`}>
			<div className={`${styles.headerItems} ${styles.icons}`}>
				{user.permissions.includes('client.read') && (
					<div
						onClick={() => {
							if (location.pathname !== '/clients') {
								navigate('/clients');
							}
						}}
						className={`${styles.headerItem} ${
							location.pathname.startsWith('/clients') ? styles.active : ''
						}`}>
						<Tooltip title={t('Clients')}>
							<div className={`${styles.iconHolder}`}>
								<ClientsIcon />
								<ClientsIconColor className={styles.color} />
							</div>
						</Tooltip>
					</div>
				)}

				{user.permissions.includes('lead.read') && (
					<div
						onClick={() => navigate('/leads')}
						className={`${styles.headerItem} ${
							location.pathname.startsWith('/leads') ? styles.active : ''
						}`}>
						<Tooltip title={t('Leads')}>
							<div className={styles.iconHolder}>
								<LeadsIcon />
								<LeadsIconColor className={styles.color} />
							</div>
						</Tooltip>
					</div>
				)}

				{(user.role.name === 'Backoffice' || user.role.name === 'Company Owner') && (
					<div
						onClick={() => navigate('/lead-statistics')}
						className={`${styles.headerItem} ${
							location.pathname.startsWith('/lead-statistics') ? styles.active : ''
						}`}>
						<Tooltip title={`${t('Lead')} ${t('Statistics')}`}>
							<div className={styles.iconHolder}>
								<LeadsStatisticsIcon height={21} />
								<LeadsStatisticsIconColor height={21} className={styles.color} />
							</div>
						</Tooltip>
					</div>
				)}

				{user.permissions.includes('document.read') && (
					<div
						onClick={() => navigate('/documents')}
						className={`${styles.headerItem} ${
							location.pathname.startsWith('/documents') ? styles.active : ''
						}`}>
						<Tooltip title={t('Documents')}>
							<div className={styles.iconHolder}>
								<DocumentsIcon />
								<DocumentsIconColor className={styles.color} />
							</div>
						</Tooltip>
					</div>
				)}

				{/* {user.permissions.includes('statistic.read') && (
					<div
						onClick={() => navigate('/statistics')}
						className={`${styles.headerItem} ${
							location.pathname.startsWith('/statistics') ? styles.active : ''
						}`}>
						<Tooltip title="Statistics">
							<div className={styles.iconHolder}>
								<StatisticsIcon />
								<StatisticsIconColor className={styles.color} />
							</div>
						</Tooltip>
					</div>
				)} */}

				{user.permissions.includes('company.read') && (
					<div
						onClick={() => navigate('/companies')}
						className={`${styles.headerItem} ${
							location.pathname.startsWith('/companies') ? styles.active : ''
						}`}>
						<Tooltip title={t('Companies')}>
							<div className={styles.iconHolder}>
								<CompanyIcon />
								<CompanyIconColor className={styles.color} />
							</div>
						</Tooltip>
					</div>
				)}

				{user.permissions.includes('device.read') && (
					<div
						onClick={() => navigate('/devices')}
						className={`${styles.headerItem} ${
							location.pathname.startsWith('/devices') ? styles.active : ''
						}`}>
						<Tooltip title={t('Devices')}>
							<div className={styles.iconHolder}>
								<DeviceIcon height={'24px'} />
								<DeviceIconColor height={'24px'} className={styles.color} />
							</div>
						</Tooltip>
					</div>
				)}
				{user.permissions.includes('user.read') && (
					<div
						onClick={() => navigate('/users')}
						className={`${styles.headerItem} ${
							location.pathname.startsWith('/users') ? styles.active : ''
						}`}>
						<Tooltip title={t('Users')}>
							<div className={styles.iconHolder}>
								<UsersIcon />
								<UsersIconColor className={styles.color} />
							</div>
						</Tooltip>
					</div>
				)}
				<div
					onClick={() => navigate('/my-device')}
					className={`${styles.headerItem} ${
						location.pathname.startsWith('/my-device') ? styles.active : ''
					}`}>
					<Tooltip title={t('My Device')}>
						<div className={styles.iconHolder}>
							<UserDeviceIcon height={'24px'} />
							<UserDeviceIconColor height={'24px'} className={styles.color} />
						</div>
					</Tooltip>
				</div>
			</div>
			<div className={`${styles.headerItems} ${styles.mobile}`}>
				{/* <div className={`${styles.lang}`}></div> */}
				{/* <div className={`${styles.languageDiv}`}>
					<p>{i18n.language.toUpperCase()}</p>
					<img className={`${styles.headerArrow}`} src={headerArrowDownSVG} />

					<div className={`${styles.dropdownLanguage}`}>
						<button onClick={() => changeLanguage('en')}>EN</button>
						<button onClick={() => changeLanguage('de')}>DE</button>
					</div>
				</div> */}

				<div aria-haspopup className={styles.burgerMenu} onClick={() => setMobileNavOpened(true)}>
					<BurgerMenu height={'24'} width={'24'} />
				</div>

				<div
					aria-expanded={mobileNavOpened}
					className={`${styles.overlay} ${mobileNavOpened ? styles.opened : ''}`}>
					<div
						onClick={() => {
							navigate('/dashboard');
							setMobileNavOpened(false);
						}}
						className={styles.burgerLogo}>
						<img src={logoSVG} alt="" />
					</div>
					<a className={styles.closeBtn} onClick={() => setMobileNavOpened(false)}>
						&times;
					</a>
					<div className={styles.overlayContent}>
						<div className={styles.navigationTop}>
							{user.permissions.includes('client.read') && (
								<div
									onClick={() => {
										setMobileNavOpened(false);
										navigate('/clients');
									}}
									className={`${styles.navItem} ${
										location.pathname.startsWith('/clients') ? styles.active : ''
									}`}>
									<div className={`${styles.iconHolder}`}>
										<ClientsIcon />
										<ClientsIconColor className={styles.color} />
									</div>
									<p className={styles.iconName}>{t('Clients')}</p>
								</div>
							)}

							{user.permissions.includes('lead.read') && (
								<div
									onClick={() => {
										setMobileNavOpened(false);
										navigate('/leads');
									}}
									className={`${styles.navItem} ${
										location.pathname.startsWith('/leads') ? styles.active : ''
									}`}>
									<div className={styles.iconHolder}>
										<LeadsIcon />
										<LeadsIconColor className={styles.color} />
									</div>
									<p className={styles.iconName}>{t('Leads')}</p>
								</div>
							)}

							{(user.role.name === 'Backoffice' || user.role.name === 'Company Owner') && (
								<div
									onClick={() => {
										setMobileNavOpened(false);
										navigate('/lead-statistics');
									}}
									className={`${styles.navItem} ${
										location.pathname.startsWith('/lead-statistics') ? styles.active : ''
									}`}>
									<div className={styles.iconHolder}>
										<LeadsStatisticsIcon />
										<LeadsStatisticsIconColor className={styles.color} />
									</div>
									<p className={styles.iconName}>{`${t('Lead')} ${t('Statistics')}`}</p>
								</div>
							)}

							{user.permissions.includes('document.read') && (
								<div
									onClick={() => {
										setMobileNavOpened(false);
										navigate('/documents');
									}}
									className={`${styles.navItem} ${
										location.pathname.startsWith('/documents') ? styles.active : ''
									}`}>
									<div className={styles.iconHolder}>
										<DocumentsIcon />
										<DocumentsIconColor className={styles.color} />
									</div>
									<p className={styles.iconName}>{t('Documents')}</p>
								</div>
							)}

							{/* {user.permissions.includes('statistic.read') && (
					<div
						onClick={() => navigate('/statistics')}
						className={`${styles.headerItem} ${
							location.pathname.startsWith('/statistics') ? styles.active : ''
						}`}>
						<Tooltip title="Statistics">
							<div className={styles.iconHolder}>
								<StatisticsIcon />
								<StatisticsIconColor className={styles.color} />
							</div>
						</Tooltip>
					</div>
				)} */}

							{user.permissions.includes('company.read') && (
								<div
									onClick={() => {
										setMobileNavOpened(false);
										navigate('/companies');
									}}
									className={`${styles.navItem} ${
										location.pathname.startsWith('/companies') ? styles.active : ''
									}`}>
									<div className={styles.iconHolder}>
										<CompanyIcon />
										<CompanyIconColor className={styles.color} />
									</div>
									<p className={styles.iconName}>{t('Companies')}</p>
								</div>
							)}

							{user.permissions.includes('device.read') && (
								<div
									onClick={() => {
										setMobileNavOpened(false);
										navigate('/devices');
									}}
									className={`${styles.navItem} ${
										location.pathname.startsWith('/devices') ? styles.active : ''
									}`}>
									<div className={styles.iconHolder}>
										<DeviceIcon height={'24px'} />
										<DeviceIconColor height={'24px'} className={styles.color} />
									</div>
									<p className={styles.iconName}>{t('Devices')}</p>
								</div>
							)}
							{user.permissions.includes('user.read') && (
								<div
									onClick={() => {
										setMobileNavOpened(false);
										navigate('/users');
									}}
									className={`${styles.navItem} ${
										location.pathname.startsWith('/users') ? styles.active : ''
									}`}>
									<div className={styles.iconHolder}>
										<UsersIcon />
										<UsersIconColor className={styles.color} />
									</div>
									<p className={styles.iconName}>{t('Users')}</p>
								</div>
							)}
							<div
								onClick={() => {
									setMobileNavOpened(false);
									navigate('/my-device');
								}}
								className={`${styles.navItem} ${
									location.pathname.startsWith('/my-device') ? styles.active : ''
								}`}>
								<div className={styles.iconHolder}>
									<UserDeviceIcon height={'24px'} />
									<UserDeviceIconColor height={'24px'} className={styles.color} />
								</div>
								<p className={styles.iconName}>{t('My Device')}</p>
							</div>

							<div
								onClick={() => {
									setMobileNavOpened(false);
									navigate('/settings');
								}}
								className={`${styles.navItem} ${
									location.pathname.startsWith('/settings') ? styles.active : ''
								}`}>
								<div className={styles.iconHolder}>
									<Settings height={'24px'} />
									<SettingsColor height={'24px'} className={styles.color} />
								</div>
								<p className={styles.iconName}>{t('Settings')}</p>
							</div>

							<div
								onClick={() => {
									logOut();
								}}
								className={`${styles.navItem}`}>
								<div className={styles.iconHolder}>
									<Logout height={'24px'} />
								</div>
								<p className={styles.iconName}>{t('Log Out')}</p>
							</div>
						</div>
					</div>
				</div>

				<div className={`${styles.profile} ${styles.dropdown}`}>
					{user.avatar ? <img src={user.avatar} /> : <UsersIcon />}
					<p>{user.firstName || ''}</p>
					{/* <img className={`${styles.headerArrow}`} src={headerArrowDownSVG} />
					<div className={`${styles.dropdownContent}`}>CONTENT</div> */}
				</div>
			</div>
		</header>
	);
};

export default Header;
