import logoSVG from 'assets/logo.svg';
import settingsSVG from 'assets/settings.svg';
import logoutSVG from 'assets/logout.svg';
import styles from './Nav.module.scss';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { logout } from 'services/auth.service';
import { useRecoilState } from 'recoil';
import { userState } from 'recoil/user';
import { Tooltip } from '@mui/material';
import { useContext } from 'react';
import { ReactComponent as Settings } from 'assets/settings.svg';
import { ReactComponent as Logout } from 'assets/logout.svg';
import { ReactComponent as NewLeadsGray } from 'assets/new-leads-gray.svg';
import { ReactComponent as NewLeadsColor } from 'assets/new-leads-color.svg';

import { ReactComponent as AllLeadsGray } from 'assets/all-leads-gray.svg';
import { ReactComponent as AllLeadsColor } from 'assets/all-leads-color.svg';

import { ReactComponent as PercentageGray } from 'assets/percent-gray.svg';
import { ReactComponent as PercentageColor } from 'assets/percent-color.svg';

import { ReactComponent as CallbackGray } from 'assets/callback-gray.svg';
import { ReactComponent as CallbackColor } from 'assets/callback-color.svg';

import { ReactComponent as NotReachedGray } from 'assets/not-reached-gray.svg';
import { ReactComponent as NotReachedColor } from 'assets/not-reached-color.svg';

import { ReactComponent as NotInterestedGray } from 'assets/not-interested-gray.svg';
import { ReactComponent as NotInterestedColor } from 'assets/not-interested-color.svg';

import { ReactComponent as PendingLeadGray } from 'assets/pending-lead-gray.svg';
import { ReactComponent as PendingLeadColor } from 'assets/pending-lead-color.svg';

import { ReactComponent as ScheduledLeadGray } from 'assets/scheduled-lead-gray.svg';
import { ReactComponent as ScheduledLeadColor } from 'assets/scheduled-lead-color.svg';

import { ReactComponent as InvalidLeadGray } from 'assets/invalid-lead-gray.svg';
import { ReactComponent as InvalidLeadColor } from 'assets/invalid-lead-color.svg';

import { ReactComponent as DoubleLeadGray } from 'assets/double-lead-gray.svg';
import { ReactComponent as DoubleLeadColor } from 'assets/double-lead-color.svg';

import { ReactComponent as NotCompletedLeadGray } from 'assets/not-completed-lead-gray.svg';
import { ReactComponent as NotCompletedLeadColor } from 'assets/not-completed-lead-color.svg';

import { ReactComponent as CompletedLeadGray } from 'assets/completed-lead-gray.svg';
import { ReactComponent as CompletedLeadColor } from 'assets/completed-lead-color.svg';
import { useTranslation } from 'react-i18next';
type NavProps = {
	leadStatusSelected?: string;
	setLeadStatusSelected?: any;
	setFilters: any;
};

const Nav = ({ leadStatusSelected, setLeadStatusSelected, setFilters }: NavProps) => {
	const navigate = useNavigate();
	const [user, setUser] = useRecoilState(userState);
	const location = useLocation();
	const { t } = useTranslation('translation');
	const logOut = () => {
		logout();
		setUser(null);
		navigate('/');
	};

	return (
		<>
			<div className={`${styles.logo}`}>
				<Link to="/dashboard">
					<img src={logoSVG} />
				</Link>
			</div>
			<nav className={`${styles.nav} ${!location.pathname.endsWith('/leads') ? styles.notInLeads : ''}`}>
				{location.pathname.endsWith('/leads') && (
					<div className={`${styles.navIcons} ${styles.overflow}`}>
						<Tooltip placement="right-start" title={t('New Leads')}>
							<div
								onClick={() => {
									setLeadStatusSelected('New Lead');
									setFilters({ type: 'changeStatus', newStatus: 'New Lead' });
								}}
								className={`${styles.svgHolder} ${
									leadStatusSelected == 'New Lead' ? styles.active : ''
								}`}>
								<NewLeadsGray />
								<NewLeadsColor className={styles.color} />
							</div>
						</Tooltip>
						<Tooltip placement="right-start" title={t('All Leads')}>
							<div
								onClick={() => {
									setLeadStatusSelected('All Leads');
									setFilters({ type: 'changeStatus', newStatus: 'All Leads' });
								}}
								className={`${styles.svgHolder} ${
									leadStatusSelected == 'All Leads' ? styles.active : ''
								}`}>
								<AllLeadsGray />
								<AllLeadsColor className={styles.color} />
							</div>
						</Tooltip>
						<Tooltip placement="right-start" title={t('Call Back')}>
							<div
								onClick={() => {
									setLeadStatusSelected('Callback');
									setFilters({ type: 'changeStatus', newStatus: 'Callback' });
								}}
								className={`${styles.svgHolder} ${
									leadStatusSelected == 'Callback' ? styles.active : ''
								}`}>
								<CallbackGray />
								<CallbackColor className={styles.color} />
							</div>
						</Tooltip>
						<Tooltip placement="right-start" title={t('Not Reached')}>
							<div
								onClick={() => {
									setLeadStatusSelected('Not reached');
									setFilters({ type: 'changeStatus', newStatus: 'Not reached' });
								}}
								className={`${styles.svgHolder} ${
									leadStatusSelected == 'Not reached' ? styles.active : ''
								}`}>
								<NotReachedGray />
								<NotReachedColor className={styles.color} />
							</div>
						</Tooltip>
						<Tooltip placement="right-start" title={t('Not Interested')}>
							<div
								onClick={() => {
									setLeadStatusSelected('Not Interested');
									setFilters({ type: 'changeStatus', newStatus: 'Not Interested' });
								}}
								className={`${styles.svgHolder} ${
									leadStatusSelected == 'Not Interested' ? styles.active : ''
								}`}>
								<NotInterestedGray />
								<NotInterestedColor className={styles.color} />
							</div>
						</Tooltip>
						<Tooltip placement="right-start" title={t('Pending')}>
							<div
								onClick={() => {
									setLeadStatusSelected('Pending');
									setFilters({ type: 'changeStatus', newStatus: 'Pending' });
								}}
								className={`${styles.svgHolder} ${
									leadStatusSelected == 'Pending' ? styles.active : ''
								}`}>
								<PendingLeadGray />
								<PendingLeadColor className={styles.color} />
							</div>
						</Tooltip>
						<Tooltip placement="right-start" title={t('Scheduled')}>
							<div
								onClick={() => {
									setLeadStatusSelected('Scheduled');
									setFilters({ type: 'changeStatus', newStatus: 'Scheduled' });
								}}
								className={`${styles.svgHolder} ${
									leadStatusSelected == 'Scheduled' ? styles.active : ''
								}`}>
								<ScheduledLeadGray />
								<ScheduledLeadColor className={styles.color} />
							</div>
						</Tooltip>
						<Tooltip placement="right-start" title={t('Invalid Number')}>
							<div
								onClick={() => {
									setLeadStatusSelected('Invalid Number');
									setFilters({ type: 'changeStatus', newStatus: 'Invalid Number' });
								}}
								className={`${styles.svgHolder} ${
									leadStatusSelected == 'Invalid Number' ? styles.active : ''
								}`}>
								<InvalidLeadGray />
								<InvalidLeadColor className={styles.color} />
							</div>
						</Tooltip>
						<Tooltip placement="right-start" title={t('Double')}>
							<div
								onClick={() => {
									setLeadStatusSelected('Double');
									setFilters({ type: 'changeStatus', newStatus: 'Double' });
								}}
								className={`${styles.svgHolder} ${
									leadStatusSelected == 'Double' ? styles.active : ''
								}`}>
								<DoubleLeadGray />
								<DoubleLeadColor className={styles.color} />
							</div>
						</Tooltip>
						<Tooltip placement="right-start" title={t('Not Completed')}>
							<div
								onClick={() => {
									setLeadStatusSelected('Not completed');
									setFilters({ type: 'changeStatus', newStatus: 'Not completed' });
								}}
								className={`${styles.svgHolder} ${
									leadStatusSelected == 'Not completed' ? styles.active : ''
								}`}>
								<NotCompletedLeadGray />
								<NotCompletedLeadColor className={styles.color} />
							</div>
						</Tooltip>
						<Tooltip placement="right-start" title={t('Completed')}>
							<div
								onClick={() => {
									setLeadStatusSelected('Completed');
									setFilters({ type: 'changeStatus', newStatus: 'Completed' });
								}}
								className={`${styles.svgHolder} ${
									leadStatusSelected == 'Completed' ? styles.active : ''
								}`}>
								<CompletedLeadGray />
								<CompletedLeadColor className={styles.color} />
							</div>
						</Tooltip>
					</div>
				)}

				<div className={`${styles.navIcons}`}>
					<Tooltip placement="right-start" title={t('Settings')}>
						<div className={styles.svgHolder} style={{ marginBottom: '20px' }}>
							<Link to="/settings">
								<Settings />
							</Link>
						</div>
					</Tooltip>
					<Tooltip placement="right-start" title="Logout">
						<div className={styles.svgHolder}>
							<Link onClick={logOut} to="/" aria-label="Logout">
								<Logout />
							</Link>
						</div>
					</Tooltip>
				</div>
			</nav>
		</>
	);
};

export default Nav;
