import { Cropper } from 'react-cropper';
import { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import 'cropperjs/dist/cropper.css';
import styles from './Cropper.module.scss';
import { uploadFile } from 'services/file.service';
import { changeLogo, changeImage, changeColors } from 'services/locations.service';
import { getOneLocation } from 'services/clients.service';
import { toastSuccess } from 'services/toast';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading/Loading';
import { getMyLocation } from 'services/auth.service';
import { getMyOneLocation } from 'services/users.service';
type CropComponentProps = {
	logoUrl: any;
	imageUrl: any;
	cropOpened: boolean;
	toggleCrop: any;
	setCroppedImage: any;
	setCroppedLogo: any;
	imageType: string;
	clientID: any;
	locationID: any;
	setSelectedLocation: any;
	setLoading?: any;
	fromAgent?: boolean;
	props?: any;
};

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		zIndex: '10',
	},
};

const CropperComponent = ({
	logoUrl,
	imageUrl,
	cropOpened,
	toggleCrop,
	setCroppedImage,
	setCroppedLogo,
	imageType,
	clientID,
	locationID,
	setSelectedLocation,
	setLoading,
	fromAgent,
	props,
}: CropComponentProps) => {
	const [isOpen, setIsOpen] = useState(cropOpened);
	const croppedCanvas = useRef<HTMLImageElement>(null);
	const { t } = useTranslation('translation');

	useEffect(() => {
		setIsOpen(cropOpened);
	}, [cropOpened]);

	const crop = async () => {
		setLoading(true);
		const imageElement: any = croppedCanvas?.current;
		const cropper: any = imageElement?.cropper;
		const blob = await (await fetch(cropper.getCroppedCanvas().toDataURL())).blob();
		const file = new File([blob], 'cropped.jpg', { type: 'image/jpeg' });
		// if (imageType === 'logo') {
		//   setCroppedLogo(file)
		//   toggleCrop()
		// } else {
		//   setCroppedImage(file)
		//   toggleCrop()
		// }
		const data = new FormData();
		data.append('file', file);

		try {
			const response = await uploadFile(data);
			if (response) {
				const filename = response.file.filename;
				const fileID = filename.split('.')[0];

				if (imageType == 'logo') {
					const responseLogo = await changeLogo(clientID, locationID, fileID);
					toggleCrop();

					if (responseLogo) {
						let response;
						if (!fromAgent) {
							response = await getOneLocation(clientID, locationID);
							setSelectedLocation(response.data);
						} else {
							response = await getMyOneLocation(locationID);
							setSelectedLocation(response.data);
						}

						if (response) {
							setCroppedImage(response?.data?.pageSetup?.image?.link);
							setCroppedLogo(response?.data?.pageSetup?.logo?.link);
							toastSuccess(t('Changes saved'));
						}
					}
				} else {
					const responseImage = await changeImage(clientID, locationID, fileID);
					toggleCrop();

					if (responseImage) {
						let response;
						if (!fromAgent) {
							response = await getOneLocation(clientID, locationID);
							setSelectedLocation(response.data);
						} else {
							response = await getMyOneLocation(locationID);
							setSelectedLocation(response.data);
						}
						if (response) {
							setCroppedImage(response?.data?.pageSetup?.image?.link);
							setCroppedLogo(response?.data?.pageSetup?.logo?.link);
							toastSuccess(t('Changes saved'));
						}
					}
				}
			}
			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<div>
			<Modal ariaHideApp={false} isOpen={isOpen} contentLabel="Crop image" style={customStyles}>
				<div className={styles.cropperComponentContainer}>
					<Cropper
						src={imageType === 'logo' ? logoUrl : imageUrl}
						style={{ height: 400, width: '100%' }}
						aspectRatio={imageType === 'logo' ? 1 / 1 : 16 / 9}
						ref={croppedCanvas}
					/>
					<div className={styles.cropDiv}>
						<button className={styles.toggleBtn} onClick={() => toggleCrop()}>
							{t('Cancel')}
						</button>
						<button className={styles.cropBtn} onClick={() => crop()}>
							{t('Crop')}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default CropperComponent;
