import styles from './ProgressBar.module.scss';

type ProgressBarProps = {
	percentage: number;
	index: number;
	option: string;
};

const ProgressBar = ({ percentage, index, option }: ProgressBarProps) => {
	return (
		<div className={styles.containerStyles}>
			<p className={styles.progressText}>
				{`${index}.`} <b>{percentage ? percentage : '0'}%</b> <span>{option}</span>
			</p>
			<div style={{ width: `${percentage ? percentage : '0'}%` }} className={styles.fillerStyles}>
				<span className={styles.labelStyles}></span>
			</div>
		</div>
	);
};

export default ProgressBar;
