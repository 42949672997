import { AxiosError } from 'axios';
import api from './api';
import { LeadNewForm } from 'pages/Leads/LeadNew/LeadNew';

export const getAllLeads = async (
	company?: string,
	skip?: number,
	limit?: number,
	search?: string,
	status?: string,
	user?: string,
) => {
	try {
		let params: any = {};
		if (company) params = { ...params, company };
		if (skip) params = { ...params, skip };
		if (limit) params = { ...params, limit };
		if (search) params = { ...params, search };
		if (status) params = { ...params, status };
		if (user) params = { ...params, user };

		const res = api.get('/leads', { params }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getAllLeads', error);
		throw error;
	}
};

export const getLeadByID = async (id: string) => {
	try {
		const res = api.get(`/leads/${id}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getLeadByID', error);
		throw error;
	}
};

export const updateLead = async (leadID: string, data: any) => {
	try {
		const res = api.put(`/leads/${leadID}`, data).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('updateLead', error);
		throw error;
	}
};

export const deleteLead = async (leadID: string) => {
	try {
		const res = api.delete(`/leads/${leadID}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('deleteLead', error);
		throw error;
	}
};
export const createLead = async (data: LeadNewForm) => {
	try {
		const res = api.post('/leads', data).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('createlead', error);
		throw error;
	}
};
export const transformToClient = async (leadID: string, data: any) => {
	try {
		const res = api.post(`/leads/${leadID}/client`, data).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('transformToClient', error);
		throw error;
	}
};
export const getLeadNotes = async (leadID: string) => {
	try {
		const res = api.get(`/leads/${leadID}/notes`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getLeadNotes', error);
		throw error;
	}
};

export const createNote = async (leadID: string, content: string) => {
	try {
		const res = await api.post(`/leads/${leadID}/notes`, { content }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('createNote', error);
		throw error;
	}
};

export const editNote = async (leadID: string, noteID: string, content: string) => {
	try {
		const res = await api.patch(`/leads/${leadID}/notes/${noteID}`, { content }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('editNote', error);
		throw error;
	}
};
export const deleteNote = async (leadID: string, noteID: string) => {
	try {
		const res = await api.delete(`/leads/${leadID}/notes/${noteID}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('deleteNote', error);
		throw error;
	}
};

export const getAllDocuments = async (leadID: string) => {
	try {
		const res = await api.get(`/leads/${leadID}/documents`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getAllDocuments', error);
		throw error;
	}
};

export const updateDocument = async (leadID: string, documentID: string, data: any) => {
	try {
		const res = await api.patch(`/leads/${leadID}/documents/${documentID}`, data).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('UpdateDocument', error);
		throw error;
	}
};

export const deleteDocument = async (leadID: string, documentID: string) => {
	try {
		const res = await api.delete(`/leads/${leadID}/documents/${documentID}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('DeleteDocument', error);
		throw error;
	}
};

export const uploadDocument = async (name: string, file: string, leadID: string) => {
	try {
		const data = {
			name,
			file,
		};
		const res = await api.post(`/leads/${leadID}/documents`, data).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('UploadDocument', error);
		throw error;
	}
};

export const changeStatus = async (leadID: string, status: string, date?: Date) => {
	try {
		const res = await api.patch(`/leads/${leadID}/status`, { status, statusDate: date }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('ChangeStatus', error);
		throw error;
	}
};

export const getScheduledLeads = async () => {
	try {
		const res = await api.get('/leads/scheduled').then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getScheduledLeads', error);
		throw error;
	}
};

export const assignLeads = async (leadIds: any, agentId: any) => {
	try {
		const res = await api.post('/leads/reassign', { leadIds, agentId }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('assignLeads', error);
		throw error;
	}
};

export const getLeadStatisticsGeneral = async () => {
	try {
		const res = await api.get('/leads/statistics').then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getLeadStatisticsGeneral', error);
		throw error;
	}
};
export const getLeadStatisticsPhase = async (phase?: any) => {
	try {
		let params: any = {};
		if (phase) params = { ...params, phase };

		const res = await api.get('/leads/statistics/phase', { params }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getLeadStatisticsGeneral', error);
		throw error;
	}
};
