import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLeadStatisticsGeneral, getLeadStatisticsPhase } from 'services/leads.service';
import { toastError } from 'services/toast';
import styles from './LeadsStatistics.module.scss';

import { ReactComponent as NewLeadsStatisticsIcon } from 'assets/new-lead-gray.svg';
import { ReactComponent as NewLeadsStatisticsIconColor } from 'assets/new-lead-color.svg';

import { ReactComponent as ScheduledLeadsStatisticsIcon } from 'assets/scheduled-gray.svg';
import { ReactComponent as ScheduledLeadsStatisticsIconColor } from 'assets/scheduled-color.svg';

import { ReactComponent as NotInterestedLeadsStatisticsIcon } from 'assets/not-interested-stat-gray.svg';
import { ReactComponent as NotInterestedLeadsStatisticsIconColor } from 'assets/not-interested-stat-color.svg';

import { ReactComponent as CompletedLeadsStatisticsIcon } from 'assets/completed-gray.svg';
import { ReactComponent as CompletedLeadsStatisticsIconColor } from 'assets/completed-color.svg';
import { useNavigate } from 'react-router';

type LeadStatisticsProps = {
	leadStatisticsStatusSelected: string;
	setLeadStatisticsStatusSelected: any;
};

const LeadsStatistics = () => {
	const { t } = useTranslation('translation');
	const [phaseOne, setPhaseOne] = useState<any>({});
	const [phaseTwo, setPhaseTwo] = useState<any>({});
	const [phaseThree, setPhaseThree] = useState<any>({});
	const [phaseFour, setPhaseFour] = useState<any>({});
	const [leadStatisticsStatusSelected, setLeadStatisticsStatusSelected] = useState('New Leads');

	useEffect(() => {
		try {
			(async () => {
				const allGeneral = await getLeadStatisticsGeneral();
				allGeneral.phases.map((phase: any) => {
					if (phase.phase === 1) {
						setPhaseOne({ count: phase.count, percentage: phase.percentage });
					}
					if (phase.phase === 2) {
						setPhaseTwo({ count: phase.count, percentage: phase.percentage });
					}
					if (phase.phase === 3) {
						setPhaseThree({ count: phase.count, percentage: phase.percentage });
					}
					if (phase.phase === 4) {
						setPhaseFour({ count: phase.count, percentage: phase.percentage });
					}
				});
			})();
		} catch (error) {
			toastError(t('Something went wrong'));
		}
	}, []);
	return (
		<section>
			<div className={styles.content}>
				<div className={styles.tabsHolder}>
					<div className={`${styles.tabs}`}>
						<button
							onClick={() => setLeadStatisticsStatusSelected('New Leads')}
							className={`${styles.tab} ${
								leadStatisticsStatusSelected == 'New Leads' ? styles.active : null
							}`}>
							<div className={`${styles.iconAndName} `}>
								<div
									className={`${styles.iconHolder} ${
										leadStatisticsStatusSelected == 'New Leads' ? styles.active : null
									}`}>
									<NewLeadsStatisticsIcon />
									<NewLeadsStatisticsIconColor className={styles.color} />
								</div>
								<p className={styles.name}>{t('New Leads')}</p>
							</div>
							<div className={styles.infoDiv}>
								{phaseOne.count} ({phaseOne.percentage}%)
							</div>
						</button>
						<button
							onClick={() => setLeadStatisticsStatusSelected('Scheduled')}
							className={`${styles.tab} ${
								leadStatisticsStatusSelected == 'Scheduled' ? styles.active : null
							}`}>
							<div className={`${styles.iconAndName} `}>
								<div
									className={`${styles.iconHolder} ${
										leadStatisticsStatusSelected == 'Scheduled' ? styles.active : null
									}`}>
									<ScheduledLeadsStatisticsIcon />
									<ScheduledLeadsStatisticsIconColor className={styles.color} />
								</div>
								<p className={styles.name}>{t('Scheduled')}</p>
							</div>
							<div className={styles.infoDiv}>
								{phaseTwo.count} ({phaseTwo.percentage}%)
							</div>
						</button>
						<button
							onClick={() => setLeadStatisticsStatusSelected('Not Interested')}
							className={`${styles.tab} ${
								leadStatisticsStatusSelected == 'Not Interested' ? styles.active : null
							}`}>
							<div className={`${styles.iconAndName} `}>
								<div
									className={`${styles.iconHolder} ${
										leadStatisticsStatusSelected == 'Not Interested' ? styles.active : null
									}`}>
									<NotInterestedLeadsStatisticsIcon />
									<NotInterestedLeadsStatisticsIconColor className={styles.color} />
								</div>
								<p className={styles.name}>{t('Not Interested')}</p>
							</div>
							<div className={styles.infoDiv}>
								{phaseThree.count} ({phaseThree.percentage}%)
							</div>
						</button>
						<button
							onClick={() => setLeadStatisticsStatusSelected('Completed')}
							className={`${styles.tab} ${
								leadStatisticsStatusSelected == 'Completed' ? styles.active : null
							}`}>
							<div className={`${styles.iconAndName} `}>
								<div
									className={`${styles.iconHolder} ${
										leadStatisticsStatusSelected == 'Completed' ? styles.active : null
									}`}>
									<CompletedLeadsStatisticsIcon />
									<CompletedLeadsStatisticsIconColor className={styles.color} />
								</div>
								<p className={styles.name}>{t('Completed')}</p>
							</div>
							<div className={styles.infoDiv}>
								{phaseFour.count} ({phaseFour.percentage}%)
							</div>
						</button>
					</div>
				</div>
				{leadStatisticsStatusSelected == 'New Leads' && (
					<LeadStatisticsComponent showNotes={true} phase={1}></LeadStatisticsComponent>
				)}
				{leadStatisticsStatusSelected == 'Scheduled' && (
					<LeadStatisticsComponent showNotes={true} phase={2}></LeadStatisticsComponent>
				)}
				{leadStatisticsStatusSelected == 'Not Interested' && (
					<LeadStatisticsComponent showNotes={true} phase={3}></LeadStatisticsComponent>
				)}
				{leadStatisticsStatusSelected == 'Completed' && (
					<LeadStatisticsComponent showNotes={true} phase={4}></LeadStatisticsComponent>
				)}
			</div>
		</section>
	);
};

export default LeadsStatistics;

type LeadStatisticsComponentProps = {
	phase: number;
	showNotes: boolean;
};
const LeadStatisticsComponent = ({ phase, showNotes }: LeadStatisticsComponentProps) => {
	const { t } = useTranslation('translation');
	const [all, setAll] = useState([]);
	const navigate = useNavigate();
	useEffect(() => {
		try {
			(async () => {
				const allLeads = await getLeadStatisticsPhase(phase);
				setAll(allLeads.data);
			})();
		} catch (error) {
			toastError(t('Cannot get leads'));
		}
	}, []);
	return (
		<div className={styles.leadStatsComponent}>
			<div className={styles.content}>
				{all &&
					all.map((lead: any) => {
						return (
							<div key={lead._id} className={styles.oneLead}>
								<div className={styles.header}>
									<p onClick={() => navigate(`/leads/${lead._id}`)}>{lead?.name}</p>
								</div>

								<div className={styles.infoGrid}>
									<div className={styles.oneInfo}>
										<p className={styles.infoName}>{t('Client')}</p>
										<span>
											{lead?.firstName} {lead?.lastName}
										</span>
									</div>
									<div className={styles.oneInfo}>
										<p className={styles.infoName}>{t('Country')}</p>
										<span>{`${lead?.country}`}</span>
									</div>
									<div className={styles.oneInfo}>
										<p className={styles.infoName}>{t('Place')}</p>
										<span>{`${lead?.city}`}</span>
									</div>
									<div className={styles.oneInfo}>
										<p className={styles.infoName}>{t('Agent')}</p>
										<span>{`${lead?.user?.firstName} ${lead?.user?.lastName}`}</span>
									</div>
								</div>

								{showNotes && (
									<div className={styles.notes}>
										{lead?.notes.length > 0 && <p className={styles.title}>Notes</p>}
										{lead?.notes.length > 0 && (
											<div className={`${styles.notesHolder}`}>
												{lead?.notes.map((note: any, i: any) => {
													return (
														<div key={i} className={styles.oneNote}>
															<div className={styles.note}>{note?.content}</div>
														</div>
													);
												})}
											</div>
										)}
										{/* {lead.notes.length == 0 && (
											<p style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
												{t('No Notes')}
											</p>
										)} */}
									</div>
								)}
							</div>
						);
					})}
			</div>
		</div>
	);
};
