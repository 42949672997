import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from 'recoil/user';
import { getMyInfo, login } from 'services/auth.service';
import { toastError } from 'services/toast';
import styles from './Login.module.scss';
import { useLocalStorage } from 'hooks/useLocalStorage';

import { ReactComponent as Logo } from 'assets/logo.svg';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
type LoginForm = {
	email: string;
	password: string;
};

const Login = () => {
	const [user, setUser] = useRecoilState(userState);
	const navigate = useNavigate();
	const { t, i18n } = useTranslation('translation');

	useEffect(() => {
		if (user) {
			navigate('/dashboard');
		}
	}, [user]);

	const { handleSubmit, formState, control } = useForm<LoginForm>({
		defaultValues: {
			email: '',
			password: '',
		},
	});

	const onSubmit: SubmitHandler<LoginForm> = async (data) => {
		const token = await login(data.email, data.password);
		if (token) {
			localStorage.setItem('token', token);
			const myInfo = await getMyInfo();
			if (myInfo.role.name === 'SuperAdmin') {
				myInfo.isAdmin = true;
			} else {
				myInfo.isAdmin = false;
			}
			setUser(myInfo);
			navigate('/dashboard');
		} else {
			toastError(t('Invalid credentials'));
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.holder}>
				<div className={styles.logo}>
					<Logo height="66" width="100" />
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						name="email"
						control={control}
						rules={{ required: true }}
						render={({ field, fieldState }) => (
							<TextField
								fullWidth
								type="email"
								label="Email"
								className={styles.textfield}
								value={field.value}
								onChange={field.onChange}
								error={!!fieldState.error}
								helperText={fieldState.error ? fieldState.error.message : null}
							/>
						)}
					/>
					<Controller
						name="password"
						control={control}
						rules={{ required: true }}
						render={({ field, fieldState }) => (
							<TextField
								fullWidth
								className={styles.textfield}
								type="password"
								label={t('Password')}
								value={field.value}
								onChange={field.onChange}
								error={!!fieldState.error}
								helperText={fieldState.error ? fieldState.error.message : null}
							/>
						)}
					/>
					<div className={styles.buttonHolder}>
						<button type="submit" disabled={formState.isSubmitting}>
							LOGIN
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Login;
