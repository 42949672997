import api from './api';

export const getAllSurveys = async (locationID: string, userID: string) => {
	try {
		const resp = await api.get(`/clients/${userID}/locations/${locationID}/surveys`).then((res: any) => res.data);
		return resp;
	} catch (error) {
		console.log('getAllSurveys', error);
		throw error;
	}
};
export const createNewSurvey = async (locationID: string, title: string, userID: string) => {
	try {
		const resp = await api
			.post(`/clients/${userID}/locations/${locationID}/surveys`, { title })
			.then((res: any) => res.data);
		return resp;
	} catch (error) {
		console.log('createNewSurvey', error);
		throw error;
	}
};
export const deleteSurvey = async (locationID: string, surveyID: string, userID: string) => {
	try {
		const resp = await api
			.delete(`/clients/${userID}/locations/${locationID}/surveys/${surveyID}`)
			.then((res: any) => res.data);
		return resp;
	} catch (error) {
		console.log('deleteSurvey', error);
		throw error;
	}
};
export const updateSurvey = async (locationID: string, surveyID: string, data: any, userID: any) => {
	try {
		const resp = await api
			.put(`/clients/${userID}/locations/${locationID}/surveys/${surveyID}`, { ...data })
			.then((res: any) => res.data);
		return resp;
	} catch (error) {
		console.log('updateSurvey', error);
		throw error;
	}
};
export const activateSurvey = async (locationID: string, surveyID: string, userID: string) => {
	try {
		const resp = await api
			.post(`/clients/${userID}/locations/${locationID}/surveys/activate`, { id: surveyID })
			.then((res: any) => res.data);
		return resp;
	} catch (error) {
		console.log('activateSurvey', error);
		throw error;
	}
};
export const getSurveyStats = async (locationID: any, surveyID: any, userID: string) => {
	try {
		const resp = await api
			.get(`/clients/${userID}/locations/${locationID}/surveys/${surveyID}/statistics`)
			.then((res: any) => res.data);
		return resp;
	} catch (error) {
		console.log('getSurveyStats', error);
		throw error;
	}
};
