import styles from './ConfirmPopup.module.scss';
import { ReactComponent as XIcon } from 'assets/x-icon-black.svg';
import { useTranslation } from 'react-i18next';

type PopupProps = {
	show: boolean;
	title?: string;
	children?: any;
	onConfirm: any;
	onCancel: any;
	confirmText?: string;
};
const ConfirmPopup = ({ show, title, children, onConfirm, onCancel, confirmText }: PopupProps) => {
	const { t } = useTranslation('translation');

	const confirm = confirmText as string;
	// console.log(t(confirm));

	return (
		<div onClick={() => onCancel()} className={`${styles.modal} ${show ? styles.enterDone : styles.exit}`}>
			<div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
				<div className={styles.modalHeader}>
					<p className={styles.modalTitle}>
						{title
							? t(title as unknown as TemplateStringsArray)
							: `${t('Are you sure' as unknown as TemplateStringsArray)}?`}
					</p>
					{children && (
						<div className={styles.modalCloseBtn} onClick={() => onCancel()}>
							<XIcon />
						</div>
					)}
				</div>
				{children && <div className={styles.modalBody}>{children}</div>}
				<div className={styles.modalFooter}>
					<button onClick={() => onCancel()} className={styles.cancelButton}>
						{t('Cancel')}
					</button>
					<button onClick={() => onConfirm()} className={styles.confirmButton}>
						{confirmText ? t(confirm as unknown as TemplateStringsArray) : t('Save')}
					</button>
				</div>
			</div>
		</div>
	);
};

export default ConfirmPopup;
