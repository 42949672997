import { ReactComponent as DefaultImage } from 'assets/default-image.svg';
import { useTranslation } from 'react-i18next';
import { toastError, toastSuccess } from 'services/toast';
import styles from './MyDevice.module.scss';
import CropperComponent from 'components/Cropper/Cropper';
import { userState } from 'recoil/user';
import { Navigate, useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { useState, useRef, useEffect, useCallback } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { ReactComponent as BurgerMenu } from 'assets/burger-menu-link.svg';
import { ReactComponent as TrashCan } from 'assets/trash-can.svg';
import { ReactComponent as PowerIcon } from 'assets/power.svg';
import { ReactComponent as FacebookLogo } from 'assets/FacebookLogo.svg';
import { ReactComponent as TripadvisorLogo } from 'assets/TripadvisorLogo.svg';
import { ReactComponent as EditButton } from 'assets/edit.svg';
import { ReactComponent as WebsiteLogo } from 'assets/WebsiteLogo.svg';
import { ReactComponent as CheckCircleOutline } from 'assets/check-circle-white.svg';
import { ReactComponent as GoogleLogo } from 'assets/GoogleLogo.svg';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';

import PopoverPicker from 'components/PopoverPicker/PopoverPicker';
import Map from 'components/Map/Map';
import { ReactComponent as TrashCanButton } from 'assets/trash-can.svg';
import { addMyDevice, getMyInfo, removeMyDevice } from 'services/auth.service';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
	changeColors,
	changeLinkOrder,
	getClickStats,
	getLinks,
	getReviewStats,
	postLink,
	changeText,
	deleteOneLink,
	updateVCard,
} from 'services/locations.service';
import { Switch, TextField } from '@mui/material';
import { getMyLocation, updateDevice } from 'services/auth.service';
import Loading from 'components/Loading/Loading';
import { updateDownloadAppText, updateLinkName } from 'services/clients.service';
import ConfirmPopup from 'components/ConfirmPopup/ConfirmPopup';
import useEyeDropper from 'use-eye-dropper';
import {
	activateLocation,
	deleteOneLocation,
	createMyLocation,
	getMyLocations,
	getMyOneLocation,
} from 'services/users.service';
import useClickOutside from 'hooks/useClickOutside';
import Surveys from './Surveys/Surveys';
import Survey from './Surveys/Survey/Survey';

const MyDevice = () => {
	const { t } = useTranslation('translation');
	const [user, setUser] = useRecoilState(userState);
	const [activeTab, setActiveTab] = useState('editWebsite');
	const [loading, setLoading] = useState(false);
	const [userDevices, setUserDevices] = useState<any>(null);
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedLogo, setSelectedLogo] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);
	const [croppedLogo, setCroppedLogo] = useState(null);
	const inputLogoRef = useRef<HTMLInputElement>(null);
	const inputImageRef = useRef<HTMLInputElement>(null);
	const [cropOpened, setCropOpened] = useState(false);
	const [imageType, setImageType] = useState('');
	const [selectedLocation, setSelectedLocation] = useState<any>('');
	const [selectedSurvey, setSelectedSurvey] = useState<any>('');
	const [oneSurveyActive, setOneSurveyActive] = useState(false);
	const [selectedLocationID, setSelectedLocationID] = useState<any>('');
	const [selectedLocationCopy, setSelectedLocationCopy] = useState<any>('');
	const [background, setBackground] = useState('#ffffff');
	const [backgroundLogo, setBackgroundLogo] = useState('#ffffff');
	const [backgroundButtons, setBackgroundButtons] = useState('#ffffff');
	const [colorButtons, setColorButtons] = useState('#ffffff');
	const [message, setMessage] = useState('Thank you for you time to give us a review');
	const [messageCopy, setMessageCopy] = useState('Thank you for you time to give us a review');
	const [links, setLinks] = useState<any>(null);
	const [newDevice, setNewDevice] = useState<any>('');
	const [showRemoveDevicePopup, setShowRemoveDevicePopup] = useState(false);
	const [selectedDevice, setSelectedDevice] = useState('');
	const navigate = useNavigate();
	const { isSupported } = useEyeDropper();

	const [allLocations, setAllLocations] = useState([]);
	const [selectedMyLocation, setSelectedMyLocation] = useState('');
	const [locationSelectOpened, setLocationSelectOpened] = useState(false);
	const [showDeleteLocationPopup, setShowDeleteLocationPopup] = useState(false);
	const [locationForDeletion, setLocationForDeletion] = useState('');
	const closeLocationSelect = useCallback(() => {
		setLocationSelectOpened(false);
		setNewLocationName('');
	}, []);
	const [activeLocation, setActiveLocation] = useState<any>('');
	const locationsSelection = useRef() as React.MutableRefObject<HTMLDivElement>;
	const [newLocationName, setNewLocationName] = useState('');
	useClickOutside(locationsSelection, closeLocationSelect);

	useEffect(() => {
		getAllLocationInfo();
		setUserDevices(user?.device);
	}, [activeLocation]);

	useEffect(() => {
		if (selectedLocationID) {
			websiteGetLinks();
			getLocationInfo();
		}
	}, [selectedLocationID]);

	const websiteGetLinks = async () => {
		let data = await getLinks(user?._id, selectedLocationID);
		let iosData: any = null;
		let playstoreData: any = null;
		data?.forEach((link: any) => {
			if (link.name === 'ios') {
				iosData = link;
			}
			if (link.name === 'android') {
				playstoreData = link;
				// let androidLink = data.find(
				//   (e: any) => e.name === "download app"
				// );
				// console.log(androidLink);
				// androidLink.links.playStore = playStoreLink;
			}
		});

		// data.splice(iosLink?.sort, 0, {
		//   client: iosLink?.client,
		//   name: "download app",
		//   sort: iosLink?.sort,
		//   links: { appStore: iosLink?.link, playStore: "" },
		//   _id: iosLink?._id,
		// });

		if (!data.some((e: any) => e.name == 'download app')) {
			const iosLink = data.find((e: any) => e.name == 'ios');
			data?.splice(iosLink?.sort, 0, {
				client: iosLink?.client,
				name: 'download app',
				sort: iosLink?.sort,
				links: { appStore: iosData?.link, playStore: playstoreData?.link },
				_id: iosLink?._id,
			});
		}

		data = data.filter((e: any) => e.name !== 'ios');
		data = data.filter((e: any) => e.name !== 'android');

		setLinks(data);
	};

	const getAllLocationInfo = async () => {
		try {
			const all = await getMyLocations();
			setAllLocations(all.data);
			setActiveLocation(all.activeLocation);
			if (all.data.length > 0) {
				if (!selectedLocationID) {
					setSelectedLocationID(all.data[0]._id);
					setSelectedLocation(all.data[0]);
				}
			}
		} catch (error) {
			toastError(t('Something went wrong'));
		}
	};

	const createNewLocation = async () => {
		try {
			await createMyLocation(newLocationName);
			setNewLocationName('');
			getAllLocationInfo();
		} catch (error) {
			toastError(t('Something went wrong'));
		}
	};

	const getLocationInfo = async () => {
		try {
			if (selectedLocationID) {
				const location = await getMyOneLocation(selectedLocationID);
				// setSelectedLocation(location?.data);
				setBackground(location?.data?.pageSetup?.background);
				setBackgroundButtons(location?.data?.pageSetup?.backgroundButtons);
				setBackgroundLogo(location?.data?.pageSetup?.backgroundLogo);
				setColorButtons(location?.data?.pageSetup?.colorButtons);
				setCroppedImage(location?.data?.pageSetup?.image?.link);
				setCroppedLogo(location?.data?.pageSetup?.logo?.link);
				setSelectedLocation(location?.data);
				setMessage(location?.data?.pageSetup?.text);
				setMessageCopy(location?.data?.pageSetup?.text);
			}
		} catch (error) {
			// toastError(t('Cannot get info about this location'));
			toastError(t('Something went wrong'));

			navigate('dashboard');
		}
	};

	const onInputClick = (event: any) => {
		const element = event.target as HTMLInputElement;
		element.value = '';
	};

	const logoSelect = () => {
		setImageType('logo');
		inputLogoRef.current?.click();
	};
	const imageSelect = () => {
		setImageType('image');
		inputImageRef.current?.click();
	};
	const toggleCrop = () => {
		setCropOpened(!cropOpened);
	};
	const saveBackground = async (event: any) => {
		try {
			// if (!event.currentTarget.contains(event.relatedTarget)) {
			const responseColor = await changeColors(user?._id, selectedLocation?._id, { background });
			if (responseColor) {
				const response = await getMyOneLocation(selectedLocation?._id);

				if (response) {
					setSelectedLocation(response.data);
					setSelectedLocationCopy(response.data);
					toastSuccess(t('Changes saved'));
				}
			}
			// }
		} catch (error) {
			console.log(error);
		}
	};

	const saveBackgroundButtons = async (event: any) => {
		try {
			// if (!event.currentTarget.contains(event.relatedTarget)) {
			const responseButtons = await changeColors(user?._id, selectedLocation?._id, {
				backgroundButtons,
			});

			if (responseButtons) {
				const response = await getMyOneLocation(selectedLocation?._id);

				if (response) {
					setSelectedLocation(response.data);
					setSelectedLocationCopy(response.data);
					toastSuccess(t('Changes saved'));
				}
			}
			// }
		} catch (error) {
			console.log(error);
		}
	};
	const saveBackgroundLogo = async (event: any) => {
		try {
			// if (!event.currentTarget.contains(event.relatedTarget)) {
			const responseLogo = await changeColors(user?._id, selectedLocation?._id, { backgroundLogo });
			if (responseLogo) {
				const response = await getMyOneLocation(selectedLocation?._id);

				if (response) {
					console.log(response.data, 'na ovo menjam');
					setSelectedLocation(response.data);
					setSelectedLocationCopy(response.data);
					toastSuccess(t('Changes saved'));
				}
			}
			// }
		} catch (error) {
			console.log(error);
		}
	};
	const saveColorButtons = async (event: any) => {
		try {
			// if (!event.currentTarget.contains(event.relatedTarget)) {
			const responseButtons = await changeColors(user?._id, selectedLocation?._id, { colorButtons });
			if (responseButtons) {
				const response = await getMyOneLocation(selectedLocation?._id);
				if (response) {
					setSelectedLocation(response.data);
					setSelectedLocationCopy(response.data);
					toastSuccess(t('Changes saved'));
				}
			}
			// }
		} catch (error) {
			console.log(error);
		}
	};

	const changeDevice = (value: any, property: string) => {
		setNewDevice((prevState: any) => ({
			...prevState,
			[property]: value,
		}));
	};
	// const updateUserDevice = async () => {
	// 	try {
	// 		if (
	// 			(userDevice._id && userDevice._id?.length > 0 && userDevice._id?.length < 6) ||
	// 			(userDevice._id && userDevice._id?.length > 6)
	// 		) {
	// 			return toastError(t('Device has six characters'));
	// 		}
	// 		const res = await updateDevice(userDevice.name, userDevice._id);
	// 		if (userDevice._id.trim() === '') {
	// 			setUserDevice('');
	// 		}

	// 		const myInfo = await getMyInfo();
	// 		if (myInfo.role.name === 'SuperAdmin') {
	// 			myInfo.isAdmin = true;
	// 		} else {
	// 			myInfo.isAdmin = false;
	// 		}
	// 		setUser(myInfo);
	// 		setUserDevice(myInfo.device);

	// 		toastSuccess(t('Device changed'));
	// 	} catch (error) {
	// 		toastError(t('Cannot change device'));
	// 	}
	// };
	const addDevice = async () => {
		try {
			if (
				(newDevice._id && newDevice._id?.length > 0 && newDevice._id?.length < 6) ||
				(newDevice._id && newDevice._id?.length > 6)
			) {
				return toastError(t('Device has six characters'));
			}

			if (!newDevice._id) {
				return setNewDevice('');
			}
			const res = await addMyDevice(newDevice.name, newDevice._id);

			setNewDevice('');
			const myInfo = await getMyInfo();
			if (myInfo.role.name === 'SuperAdmin') {
				myInfo.isAdmin = true;
			} else {
				myInfo.isAdmin = false;
			}
			setUser(myInfo);
			setUserDevices(myInfo?.device);

			toastSuccess(t('Device changed'));
		} catch (error) {
			toastError(t('Cannot add that device'));
		}
	};
	const removeDevice = async (deviceID: string) => {
		try {
			await removeMyDevice(deviceID);
			setShowRemoveDevicePopup(false);
			const myInfo = await getMyInfo();
			if (myInfo.role.name === 'SuperAdmin') {
				myInfo.isAdmin = true;
			} else {
				myInfo.isAdmin = false;
			}
			setUser(myInfo);
			setUserDevices(myInfo?.device);
		} catch (error) {
			toastError(t('Cannot delete device'));
		}
	};
	const deleteLocation = async () => {
		try {
			setShowDeleteLocationPopup(false);

			await deleteOneLocation(locationForDeletion);
			await getAllLocationInfo();
		} catch (error) {
			toastError(t('Cannot delete location'));
		}
	};
	const activateMyLocation = async (locationID: string) => {
		try {
			await activateLocation(locationID);
			getAllLocationInfo();
		} catch (error) {
			toastError(t('Cannot activate'));
		}
	};
	const saveText = async () => {
		try {
			if (message !== messageCopy) {
				await changeText(user?._id, selectedLocation?._id, { text: message });
				toastSuccess(t('Changed text'));
				getLocationInfo();
			}
		} catch (error) {
			console.log(error);
			toastError(t('Cannot change text'));
		}
	};
	return oneSurveyActive ? (
		<Survey
			selectedLocation={selectedLocation}
			selectedSurvey={selectedSurvey}
			setOneSurveyActive={setOneSurveyActive}
			setSelectedSurvey={setSelectedSurvey}
			setActiveTab={setActiveTab}
		/>
	) : (
		// return loading ? (
		// 	<Loading />
		// ) : (
		<section>
			<CropperComponent
				logoUrl={selectedLogo ? URL.createObjectURL(selectedLogo) : ''}
				imageUrl={selectedImage ? URL.createObjectURL(selectedImage) : ''}
				cropOpened={cropOpened}
				toggleCrop={() => toggleCrop()}
				setCroppedImage={(image: any) => setCroppedImage(image)}
				setCroppedLogo={(image: any) => setCroppedLogo(image)}
				imageType={imageType}
				clientID={user?._id}
				locationID={selectedLocation?._id}
				setLoading={setLoading}
				setSelectedLocation={setSelectedLocation}
				fromAgent={true}
			/>
			<ConfirmPopup
				show={showDeleteLocationPopup}
				onCancel={() => setShowDeleteLocationPopup(false)}
				confirmText={t('Delete')}
				onConfirm={() => {
					deleteLocation();
				}}
			/>
			<ConfirmPopup
				show={showRemoveDevicePopup}
				onCancel={() => setShowRemoveDevicePopup(false)}
				confirmText={t('Remove')}
				onConfirm={() => removeDevice(selectedDevice)}
			/>

			<div className={styles.locationSelect}>
				<div
					onClick={() => {
						setLocationSelectOpened(!locationSelectOpened);
						setNewLocationName('');
					}}
					className={styles.select}>
					<p className={styles.chosenLocation}>{selectedLocation?.name}</p>
					{locationSelectOpened && (
						<div
							ref={locationsSelection}
							onClick={(e) => e.stopPropagation()}
							className={`${styles.selectLocationDiv} ${locationSelectOpened ? styles.active : ''}`}>
							<div className={styles.createNewLocation}>
								<input
									type="text"
									placeholder={t('Location name')}
									value={newLocationName}
									onChange={(e) => setNewLocationName(e.target.value)}
								/>
								<button onClick={() => createNewLocation()}>{t('Add Location')}</button>
							</div>
							{allLocations.length > 0 &&
								allLocations.map((location: any) => {
									return (
										<div
											onClick={() => {
												setLocationSelectOpened(false);
												setSelectedLocationID(location._id);
											}}
											className={styles.oneLocation}
											key={location._id}>
											<div className={styles.locationAndEnabled}>
												<div className={styles.nameHolder}>
													<p>{location.name}</p>
												</div>
												{activeLocation !== location._id && (
													<div className={styles.activateAndDelete}>
														<button
															className={styles.power}
															onClick={() => activateMyLocation(location._id)}>
															<PowerIcon />
														</button>
														<button
															onClick={(e: any) => {
																e.stopPropagation();
																closeLocationSelect();
																setShowDeleteLocationPopup(true);
																setLocationForDeletion(location._id);
															}}>
															<TrashCan />
														</button>
													</div>
												)}
											</div>
										</div>
									);
								})}
						</div>
					)}
				</div>
			</div>
			<div className={styles.header}>
				<div className={styles.tabs}>
					<button
						onClick={() => {
							if (activeTab !== 'editWebsite') {
								getLocationInfo();
							}
							setActiveTab('editWebsite');
						}}
						className={`${styles.tab} ${activeTab === 'editWebsite' ? styles.active : ''} `}>
						{t('Info')}
					</button>
					<button
						onClick={() => setActiveTab('links')}
						className={`${styles.tab} ${activeTab === 'links' ? styles.active : ''}`}>
						{t('Links')}
					</button>
					<button
						onClick={() => setActiveTab('surveys')}
						className={`${styles.tab} ${activeTab === 'surveys' ? styles.active : ''}`}>
						{t('Surveys')}
					</button>
				</div>
			</div>
			{activeTab === 'surveys' && (
				<Surveys
					setOneSurveyActive={setOneSurveyActive}
					setSelectedSurvey={setSelectedSurvey}
					selectedLocation={selectedLocation}
				/>
			)}
			{activeTab === 'editWebsite' && (
				<div className={styles.editWebsiteDiv}>
					<div className={styles.locationContent}>
						<div className={styles.locationCompanyPreview}>
							<div className={styles.locationEdit}>
								<div className={styles.locationLogoEdit}>
									{croppedLogo ? (
										<div className={styles.locationLogoPreview}>
											<img alt="not fount" height="100%" src={croppedLogo} />
											<br />
											{/* <button onClick={() => { removeLogo() }}>Remove</button> */}
										</div>
									) : (
										<div className={styles.locationLogoPreview}>
											<DefaultImage width="100%" height="100%" />
										</div>
									)}

									<button className={styles.locationLogoBtn} onClick={() => logoSelect()}>
										{t('Change Logo')}
									</button>
									<input
										type="file"
										name="myImage"
										accept="image/jpeg, image/png, image/svg, image/jpg"
										ref={inputLogoRef}
										key={selectedLogo}
										hidden
										onClick={onInputClick}
										onChange={(event: any) => {
											console.log(event?.target?.files[0]);
											const fileSize = event?.target?.files[0].size;
											const fileInMB = fileSize / 1024 ** 2;
											if (fileInMB > 10) {
												return toastError(t('File size greater than 10MB'));
											}
											setSelectedLogo(event?.target?.files[0]);
											toggleCrop();
										}}
									/>
								</div>

								<div className={styles.locationImageEdit}>
									{croppedImage ? (
										<div className={styles.locationImagePreview}>
											<img alt="not fount" width="100%" src={croppedImage} />
											<br />
											{/* <button onClick={() => { removeLogo() }}>Remove</button> */}
										</div>
									) : (
										<div className={styles.locationImagePreview}>
											<DefaultImage width="100%" height="100%" />
										</div>
									)}

									<button className={styles.locationImageBtn} onClick={() => imageSelect()}>
										{t('Change Image')}
									</button>
									<input
										type="file"
										name="myImage"
										accept="image/jpeg, image/png, image/svg, image/jpg"
										ref={inputImageRef}
										key={selectedImage}
										hidden
										onClick={onInputClick}
										onChange={(event: any) => {
											console.log(event?.target?.files[0]);
											const fileSize = event?.target?.files[0].size;
											const fileInMB = fileSize / 1024 ** 2;
											if (fileInMB > 10) {
												return toastError(t('File size greater than 10MB'));
											}
											setSelectedImage(event?.target?.files[0]);
											toggleCrop();
										}}
									/>
								</div>
							</div>
							{/* <div className="input-div">
													<input type="text" disabled value={clipboardValue} />
													<div
														className="clipboard-div"
														style={{ width: '30px', paddingTop: '2px' }}>
														<CopyClipboard onClick={() => copyToClipboard()} />
													</div>
												</div> */}
							<div className={styles.colorDiv}>
								<div className={styles.pickers}>
									<div className={styles.pickersTitle}>
										<h3>{t('Edit Colors')}</h3>
									</div>
									{isSupported() && (
										<div className={styles.onePicker}>
											<PopoverPicker
												disabled={!user?.permissions.includes('client.update')}
												color={background}
												onBlur={(event: any) => saveBackground(event)}
												onChange={setBackground}
											/>
											{/* <input type="color" value={background} onBlur={(e) => saveBackground(e.target.value)} onChange={(e) => setBackground(e.target.value)} /> */}
											<p>{t('Background')} </p>
										</div>
									)}
									{!isSupported() && (
										<div className={styles.onePicker}>
											<input
												type="color"
												value={background}
												onBlur={(e) => saveBackground(e.target.value)}
												onChange={(e) => setBackground(e.target.value)}
											/>
											<p>{t('Background')} </p>
										</div>
									)}
									{isSupported() && (
										<div className={styles.onePicker}>
											<PopoverPicker
												disabled={!user?.permissions.includes('client.update')}
												color={backgroundLogo}
												onBlur={(event: any) => saveBackgroundLogo(event)}
												onChange={setBackgroundLogo}
											/>

											{/* <input type="color" value={backgroundLogo} onBlur={(e) => saveBackgroundLogo(e.target.value)} onChange={(e) => setBackgroundLogo(e.target.value)} /> */}
											<p>{t('Background Logo')} </p>
										</div>
									)}
									{!isSupported() && (
										<div className={styles.onePicker}>
											<input
												type="color"
												value={backgroundLogo}
												onBlur={(e) => saveBackgroundLogo(e.target.value)}
												onChange={(e) => setBackgroundLogo(e.target.value)}
											/>
											<p>{t('Background Logo')} </p>
										</div>
									)}
									{isSupported() && (
										<div className={styles.onePicker}>
											<PopoverPicker
												disabled={!user?.permissions.includes('client.update')}
												color={backgroundButtons}
												onBlur={(event: any) => saveBackgroundButtons(event)}
												onChange={setBackgroundButtons}
											/>
											{/* <input type="color" value={backgroundButtons} onBlur={(e) => saveBackgroundButtons(e.target.value)} onChange={(e) => setBackgroundButtons(e.target.value)} /> */}
											<p>{t('Background Buttons')} </p>
										</div>
									)}
									{!isSupported() && (
										<div className={styles.onePicker}>
											<input
												type="color"
												value={backgroundButtons}
												onBlur={(e) => saveBackgroundButtons(e.target.value)}
												onChange={(e) => setBackgroundButtons(e.target.value)}
											/>
											<p>{t('Background Buttons')} </p>
										</div>
									)}

									{isSupported() && (
										<div className={styles.onePicker}>
											<PopoverPicker
												disabled={!user?.permissions.includes('client.update')}
												color={colorButtons}
												onBlur={(event: any) => saveColorButtons(event)}
												onChange={setColorButtons}
											/>
											{/* <input type="color" value={colorButtons} onBlur={(e) => saveColorButtons(e.target.value)} onChange={(e) => setColorButtons(e.target.value)} /> */}
											<p>{t('Color Text')} </p>
										</div>
									)}
									{!isSupported() && (
										<div className={styles.onePicker}>
											<input
												className={styles.colorSelection}
												type="color"
												value={colorButtons}
												onBlur={(e) => saveColorButtons(e.target.value)}
												onChange={(e) => setColorButtons(e.target.value)}
											/>
											<p>{t('Color Text')} </p>
										</div>
									)}
								</div>
							</div>
							<div className={styles.textMsgDiv}>
								<p>{t('Message')}:</p>
								<textarea
									onChange={(e: any) => setMessage(e.target.value)}
									onBlur={() => saveText()}
									value={message}
								/>
							</div>
							{userDevices?.length < 2 && (
								<div className={styles.changeDeviceDiv}>
									<TextField
										size="small"
										placeholder={t('Device ID')}
										sx={{
											backgroundColor: 'white',
										}}
										onChange={(e) => changeDevice(e.target.value, '_id')}
										value={newDevice?._id || ''}
										className={styles.input}></TextField>
									<TextField
										size="small"
										sx={{
											backgroundColor: 'white',
										}}
										placeholder={t('Device Name')}
										onChange={(e) => changeDevice(e.target.value, 'name')}
										value={newDevice?.name || ''}
										className={styles.input}></TextField>
									<button onClick={() => addDevice()} className={styles.changeButton}>
										{t('New Device')}
									</button>
								</div>
							)}
							{userDevices &&
								userDevices.map((device: any, i: any) => {
									return (
										<div key={i} className={styles.changeDeviceDiv}>
											<TextField
												size="small"
												placeholder={t('Device ID')}
												sx={{
													backgroundColor: 'white',
												}}
												disabled
												value={device?._id || ''}
												className={styles.input}></TextField>
											<TextField
												size="small"
												sx={{
													backgroundColor: 'white',
												}}
												placeholder={t('Device Name')}
												disabled
												value={device?.name || ''}
												className={styles.input}></TextField>
											<button
												onClick={() => {
													setSelectedDevice(device._id);
													setShowRemoveDevicePopup(true);
												}}
												className={`${styles.changeButton} ${styles.red}`}>
												{t('Remove')}
											</button>
										</div>
									);
								})}
							{/* <div className={styles.googleTagDiv}>
								<input
									onChange={(e) => {
										setSelectedLocation((prevState: any) => ({
											...prevState,
											googleTag: e.target.value,
										}));
									}}
									value={selectedLocation?.googleTag || ''}
									type="text"
									placeholder={'Google Tag ID'}
								/>
								<button onClick={() => saveGoogleTag()} className={styles.saveBtn}>
									{t('Save Google Tag')}
								</button>
							</div> */}
							{/* <div className='save-div'>
                <button onClick={()=> save()} className='save-btn'>SAVE</button>
            </div> */}
						</div>
						<div className={styles.locationMobile}>
							<div className={styles.mobileDiv}>
								<div className={styles.mobileView}>
									<div className={styles.imageView}>
										{croppedImage ? <img src={croppedImage} alt="" /> : null}
									</div>
									<div className={styles.logoView} style={{ backgroundColor: `${backgroundLogo}` }}>
										{croppedLogo ? <img src={croppedLogo} alt="" /> : null}
									</div>
									<div className={styles.tnxMsgDiv}>
										<p>{message}</p>
									</div>
									<div className={styles.links}>
										{renderLinks(links, backgroundButtons, colorButtons, t, selectedLocation)}
									</div>
									<div
										className={styles.background}
										style={{ backgroundColor: `${background}` }}></div>
								</div>
								<img src={require('assets/iphone-transparent.png')} width="270px" alt="" />
							</div>
						</div>
					</div>
				</div>
			)}
			{activeTab === 'links' && (
				<div className={styles.editLinksDiv}>
					<Links
						setLinks={setLinks}
						clientID={user?._id}
						locationID={selectedLocation?._id}
						getMyOneLocation={getMyOneLocation}
					/>
				</div>
			)}
		</section>
	);
};

export default MyDevice;

const renderLinks = (links: any, backgroundButtons: any, colorButtons: any, t: any, location: any) => {
	return (
		<>
			{links?.map((link: any, i: any) => {
				if (link.name === 'download app' && (link.links.appStore || link.links.playStore))
					return (
						<div key={i}>
							{link.links ? (
								<div
									style={{
										backgroundColor: backgroundButtons,
									}}
									className={styles.link}>
									<p
										style={{
											color: colorButtons,
										}}>
										{location?.pageSetup?.downloadAppText.toUpperCase()}
									</p>
								</div>
							) : null}
						</div>
					);
				if (link.name === 'vcard') {
					return (
						<div key={i}>
							{link.link ? (
								<div
									style={{
										backgroundColor: backgroundButtons,
									}}
									className={styles.link}>
									<p
										style={{
											color: colorButtons,
										}}>
										{location?.pageSetup?.vcard?.text.toUpperCase()}
									</p>
								</div>
							) : null}
						</div>
					);
				}

				return (
					<div key={i}>
						{link.link ? (
							<div
								style={{
									backgroundColor: backgroundButtons,
								}}
								className={styles.link}>
								<p
									style={{
										color: colorButtons,
									}}>
									{link.name.toUpperCase()}
								</p>
							</div>
						) : null}
					</div>
				);
			})}
		</>
	);
};

type LinksComponentProps = {
	clientID: any;
	locationID: any;
	setLinks: any;
	getMyOneLocation: any;
};
const Links = ({ clientID, locationID, setLinks, getMyOneLocation }: LinksComponentProps) => {
	const [googleLink, setGoogleLink] = useState('');
	const [iosLink, setIosLink] = useState<any>({});
	const [playStoreLink, setPlayStoreLink] = useState<any>({});

	const [isMapOpen, setIsMapOpen] = useState(false);
	const navigate = useNavigate();

	const [draggedId, setDraggedId] = useState<any>(null);
	const [draggedIndex, setDraggedIndex] = useState<any>(null);
	const [user, setUser] = useRecoilState(userState);

	const [allLinks, setAllLinks] = useState<any>([]);
	const [allLinksCopy, setAllLinksCopy] = useState<any>([]);
	const [newLinkName, setNewLinkName] = useState('');
	const [newLinkNameErr, setNewLinkNameErr] = useState(false);
	const [newLink, setNewLink] = useState('');
	const [newLinkErr, setNewLinkErr] = useState(false);
	const validateGoogle = /^(https:\/\/)(www\.)?\bsearch.google.com\b/;
	const validateFacebook = /^(https:\/\/)(www\.)?\bfacebook.com\b/;
	const validateTikTok = /^(https:\/\/)(www\.)?\btiktok.com\b/;
	const validateYelp = /^(https:\/\/)(www\.)?\byelp.com\b/;
	const validateLinkedin = /^(https:\/\/)(www\.)?\blinkedin.com\/company\b/;
	const validateTwitter = /^(https:\/\/)(www\.)?\btwitter.com\b/;
	const validateTrustpilot = /^(https:\/\/)(www\.)?\btrustpilot.com\b/;
	const validateInstagram = /^(https:\/\/)(www\.)?\binstagram.com\b/;
	const validateTripadvisor = /^(https:\/\/)(www\.)?\btripadvisor.com\b/;
	const validateAppStore = /^(https:\/\/)(www\.)?\bapps.apple.com\b/;
	const validatePlayStore = /^(https:\/\/)(www\.)?\bplay.google.com\/store\/apps\/\b/;
	const { t } = useTranslation('translation');
	const [editableLink, setEditableLink] = useState('');
	const [linkName, setLinkName] = useState('');
	const [location, setLocation] = useState<any>({});
	const [deleteLinkID, setDeleteLinkID] = useState('');
	const [preselectedLinkName, setPreselectedLinkName] = useState('');
	const [showDeleteLinkPopup, setShowDeleteLinkPopup] = useState(false);
	const validateWebsite =
		/^(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
	const validatePhone = /^(\+?)([0-9 ]+)$/;
	const [cardLonger, setCardLonger] = useState(false);

	const [vcardText, setVCardText] = useState('');
	const [vcardFirstName, setVCardFirstName] = useState('');
	const [vcardLastName, setVCardLastName] = useState('');
	const [vcardEnabled, setVCardEnabled] = useState(false);
	const [vcardCompanyName, setVCardCompanyName] = useState('');
	const [vcardPhone, setVCardPhone] = useState('');
	const [vcardEmail, setVCardEmail] = useState('');
	const [vcardWebsite, setVCardWebsite] = useState('');
	const [vcardStreet, setVCardStreet] = useState('');
	const [vcardCity, setVCardCity] = useState('');
	const [vcardState, setVCardState] = useState('');
	const [vcardCountry, setVCardCountry] = useState('');
	const [vcardZIP, setVCardZIP] = useState('');
	const [vcardFacebook, setVCardFacebook] = useState('');
	const [vcardInstagram, setVCardInstagram] = useState('');
	const [vcardLinkedin, setVCardLinkedin] = useState('');
	const [vcardYelp, setVCardYelp] = useState('');
	const [vcardTwitter, setVCardTwitter] = useState('');

	const [instaHasError, setInstaHasError] = useState(false);
	const [faceHasError, setFaceHasError] = useState(false);
	const [yelpHasError, setYelpHasError] = useState(false);
	const [webHasError, setWebHasError] = useState(false);
	const [twittHasError, setTwittHasError] = useState(false);
	const [linkedinHasError, setLinkedinHasError] = useState(false);
	const [phoneHasError, setPhoneHasError] = useState(false);
	const getAllLinks = async () => {
		try {
			let data = await getLinks(clientID, locationID);
			data?.forEach((link: any) => {
				if (link.name === 'google') {
					setGoogleLink(link.link);
				}
				if (link.name === 'ios') {
					setIosLink(link);
				}
				if (link.name === 'android') {
					setPlayStoreLink(link);
					// let androidLink = data.find(
					//   (e: any) => e.name === "download app"
					// );
					// console.log(androidLink);
					// androidLink.links.playStore = playStoreLink;
				}
			});

			// data.splice(iosLink?.sort, 0, {
			//   client: iosLink?.client,
			//   name: "download app",
			//   sort: iosLink?.sort,
			//   links: { appStore: iosLink?.link, playStore: "" },
			//   _id: iosLink?._id,
			// });

			if (!data.some((e: any) => e.name == 'download app')) {
				const iosLink = data.find((e: any) => e.name == 'ios');
				data?.splice(iosLink?.sort, 0, {
					client: iosLink?.client,
					name: 'download app',
					sort: iosLink?.sort,
					links: { appStore: iosLink?.link, playStore: playStoreLink?.link },
					_id: iosLink?._id,
				});
			}

			data = data.filter((e: any) => e.name !== 'ios');
			data = data.filter((e: any) => e.name !== 'android');

			setAllLinks(data);
			setAllLinksCopy(data);
			setLinks(data);
		} catch (error) {
			toastError(t('Cannot get links'));
		}
	};
	const getLocation = async () => {
		try {
			const locationData = await getMyOneLocation(locationID);
			if (locationData) {
				setLocation(locationData?.data);
				setVCardText(locationData?.data?.pageSetup?.vcard?.text);
				setVCardFirstName(locationData?.data?.pageSetup?.vcard?.firstName);
				setVCardLastName(locationData?.data?.pageSetup?.vcard?.lastName);
				setVCardEnabled(locationData?.data?.pageSetup?.vcard?.enabled);
				setVCardCompanyName(locationData?.data?.pageSetup?.vcard?.companyName);
				setVCardPhone(locationData?.data?.pageSetup?.vcard?.phone);
				setVCardEmail(locationData?.data?.pageSetup?.vcard?.email);
				setVCardWebsite(locationData?.data?.pageSetup?.vcard?.website);
				setVCardStreet(locationData?.data?.pageSetup?.vcard?.street);
				setVCardCity(locationData?.data?.pageSetup?.vcard?.city);
				setVCardState(locationData?.data?.pageSetup?.vcard?.state);
				setVCardCountry(locationData?.data?.pageSetup?.vcard?.country);
				setVCardZIP(locationData?.data?.pageSetup?.vcard?.zip);
				setVCardFacebook(
					locationData?.data?.pageSetup?.vcard?.socials?.find((e: any) => e?.name === 'facebook')?.link,
				);
				setVCardInstagram(
					locationData?.data?.pageSetup?.vcard?.socials?.find((e: any) => e?.name === 'instagram')?.link,
				);
				setVCardLinkedin(
					locationData?.data?.pageSetup?.vcard?.socials?.find((e: any) => e?.name === 'linkedin')?.link,
				);
				setVCardYelp(locationData?.data?.pageSetup?.vcard?.socials?.find((e: any) => e?.name === 'yelp')?.link);
				setVCardTwitter(
					locationData?.data?.pageSetup?.vcard?.socials?.find((e: any) => e?.name === 'twitter')?.link,
				);
			}
		} catch (error) {
			toastError(t('Cannot get location'));
		}
	};
	useEffect(() => {
		setInstaHasError(false);
		setFaceHasError(false);
		setYelpHasError(false);
		setWebHasError(false);
		setLinkedinHasError(false);
		setTwittHasError(false);
		setEditableLink('');
		getAllLinks();
		getLocation();
	}, [locationID]);
	// useEffect(() => {
	// 	if (iosLink != {} && allLinks && playStoreLink != {}) {
	// 		if (!allLinks.some((e: any) => e.name == 'download app')) {
	// 			console.log(iosLink);
	// 			allLinks?.splice(iosLink?.sort, 0, {
	// 				client: iosLink?.client,
	// 				name: 'download app',
	// 				sort: iosLink?.sort,
	// 				links: { appStore: iosLink?.link, playStore: playStoreLink?.link },
	// 				_id: iosLink?._id,
	// 			});
	// 		}
	// 	}
	// }, [iosLink, allLinks, playStoreLink]);

	const toggleMap = () => {
		setIsMapOpen(!isMapOpen);
	};

	const withPrevState = (link: any, value: any, type?: string) => {
		let rightLink: any;
		if (allLinks) rightLink = allLinks.find((o: any) => o._id == link._id);

		if (type == 'iosLink') {
			setIosLink((prevState: any) => ({
				...prevState,
				link: value,
			}));

			return;
		}
		if (type == 'android') {
			setPlayStoreLink((prevState: any) => ({
				...prevState,
				link: value,
			}));
			return;
		}

		setAllLinks((prevState: any) => {
			const allLinks = prevState;

			const newList = allLinks?.map((link: any) => (link._id == rightLink._id ? { ...link, link: value } : link));

			return newList;
		});
	};
	// const onDragEnd = async (id: string, index: number) => {
	// 	try {
	// 		await changeLinkOrder(clientID, locationID, { id, index });
	// 		await getAllLinks();
	// 	} catch (error) {
	// 		toastError(t('Cannot change order'));
	// 	}
	// };

	const reorder = (list: any, startIndex: any, endIndex: any) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};
	const grid = 8;
	const getListStyle = (isDraggingOver: any) => ({
		// background: isDraggingOver ? "lightblue" : "lightgrey",
		// paddingLeft: grid,

		width: '100%',
		height: 'fit-content',
	});
	const getItemStyle = (isDragging: any, draggableStyle: any, currentLinkName: any) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: 'none',
		// padding: grid * 2,
		// margin: `0 0 ${grid}px 0`,

		// change background colour if dragging
		position: 'relative',
		width: '100%',
		// marginLeft: '10px',
		marginTop: '7px',
		height:
			currentLinkName === 'download app' || currentLinkName === 'vcard'
				? currentLinkName === 'download app'
					? '180px'
					: cardLonger
					? '335px'
					: '245px'
				: '140px',
		// float: 'left',
		// paddingTop: '15px',
		boxShadow: 'rgba(0,0,0, 0.179) 0px 10px 10px -10px',

		borderRadius: '10px',
		marginBottom: '15px',
		backgroundColor: 'white',
		p: {
			color: 'blue',
		},

		// styles we need to apply on draggables
		...draggableStyle,
	});
	const onDragEnd = async (result: any) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}
		const items = reorder(allLinks, result.source.index, result.destination.index);
		setAllLinks(items);
		setAllLinksCopy(items);
		await changeLinkOrder(clientID, locationID, result);
		await getAllLinks();
	};
	const saveLink = async (name: string, link: string, prevLink: string, download?: string) => {
		let linkName = name.toLowerCase();
		let validLink = false;
		let same = false;

		if (linkName == 'facebook') {
			validLink = validateFacebook.test(link);
		} else if (linkName == 'google') {
			validLink = validateGoogle.test(link);
		} else if (linkName == 'instagram') {
			validLink = validateInstagram.test(link);
		} else if (linkName == 'tiktok') {
			validLink = validateTikTok.test(link);
		} else if (linkName == 'yelp') {
			validLink = validateYelp.test(link);
		} else if (linkName == 'linkedin') {
			validLink = validateLinkedin.test(link);
		} else if (linkName == 'twitter') {
			validLink = validateTwitter.test(link);
		} else if (linkName == 'trustpilot') {
			validLink = validateTrustpilot.test(link);
		} else if (linkName == 'tripadvisor') {
			validLink = validateTripadvisor.test(link);
		} else if (linkName == 'website') {
			validLink = validateWebsite.test(link);
		} else if (linkName == 'download app') {
			if (download == 'ios') {
				validLink = validateAppStore.test(link);
				linkName = 'ios';
			} else {
				validLink = validatePlayStore.test(link);
				linkName = 'android';
			}
		} else {
			validLink = true;
		}

		if (link.trim() === '') {
			validLink = true;
		}

		if (link == prevLink) same = true;

		if (link.trim() == '' && prevLink == null) same = true;

		if (!validLink) {
			return toastError(t('Invalid link'));
		}
		if (same) {
			return;
		}

		try {
			const resp = await postLink(clientID, locationID, { name: linkName, link });
			if (resp) {
				const resp = await getLinks(clientID, locationID);
				resp.forEach((link: any) => {
					if (link.name === 'google') {
						setGoogleLink(link.link);
					}
				});

				toastSuccess(t('Link changed'));
				getAllLinks();
				// setAllLinks(resp);
				// setAllLinksCopy(resp);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const addNewLink = async (name: any, link: any) => {
		let nameGood = true;
		let linkGood = true;
		let isValid;
		const validateLink =
			/^(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
		if (!name && name.trim() == '') {
			setNewLinkNameErr(true);
			nameGood = false;
		}

		name = name.toLowerCase();

		if (name == 'tiktok') {
			isValid = validateTikTok.test(link);
		} else if (name == 'yelp') {
			isValid = validateYelp.test(link);
		} else if (name == 'linkedin') {
			isValid = validateLinkedin.test(link);
		} else if (name == 'twitter') {
			isValid = validateTwitter.test(link);
		} else if (name == 'trustpilot') {
			isValid = validateTrustpilot.test(link);
		} else {
			isValid = validateLink.test(link);
		}
		console.log(isValid);
		if (!link || link.trim() == '' || !isValid) {
			console.log(link);
			setNewLinkErr(true);
			linkGood = false;
		}
		const nameAlreadyExists = allLinks?.some((e: any) => e.name.toLowerCase().trim() == name.toLowerCase().trim());

		if (nameAlreadyExists) {
			setNewLinkNameErr(true);
			nameGood = false;
			toastError(t('Link  already exists'));
		}
		if (nameGood && linkGood) {
			try {
				const response = await postLink(clientID, locationID, { name, link });
				if (response) {
					setAllLinks((oldLinks: any) => [...oldLinks, response]);
					setAllLinksCopy((oldLinks: any) => [...oldLinks, response]);
					getAllLinks();
					setNewLinkName('');
					setNewLink('');
					setPreselectedLinkName('');
				}
			} catch (error) {
				console.log(error);
			}
		}
	};
	const preselectLinkName = (title: string) => {
		setNewLinkName(title);
		setNewLinkNameErr(false);
	};
	const defaultLinkNames = [
		'facebook',
		'google',
		'instagram',
		'website',
		'tripadvisor',
		'trustpilot',
		'tiktok',
		'yelp',
		'linkedin',
		'twitter',
		'yandex',
		'baidu',
		'about us',
		'catalog',
		'check the menu',
	];
	const saveDownlaodAppText = async (name: string) => {
		try {
			await updateDownloadAppText(clientID, locationID, name);
			getAllLinks();
			getLocation();
		} catch (error) {
			toastError(t('Cannot change text'));
		}
	};
	const saveLinkName = async (linkID: string, name: string) => {
		try {
			await updateLinkName(clientID, location._id, linkID, name);
			getAllLinks();
		} catch (error) {
			toastError(t('Cannot change text'));
		}
	};
	const deleteLink = async (linkID: string) => {
		try {
			await deleteOneLink(clientID, location._id, linkID);
			getAllLinks();
		} catch (error) {
			toastError(t('Cannot delete link'));
		}
	};
	const changeVCard = async (vcard: any) => {
		try {
			const updatedLocation = await updateVCard(clientID, location._id, vcard);
			setLocation(updatedLocation.data);
			getAllLinks();
		} catch (error) {
			toastError(t('Cannot change vCard'));
		}
	};
	const changeVCardVisibility = async (enabled: any) => {
		try {
			const updatedLocation = await updateVCard(clientID, location._id, { enabled });
			setLocation(updatedLocation.data);
			getAllLinks();
		} catch (error) {
			toastError(t('Cannot change vCard'));
		}
	};
	return (
		<div className={styles.dragContainer}>
			{allLinks ? (
				<>
					<ConfirmPopup
						confirmText={t('Delete')}
						show={showDeleteLinkPopup}
						onCancel={() => setShowDeleteLinkPopup(false)}
						onConfirm={() => {
							setShowDeleteLinkPopup(false);
							deleteLink(deleteLinkID);
						}}
					/>
					<Map isOpen={isMapOpen} toggleMap={toggleMap} saveLink={saveLink} setGoogleLink={setGoogleLink} />
					{user?.permissions.includes('client.update') && (
						<>
							{/* <ReactSortable
								list={allLinks}
								setList={async (newState) => {
									if (JSON.stringify(newState) !== JSON.stringify(allLinks)) {
										setAllLinks(newState);
										await onDragEnd(draggedId, draggedIndex);
									}
								}}
								onChange={async (store) => {
									setDraggedId(store.item.id);
									setDraggedIndex(store.newIndex);
									// await onDragEnd(store.item.id, store.newIndex as number);
								}}>
								{allLinks?.map((item: any) => {
									if (item.name === 'google') {
										return (
											<div
												className={`${styles.draggableItem} ${styles.googleDiv}`}
												id={item._id}
												key={item._id}>
												<div className={styles.header}>
													<p className={styles.title}>{item.name.toUpperCase()}</p>
													<div className={styles.dragAndMap}>
														<button
															onClick={() => toggleMap()}
															className={styles.mapButton}>
															{t('Find Place').toUpperCase()}
														</button>
														<div className={styles.dragDiv}>
															<BurgerMenu
																fill="black"
																height="100%"
																width="100%"
																stroke="#262626"
															/>
														</div>
													</div>
												</div>
												<input
													value={googleLink || ''}
													className={styles.linkValue}
													disabled
													placeholder="URL"
													type="text"
												/>
											</div>
										);
									}
									if (item.links) {
										return (
											<div
												className={`${styles.draggableItem} ${styles.bigger}`}
												id={item._id}
												key={item._id}>
												<div className={styles.header}>
													{editableLink === item._id && (
														<input
															disabled={editableLink !== item?._id}
															readOnly={editableLink !== item?._id}
															value={linkName}
															onChange={(e) => {
																setLinkName(e.target.value);
															}}
															className={`${styles.title} ${
																editableLink === item._id ? styles.editable : ''
															}`}></input>
													)}
													{editableLink !== item._id && (
														<p className={styles.title}>
															{location?.pageSetup?.downloadAppText?.toUpperCase() || ''}
														</p>
													)}
													<div className={styles.buttons}>
														<div
															className={styles.editDiv}
															onClick={() => {
																if (editableLink === item._id) {
																	setEditableLink('');
																	saveDownlaodAppText(linkName);
																	return;
																}
																setLinkName(location?.pageSetup?.downloadAppText);
																setEditableLink(item._id);
																setAllLinks(allLinksCopy);
															}}>
															{editableLink === item._id ? (
																<CheckCircleOutline
																	width="21px"
																	height="100%"
																	fill="#4ca084"
																/>
															) : (
																<EditButton height="100%" width="21px" />
															)}
														</div>

														<div className={styles.dragDiv}>
															<BurgerMenu
																fill="black"
																height="100%"
																width="100%"
																stroke="#000"
															/>
														</div>
													</div>
												</div>
												<input
													className={styles.linkValue}
													value={iosLink?.link || ''}
													onChange={(e) => withPrevState(item, e.target.value, 'iosLink')}
													onBlur={(e) =>
														saveLink(
															item.name,
															e.target.value,
															allLinksCopy?.find((e: any) => e.name === 'download app')
																?.links?.appStore,
															'ios',
														)
													}
													placeholder="APP STORE URL"
													type="text"
												/>
												<input
													className={styles.linkValue}
													onChange={(e) => withPrevState(item, e.target.value, 'android')}
													onBlur={(e) =>
														saveLink(
															item.name,
															e.target.value,
															allLinksCopy?.find((e: any) => e.name === 'download app')
																?.links?.playStore,
															'android',
														)
													}
													value={playStoreLink?.link || ''}
													placeholder="PLAY STORE URL"
													type="text"
												/>
											</div>
										);
									}
									return (
										<div className={styles.draggableItem} id={item._id} key={item._id}>
											<div className={styles.header}>
												{editableLink === item._id && (
													<input
														disabled={editableLink !== item._id}
														readOnly={editableLink !== item._id}
														value={linkName}
														onChange={(e) => {
															setLinkName(e.target.value);
														}}
														className={`${styles.title} ${
															editableLink === item._id ? styles.editable : ''
														}`}></input>
												)}
												{editableLink !== item._id && (
													<p className={styles.title}>{item?.name?.toUpperCase() || ''}</p>
												)}
												<div className={styles.buttons}>
													<div
														className={styles.editDiv}
														hidden={defaultLinkNames.includes(item.name)}
														onClick={() => {
															if (editableLink === item._id) {
																setEditableLink('');
																saveLinkName(item._id, linkName);
																return;
															}
															setLinkName(item?.name);
															setEditableLink(item._id);
															setAllLinks(allLinksCopy);
														}}>
														{editableLink === item._id ? (
															<CheckCircleOutline
																width="21px"
																height="100%"
																fill="#4ca084"
															/>
														) : (
															<EditButton height="100%" width="21px" />
														)}
													</div>
													<div
														className={styles.deleteDiv}
														hidden={defaultLinkNames.includes(item.name)}
														onClick={() => {
															setDeleteLinkID(item._id);
															setShowDeleteLinkPopup(true);
														}}>
														<TrashCanButton />
													</div>

													<div className={styles.dragDiv}>
														<BurgerMenu
															fill="black"
															height="100%"
															width="100%"
															stroke="#000"
														/>
													</div>
												</div>
											</div>

											<input
												className={styles.linkValue}
												value={item.link || ''}
												onChange={(e) => withPrevState(item, e.target.value)}
												onBlur={(e) =>
													saveLink(
														item.name,
														e.target.value,
														allLinksCopy?.find((e: any) => e.name === item.name).link,
													)
												}
												placeholder="URL"
												type="text"
											/>
										</div>
									);
								})}
							</ReactSortable> */}
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="droppable">
									{(provided, snapshot) => (
										<div
											{...provided.droppableProps}
											ref={provided.innerRef}
											style={getListStyle(snapshot.isDraggingOver)}>
											{allLinks
												? allLinks.map((link: any, index: any) => {
														const currentLink = allLinks.find(
															(o: any) => o._id == link._id,
														);
														let currentLinkValue: any;

														// console.log('TEST', allLinks, allLinksCopy)
														if (allLinksCopy)
															currentLinkValue = allLinksCopy.find(
																(o: any) => o._id == link?._id,
															)?.link;
														if (link.name == 'ios' || link.name == 'android') {
															return null;
														}
														if (link.name == 'google') {
															return (
																<Draggable
																	key={link?._id}
																	draggableId={link?._id}
																	index={index}>
																	{(provided, snapshot) => (
																		<div
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...link}
																			style={getItemStyle(
																				snapshot.isDragging,
																				provided.draggableProps.style,
																				link.name,
																			)}>
																			<div className={styles.item} key={link._id}>
																				<div className={styles.googleDiv}>
																					<p>{link.name.toUpperCase()}</p>
																					<div className={styles.placeNdrag}>
																						<button
																							onClick={() => toggleMap()}>
																							{t(
																								'Find Place',
																							).toUpperCase()}
																						</button>
																						<div
																							{...provided.dragHandleProps}
																							className={styles.dragDiv}>
																							<BurgerMenu
																								fill="black"
																								height="100%"
																								width="100%"
																								stroke="#000"
																							/>
																						</div>
																					</div>
																				</div>
																				<div className={styles.inputDiv}>
																					<input
																						disabled
																						value={googleLink || ''}
																						type="text"
																						placeholder="URL"
																					/>
																				</div>
																			</div>
																		</div>
																	)}
																</Draggable>
															);
														}
														if (link.name === 'survey') {
															return (
																<Draggable
																	key={link?._id}
																	draggableId={link?._id}
																	index={index}>
																	{(provided, snapshot) => (
																		<div
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			style={getItemStyle(
																				snapshot.isDragging,
																				provided.draggableProps.style,
																				link.name,
																			)}>
																			<div className={styles.item} key={link._id}>
																				<div
																					className={`${styles.header} ${
																						editableLink === link._id
																							? styles.hasInput
																							: ''
																					}`}>
																					{editableLink === link._id && (
																						<input
																							disabled={
																								editableLink !==
																								link._id
																							}
																							readOnly={
																								editableLink !==
																								link._id
																							}
																							value={linkName}
																							onChange={(e) => {
																								setLinkName(
																									e.target.value,
																								);
																							}}
																							className={`${
																								styles.title
																							} ${
																								editableLink ===
																								link._id
																									? styles.editable
																									: ''
																							}`}></input>
																					)}
																					{editableLink !== link._id && (
																						<p className={styles.title}>
																							{link?.name?.toUpperCase() ||
																								''}
																						</p>
																					)}
																					<div className={styles.buttons}>
																						{/* <div
																							className={styles.editDiv}
																							hidden={defaultLinkNames.includes(
																								link.name,
																							)}
																							onClick={() => {
																								if (
																									editableLink ===
																									link._id
																								) {
																									setEditableLink('');
																									saveLinkName(
																										link._id,
																										linkName,
																									);
																									return;
																								}
																								setInstaHasError(false);
																								setFaceHasError(false);
																								setYelpHasError(false);
																								setWebHasError(false);
																								setLinkedinHasError(
																									false,
																								);
																								setPhoneHasError(false);
																								setTwittHasError(false);
																								setVCardText(
																									location?.pageSetup
																										?.vcard?.text,
																								);
																								setVCardFirstName(
																									location?.pageSetup
																										?.vcard
																										?.firstName,
																								);
																								setVCardLastName(
																									location?.pageSetup
																										?.vcard
																										?.lastName,
																								);
																								setVCardEnabled(
																									location?.pageSetup
																										?.vcard
																										?.enabled,
																								);
																								setVCardCompanyName(
																									location?.pageSetup
																										?.vcard
																										?.companyName,
																								);
																								setVCardPhone(
																									location?.pageSetup
																										?.vcard?.phone,
																								);
																								setVCardEmail(
																									location?.pageSetup
																										?.vcard?.email,
																								);
																								setVCardWebsite(
																									location?.pageSetup
																										?.vcard
																										?.website,
																								);
																								setVCardStreet(
																									location?.pageSetup
																										?.vcard?.street,
																								);
																								setVCardCity(
																									location?.pageSetup
																										?.vcard?.city,
																								);
																								setVCardStreet(
																									location?.pageSetup
																										?.vcard?.street,
																								);
																								setVCardState(
																									location?.pageSetup
																										?.vcard?.state,
																								);
																								setVCardCountry(
																									location?.pageSetup
																										?.vcard
																										?.country,
																								);
																								setVCardZIP(
																									location?.pageSetup
																										?.vcard?.zip,
																								);
																								setVCardFacebook(
																									location?.pageSetup?.vcard?.socials?.find(
																										(e: any) =>
																											e?.name ===
																											'facebook',
																									)?.link,
																								);
																								setVCardInstagram(
																									location?.pageSetup?.vcard?.socials?.find(
																										(e: any) =>
																											e?.name ===
																											'instagram',
																									)?.link,
																								);
																								setVCardLinkedin(
																									location?.pageSetup?.vcard?.socials?.find(
																										(e: any) =>
																											e?.name ===
																											'linkedin',
																									)?.link,
																								);
																								setVCardYelp(
																									location?.pageSetup?.vcard?.socials?.find(
																										(e: any) =>
																											e?.name ===
																											'yelp',
																									)?.link,
																								);
																								setVCardTwitter(
																									location?.pageSetup?.vcard?.socials?.find(
																										(e: any) =>
																											e?.name ===
																											'twitter',
																									)?.link,
																								);
																								setLinkName(link?.name);
																								setEditableLink(
																									link._id,
																								);
																								setAllLinks(
																									allLinksCopy,
																								);
																							}}>
																							{editableLink ===
																							link._id ? (
																								<CheckCircleOutline
																									width="21px"
																									height="100%"
																									fill="#4ca084"
																								/>
																							) : (
																								<EditButton
																									height="100%"
																									width="21px"
																								/>
																							)}
																						</div> */}
																						{/* <div
																							className={styles.deleteDiv}
																							hidden={defaultLinkNames.includes(
																								link.name,
																							)}
																							onClick={() => {
																								setDeleteLinkID(
																									link._id,
																								);
																								setShowDeleteLinkPopup(
																									true,
																								);
																							}}>
																							<TrashCanButton
																								height={23}
																								width={23}
																							/>
																						</div> */}
																						<div
																							{...provided.dragHandleProps}
																							className={styles.dragIcon}>
																							<BurgerMenu
																								fill="black"
																								height="100%"
																								width="100%"
																								stroke="#000"
																							/>
																						</div>
																					</div>
																				</div>

																				<input
																					type="text"
																					value={currentLink.link || ''}
																					placeholder="URL"
																					disabled={true}
																					onChange={(e) =>
																						withPrevState(
																							link,
																							e.target.value,
																						)
																					}
																					onBlur={(e) =>
																						saveLink(
																							link.name,
																							e.target.value,
																							currentLinkValue,
																						)
																					}
																				/>
																			</div>
																		</div>
																	)}
																</Draggable>
															);
														}
														if (link.links) {
															return (
																<Draggable
																	key={link?._id}
																	draggableId={link?._id}
																	index={index}>
																	{(provided, snapshot) => (
																		<div
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			style={getItemStyle(
																				snapshot.isDragging,
																				provided.draggableProps.style,
																				link.name,
																			)}>
																			<div className={styles.item} key={link._id}>
																				<div
																					className={`${styles.header} ${
																						editableLink === link._id
																							? styles.hasInput
																							: ''
																					}`}>
																					{editableLink === link._id && (
																						<input
																							disabled={
																								editableLink !==
																								link._id
																							}
																							readOnly={
																								editableLink !==
																								link._id
																							}
																							value={linkName}
																							onChange={(e) => {
																								setLinkName(
																									e.target.value,
																								);
																							}}
																							className={`${
																								styles.title
																							} ${
																								editableLink ===
																								link._id
																									? styles.editable
																									: ''
																							}`}></input>
																					)}
																					{editableLink !== link._id && (
																						<p className={styles.title}>
																							{location?.pageSetup?.downloadAppText?.toUpperCase() ||
																								''}
																						</p>
																					)}

																					<div className={styles.buttons}>
																						<div
																							className={styles.editDiv}
																							hidden={defaultLinkNames.includes(
																								link.name,
																							)}
																							onClick={() => {
																								if (
																									editableLink ===
																									link._id
																								) {
																									setEditableLink('');
																									saveDownlaodAppText(
																										linkName,
																									);

																									return;
																								}
																								setInstaHasError(false);
																								setFaceHasError(false);
																								setYelpHasError(false);
																								setWebHasError(false);
																								setLinkedinHasError(
																									false,
																								);
																								setPhoneHasError(false);
																								setTwittHasError(false);
																								setVCardText(
																									location?.pageSetup
																										?.vcard?.text,
																								);
																								setVCardFirstName(
																									location?.pageSetup
																										?.vcard
																										?.firstName,
																								);
																								setVCardLastName(
																									location?.pageSetup
																										?.vcard
																										?.lastName,
																								);
																								setVCardEnabled(
																									location?.pageSetup
																										?.vcard
																										?.enabled,
																								);
																								setVCardCompanyName(
																									location?.pageSetup
																										?.vcard
																										?.companyName,
																								);
																								setVCardPhone(
																									location?.pageSetup
																										?.vcard?.phone,
																								);
																								setVCardEmail(
																									location?.pageSetup
																										?.vcard?.email,
																								);
																								setVCardWebsite(
																									location?.pageSetup
																										?.vcard
																										?.website,
																								);
																								setVCardStreet(
																									location?.pageSetup
																										?.vcard?.street,
																								);
																								setVCardCity(
																									location?.pageSetup
																										?.vcard?.city,
																								);
																								setVCardState(
																									location?.pageSetup
																										?.vcard?.state,
																								);
																								setVCardCountry(
																									location?.pageSetup
																										?.vcard
																										?.country,
																								);
																								setVCardZIP(
																									location?.pageSetup
																										?.vcard?.zip,
																								);
																								setVCardFacebook(
																									location?.pageSetup?.vcard?.socials?.find(
																										(e: any) =>
																											e?.name ===
																											'facebook',
																									)?.link,
																								);
																								setVCardInstagram(
																									location?.pageSetup?.vcard?.socials?.find(
																										(e: any) =>
																											e?.name ===
																											'instagram',
																									)?.link,
																								);
																								setVCardLinkedin(
																									location?.pageSetup?.vcard?.socials?.find(
																										(e: any) =>
																											e?.name ===
																											'linkedin',
																									)?.link,
																								);
																								setVCardYelp(
																									location?.pageSetup?.vcard?.socials?.find(
																										(e: any) =>
																											e?.name ===
																											'yelp',
																									)?.link,
																								);
																								setVCardTwitter(
																									location?.pageSetup?.vcard?.socials?.find(
																										(e: any) =>
																											e?.name ===
																											'twitter',
																									)?.link,
																								);
																								setLinkName(
																									location?.pageSetup
																										?.downloadAppText,
																								);
																								setEditableLink(
																									link._id,
																								);
																								setAllLinks(
																									allLinksCopy,
																								);
																							}}>
																							{editableLink ===
																							link._id ? (
																								<CheckCircleOutline
																									width="21px"
																									height="100%"
																									fill="#4ca084"
																								/>
																							) : (
																								<EditButton
																									height="100%"
																									width="21px"
																								/>
																							)}
																						</div>
																						<div
																							{...provided.dragHandleProps}
																							className={styles.dragIcon}>
																							<BurgerMenu
																								fill="black"
																								height="100%"
																								width="100%"
																								stroke="#000"
																							/>
																						</div>
																					</div>
																				</div>

																				<input
																					type="text"
																					value={iosLink?.link || ''}
																					placeholder="App store URL"
																					onChange={(e) =>
																						withPrevState(
																							link,
																							e.target.value,
																							'iosLink',
																						)
																					}
																					onBlur={(e) =>
																						saveLink(
																							link.name,
																							e.target.value,
																							allLinksCopy?.find(
																								(e: any) =>
																									e.name ===
																									'download app',
																							)?.links?.appStore,
																							'ios',
																						)
																					}
																				/>
																				<input
																					type="text"
																					value={playStoreLink?.link || ''}
																					placeholder="Play store URL"
																					onChange={(e) =>
																						withPrevState(
																							link,
																							e.target.value,
																							'android',
																						)
																					}
																					onBlur={(e) =>
																						saveLink(
																							link.name,
																							e.target.value,
																							allLinksCopy?.find(
																								(e: any) =>
																									e.name ===
																									'download app',
																							)?.links?.playStore,
																							'android',
																						)
																					}
																				/>
																			</div>
																		</div>
																	)}
																</Draggable>
															);
														}
														if (link.name == 'vcard') {
															return (
																<Draggable
																	key={link?._id}
																	draggableId={link?._id}
																	index={index}>
																	{(provided, snapshot) => (
																		<div
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...link}
																			style={getItemStyle(
																				snapshot.isDragging,
																				provided.draggableProps.style,
																				link.name,
																			)}>
																			<div
																				className={`${styles.item} ${
																					styles.card
																				} ${cardLonger ? styles.longer : ''}`}
																				key={link._id}>
																				<div className={`${styles.vCardDiv}`}>
																					{/* <p>
																						{location?.pageSetup?.vcard?.text?.toUpperCase()}
																					</p> */}
																					{editableLink === link._id && (
																						<input
																							disabled={
																								editableLink !==
																								link._id
																							}
																							readOnly={
																								editableLink !==
																								link._id
																							}
																							value={
																								// location?.pageSetup
																								// 	?.vcard?.text
																								vcardText
																							}
																							onChange={(e) => {
																								// changeVCardInfo(
																								// 	'text',
																								// 	e.target.value,
																								// );
																								setVCardText(
																									e.target.value,
																								);
																							}}
																							className={`${
																								styles.title
																							} ${
																								editableLink ===
																								link._id
																									? styles.editable
																									: ''
																							}`}></input>
																					)}
																					{editableLink !== link._id && (
																						<p className={styles.title}>
																							{/* {location?.pageSetup?.vcard?.text?.toUpperCase() ||
																								''} */}
																							{vcardText.toUpperCase() ||
																								''}
																						</p>
																					)}
																					<div className={styles.buttons}>
																						<Switch
																							className={styles.switch}
																							size="small"
																							checked={vcardEnabled}
																							onClick={() => {
																								setVCardEnabled(
																									!vcardEnabled,
																								);
																								changeVCardVisibility(
																									!vcardEnabled,
																								);
																							}}
																						/>
																						<div
																							className={styles.editDiv}
																							hidden={defaultLinkNames.includes(
																								link.name,
																							)}
																							onClick={() => {
																								if (
																									editableLink ===
																									link._id
																								) {
																									let linksValidated =
																										false;
																									let facebookValidated =
																										false;
																									let instagramValidated =
																										false;
																									let yelpValidated =
																										false;
																									let linkedinValidated =
																										false;
																									let twitterValidated =
																										false;
																									let websiteValidated =
																										false;
																									let phoneValidated =
																										false;

																									//Facebook
																									if (vcardFacebook) {
																										facebookValidated =
																											validateFacebook.test(
																												vcardFacebook,
																											);
																										if (
																											!facebookValidated
																										)
																											setFaceHasError(
																												true,
																											);
																									} else {
																										facebookValidated =
																											true;
																									}
																									//Phone
																									if (vcardPhone) {
																										phoneValidated =
																											validatePhone.test(
																												vcardPhone.trim(),
																											);
																										if (
																											!phoneValidated
																										) {
																											setPhoneHasError(
																												true,
																											);
																										} else {
																											phoneValidated =
																												true;
																											setVCardPhone(
																												vcardPhone.trim(),
																											);
																										}
																									}

																									//Instagram
																									if (
																										vcardInstagram
																									) {
																										instagramValidated =
																											validateInstagram.test(
																												vcardInstagram,
																											);
																										if (
																											!instagramValidated
																										)
																											setInstaHasError(
																												true,
																											);
																									} else {
																										instagramValidated =
																											true;
																									}
																									//Yelp
																									if (vcardYelp) {
																										yelpValidated =
																											validateYelp.test(
																												vcardYelp,
																											);
																										if (
																											!yelpValidated
																										)
																											setYelpHasError(
																												true,
																											);
																									} else {
																										yelpValidated =
																											true;
																									}
																									//Linkedin
																									if (vcardLinkedin) {
																										linkedinValidated =
																											validateLinkedin.test(
																												vcardLinkedin,
																											);
																										if (
																											!linkedinValidated
																										)
																											setLinkedinHasError(
																												true,
																											);
																									} else {
																										linkedinValidated =
																											true;
																									}
																									//Twitter
																									if (vcardTwitter) {
																										twitterValidated =
																											validateTwitter.test(
																												vcardTwitter,
																											);
																										if (
																											!twitterValidated
																										)
																											setTwittHasError(
																												true,
																											);
																									} else {
																										twitterValidated =
																											true;
																									}
																									//Website
																									if (vcardWebsite) {
																										websiteValidated =
																											validateWebsite.test(
																												vcardWebsite,
																											);
																										if (
																											!websiteValidated
																										) {
																											setWebHasError(
																												true,
																											);
																										}
																									} else {
																										websiteValidated =
																											true;
																									}

																									if (
																										facebookValidated &&
																										instagramValidated &&
																										yelpValidated &&
																										websiteValidated &&
																										linkedinValidated &&
																										phoneValidated &&
																										twitterValidated
																									) {
																										linksValidated =
																											true;
																									}

																									if (
																										!linksValidated
																									) {
																										console.log(
																											'not valid',
																										);
																										return;
																									}

																									setEditableLink('');
																									changeVCard({
																										text: vcardText,
																										firstName:
																											vcardFirstName,
																										lastName:
																											vcardLastName,
																										enabled:
																											vcardEnabled,
																										companyName:
																											vcardCompanyName,
																										phone: vcardPhone.trim(),
																										email: vcardEmail,
																										website:
																											vcardWebsite,
																										country:
																											vcardCountry,
																										city: vcardCity,
																										street: vcardStreet,
																										zip: vcardZIP,
																										state: vcardState,
																										socials: [
																											{
																												name: 'instagram',
																												link:
																													vcardInstagram ||
																													'',
																											},
																											{
																												name: 'facebook',
																												link:
																													vcardFacebook ||
																													'',
																											},
																											{
																												name: 'linkedin',
																												link:
																													vcardLinkedin ||
																													'',
																											},
																											{
																												name: 'yelp',
																												link:
																													vcardYelp ||
																													'',
																											},
																											{
																												name: 'twitter',
																												link: vcardTwitter,
																											},
																										],
																									});
																									return;
																								}

																								setEditableLink(
																									link._id,
																								);
																								setAllLinks(
																									allLinksCopy,
																								);
																							}}>
																							{editableLink ===
																							link._id ? (
																								<CheckCircleOutline
																									width="21px"
																									height="100%"
																									fill="#4ca084"
																								/>
																							) : (
																								<EditButton
																									height="100%"
																									width="21px"
																								/>
																							)}
																						</div>
																						<div
																							{...provided.dragHandleProps}
																							className={styles.dragDiv}>
																							<BurgerMenu
																								fill="black"
																								height="100%"
																								width="100%"
																								stroke="#000"
																							/>
																						</div>
																					</div>
																				</div>
																				<div
																					className={`${styles.inputDiv} ${styles.multiple}`}>
																					<div className={styles.inputHolder}>
																						<input
																							disabled={
																								editableLink !==
																								link._id
																							}
																							value={vcardFirstName || ''}
																							onChange={(e) => {
																								setVCardFirstName(
																									e.target.value,
																								);
																							}}
																							type="text"
																							placeholder={t('Name')}
																						/>
																					</div>
																					<div className={styles.inputHolder}>
																						<input
																							disabled={
																								editableLink !==
																								link._id
																							}
																							value={vcardLastName || ''}
																							onChange={(e) => {
																								setVCardLastName(
																									e.target.value,
																								);
																							}}
																							type="text"
																							placeholder={t('Surname')}
																						/>
																					</div>
																					<div className={styles.inputHolder}>
																						<input
																							disabled={
																								editableLink !==
																								link._id
																							}
																							value={
																								vcardCompanyName || ''
																							}
																							onChange={(e) => {
																								setVCardCompanyName(
																									e.target.value,
																								);
																							}}
																							type="text"
																							placeholder={t(
																								'Company Name',
																							)}
																						/>
																					</div>

																					<div className={styles.inputHolder}>
																						<input
																							disabled={
																								editableLink !==
																								link._id
																							}
																							value={vcardPhone || ''}
																							onChange={(e) => {
																								setPhoneHasError(false);
																								setVCardPhone(
																									e.target.value,
																								);
																							}}
																							type="text"
																							className={
																								phoneHasError
																									? styles.error
																									: ''
																							}
																							placeholder={t('Phone')}
																						/>
																					</div>
																					<div className={styles.inputHolder}>
																						<input
																							disabled={
																								editableLink !==
																								link._id
																							}
																							value={vcardEmail || ''}
																							onChange={(e) => {
																								setVCardEmail(
																									e.target.value,
																								);
																							}}
																							type="email"
																							placeholder={t('E-mail')}
																						/>
																					</div>
																					<div className={styles.inputHolder}>
																						<input
																							disabled={
																								editableLink !==
																								link._id
																							}
																							className={
																								webHasError
																									? styles.error
																									: ''
																							}
																							value={vcardWebsite || ''}
																							onChange={(e) => {
																								setWebHasError(false);
																								setVCardWebsite(
																									e.target.value,
																								);
																							}}
																							type="text"
																							placeholder={t('Website')}
																						/>
																					</div>

																					<div className={styles.inputHolder}>
																						<input
																							disabled={
																								editableLink !==
																								link._id
																							}
																							value={vcardCountry || ''}
																							onChange={(e) => {
																								setVCardCountry(
																									e.target.value,
																								);
																							}}
																							type="text"
																							placeholder={t('Country')}
																						/>
																					</div>
																					<div className={styles.inputHolder}>
																						<input
																							disabled={
																								editableLink !==
																								link._id
																							}
																							value={vcardCity || ''}
																							onChange={(e) => {
																								setVCardCity(
																									e.target.value,
																								);
																							}}
																							type="text"
																							placeholder={t('Place')}
																						/>
																					</div>
																					<div className={styles.inputHolder}>
																						<input
																							disabled={
																								editableLink !==
																								link._id
																							}
																							value={vcardStreet || ''}
																							onChange={(e) => {
																								setVCardStreet(
																									e.target.value,
																								);
																							}}
																							type="text"
																							placeholder={t(
																								'Street / No',
																							)}
																						/>
																					</div>
																					{cardLonger && (
																						<>
																							<div
																								className={`${styles.inputHolder} ${styles.multiple}`}>
																								<input
																									disabled={
																										editableLink !==
																										link._id
																									}
																									className={
																										styles.narrow
																									}
																									value={
																										vcardZIP || ''
																									}
																									onChange={(e) => {
																										setVCardZIP(
																											e.target
																												.value,
																										);
																									}}
																									type="number"
																									placeholder={t(
																										'ZIP',
																									)}
																								/>
																								<input
																									disabled={
																										editableLink !==
																										link._id
																									}
																									value={
																										vcardState || ''
																									}
																									onChange={(e) => {
																										setVCardState(
																											e.target
																												.value,
																										);
																									}}
																									type="text"
																									placeholder={t(
																										'State',
																									)}
																								/>
																							</div>
																							<div
																								className={
																									styles.inputHolder
																								}>
																								<input
																									disabled={
																										editableLink !==
																										link._id
																									}
																									value={
																										vcardInstagram ||
																										''
																									}
																									className={
																										instaHasError
																											? styles.error
																											: ''
																									}
																									onChange={(e) => {
																										setInstaHasError(
																											false,
																										);
																										setVCardInstagram(
																											e.target
																												.value,
																										);
																									}}
																									type="text"
																									placeholder={
																										'Instagram'
																									}
																								/>
																							</div>
																							<div
																								className={
																									styles.inputHolder
																								}>
																								<input
																									disabled={
																										editableLink !==
																										link._id
																									}
																									className={
																										faceHasError
																											? styles.error
																											: ''
																									}
																									value={
																										vcardFacebook ||
																										''
																									}
																									onChange={(e) => {
																										setFaceHasError(
																											false,
																										);
																										setVCardFacebook(
																											e.target
																												.value,
																										);
																									}}
																									type="text"
																									placeholder={
																										'Facebook'
																									}
																								/>
																							</div>
																							<div
																								className={
																									styles.inputHolder
																								}>
																								<input
																									disabled={
																										editableLink !==
																										link._id
																									}
																									className={
																										twittHasError
																											? styles.error
																											: ''
																									}
																									value={
																										vcardTwitter ||
																										''
																									}
																									onChange={(e) => {
																										setTwittHasError(
																											false,
																										);
																										setVCardTwitter(
																											e.target
																												.value,
																										);
																									}}
																									type="text"
																									placeholder={
																										'Twitter'
																									}
																								/>
																							</div>
																							<div
																								className={
																									styles.inputHolder
																								}>
																								<input
																									disabled={
																										editableLink !==
																										link._id
																									}
																									className={
																										linkedinHasError
																											? styles.error
																											: ''
																									}
																									value={
																										vcardLinkedin ||
																										''
																									}
																									onChange={(e) => {
																										setLinkedinHasError(
																											false,
																										);
																										setVCardLinkedin(
																											e.target
																												.value,
																										);
																									}}
																									type="text"
																									placeholder={
																										'Linkedin'
																									}
																								/>
																							</div>
																							<div
																								className={
																									styles.inputHolder
																								}>
																								<input
																									disabled={
																										editableLink !==
																										link._id
																									}
																									className={
																										yelpHasError
																											? styles.error
																											: ''
																									}
																									value={
																										vcardYelp || ''
																									}
																									onChange={(e) => {
																										setYelpHasError(
																											false,
																										);
																										setVCardYelp(
																											e.target
																												.value,
																										);
																									}}
																									type="text"
																									placeholder={'Yelp'}
																								/>
																							</div>
																						</>
																					)}
																				</div>

																				<div
																					className={styles.moreButtonHolder}>
																					<button
																						onClick={() =>
																							setCardLonger(!cardLonger)
																						}>
																						<ArrowDown
																							className={
																								cardLonger
																									? styles.up
																									: ''
																							}
																							height="5px"
																						/>
																						{!cardLonger
																							? t('Add more')
																							: t('Show less')}
																					</button>
																				</div>
																			</div>
																		</div>
																	)}
																</Draggable>
															);
														}
														return (
															<Draggable
																key={link?._id}
																draggableId={link?._id}
																index={index}>
																{(provided, snapshot) => (
																	<div
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		style={getItemStyle(
																			snapshot.isDragging,
																			provided.draggableProps.style,
																			link.name,
																		)}>
																		<div className={styles.item} key={link._id}>
																			<div
																				className={`${styles.header} ${
																					editableLink === link._id
																						? styles.hasInput
																						: ''
																				}`}>
																				{editableLink === link._id && (
																					<input
																						disabled={
																							editableLink !== link._id
																						}
																						readOnly={
																							editableLink !== link._id
																						}
																						value={linkName}
																						onChange={(e: any) => {
																							setLinkName(e.target.value);
																						}}
																						className={`${styles.title} ${
																							editableLink === link._id
																								? styles.editable
																								: ''
																						}`}></input>
																				)}
																				{editableLink !== link._id && (
																					<p className={styles.title}>
																						{link?.name?.toUpperCase() ||
																							''}
																					</p>
																				)}
																				<div className={styles.buttons}>
																					<div
																						className={styles.editDiv}
																						hidden={defaultLinkNames.includes(
																							link.name,
																						)}
																						onClick={() => {
																							if (
																								editableLink ===
																								link._id
																							) {
																								setEditableLink('');
																								saveLinkName(
																									link._id,
																									linkName,
																								);
																								return;
																							}
																							setInstaHasError(false);
																							setFaceHasError(false);
																							setYelpHasError(false);
																							setWebHasError(false);
																							setLinkedinHasError(false);
																							setPhoneHasError(false);
																							setTwittHasError(false);
																							setVCardText(
																								location?.pageSetup
																									?.vcard?.text,
																							);
																							setVCardFirstName(
																								location?.pageSetup
																									?.vcard?.firstName,
																							);
																							setVCardLastName(
																								location?.pageSetup
																									?.vcard?.lastName,
																							);
																							setVCardEnabled(
																								location?.pageSetup
																									?.vcard?.enabled,
																							);
																							setVCardCompanyName(
																								location?.pageSetup
																									?.vcard
																									?.companyName,
																							);
																							setVCardPhone(
																								location?.pageSetup
																									?.vcard?.phone,
																							);
																							setVCardEmail(
																								location?.pageSetup
																									?.vcard?.email,
																							);
																							setVCardWebsite(
																								location?.pageSetup
																									?.vcard?.website,
																							);
																							setVCardStreet(
																								location?.pageSetup
																									?.vcard?.street,
																							);
																							setVCardCity(
																								location?.pageSetup
																									?.vcard?.city,
																							);
																							setVCardState(
																								location?.pageSetup
																									?.vcard?.state,
																							);
																							setVCardCountry(
																								location?.pageSetup
																									?.vcard?.country,
																							);
																							setVCardZIP(
																								location?.pageSetup
																									?.vcard?.zip,
																							);
																							setVCardFacebook(
																								location?.pageSetup?.vcard?.socials?.find(
																									(e: any) =>
																										e?.name ===
																										'facebook',
																								)?.link,
																							);
																							setVCardInstagram(
																								location?.pageSetup?.vcard?.socials?.find(
																									(e: any) =>
																										e?.name ===
																										'instagram',
																								)?.link,
																							);
																							setVCardLinkedin(
																								location?.pageSetup?.vcard?.socials?.find(
																									(e: any) =>
																										e?.name ===
																										'linkedin',
																								)?.link,
																							);
																							setVCardYelp(
																								location?.pageSetup?.vcard?.socials?.find(
																									(e: any) =>
																										e?.name ===
																										'yelp',
																								)?.link,
																							);
																							setVCardTwitter(
																								location?.pageSetup?.vcard?.socials?.find(
																									(e: any) =>
																										e?.name ===
																										'twitter',
																								)?.link,
																							);
																							setLinkName(link?.name);
																							setEditableLink(link._id);
																							setAllLinks(allLinksCopy);
																						}}>
																						{editableLink === link._id ? (
																							<CheckCircleOutline
																								width="21px"
																								height="100%"
																								fill="#4ca084"
																							/>
																						) : (
																							<EditButton
																								height="100%"
																								width="21px"
																							/>
																						)}
																					</div>
																					<div
																						className={styles.deleteDiv}
																						hidden={defaultLinkNames.includes(
																							link.name,
																						)}
																						onClick={() => {
																							setDeleteLinkID(link._id);
																							setShowDeleteLinkPopup(
																								true,
																							);
																						}}>
																						<TrashCanButton />
																					</div>
																					<div
																						{...provided.dragHandleProps}
																						className={styles.dragIcon}>
																						<BurgerMenu
																							fill="black"
																							height="100%"
																							width="100%"
																							stroke="#000"
																						/>
																					</div>
																				</div>
																			</div>

																			<input
																				type="text"
																				value={currentLink.link || ''}
																				placeholder="URL"
																				onChange={(e: any) =>
																					withPrevState(link, e.target.value)
																				}
																				onBlur={(e: any) =>
																					saveLink(
																						link.name,
																						e.target.value,
																						currentLinkValue,
																					)
																				}
																			/>
																		</div>
																	</div>
																)}
															</Draggable>
														);
												  })
												: null}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
							<div className={styles.newItem}>
								<div className={styles.header}>
									<p className={styles.title}>{t('New Item').toUpperCase()}</p>
									<div className={styles.selectAndAdd}>
										<select
											onChange={(e) => {
												preselectLinkName(e.target.value);
												setPreselectedLinkName(e.target.value);
											}}
											value={preselectedLinkName}
											name="socialMedia"
											id="">
											<option value="">{t('Recommendation')}</option>
											<option value="Website">Website</option>
											<option value="TikTok">TikTok</option>
											<option value="Yelp">Yelp</option>
											<option value="Survey">Survey</option>
											<option value="Q&A">Q&A</option>
											<option value="Linkedin">Linkedin</option>
											<option value="Trustpilot">Trustpilot</option>
											<option value="Twitter">Twitter</option>
											<option value="Yandex">Yandex</option>
											<option value="Baidu">Baidu</option>
											<option value="About Us">About Us</option>
											<option value="Catalog">Catalog</option>
											<option value="Check The Menu">Check The Menu</option>
										</select>
										<button
											className={styles.addBtn}
											onClick={() => addNewLink(newLinkName, newLink)}>
											{t('Add').toUpperCase()}
										</button>
									</div>
								</div>
								<input
									className={`${styles.linkValue} ${newLinkNameErr ? styles.red : ''}`}
									value={newLinkName}
									onChange={(e) => {
										setNewLinkName(e.target.value);
										setNewLinkNameErr(false);
									}}
									type="text"
									placeholder="Name"
								/>
								<input
									className={`${styles.linkValue} ${newLinkErr ? styles.red : ''}`}
									value={newLink}
									onChange={(e) => {
										setNewLink(e.target.value);
										setNewLinkErr(false);
									}}
									type="text"
									placeholder="URL"
								/>
							</div>
						</>
					)}
					{!user?.permissions.includes('client.update') && (
						<>
							{allLinks?.map((item: any) => {
								if (item.name === 'google') {
									return (
										<div
											className={`${styles.draggableItem} ${styles.googleDiv}`}
											id={item._id}
											key={item._id}>
											<div className={styles.header}>
												<p className={styles.title}>{item.name.toUpperCase()}</p>
												<div className={styles.dragAndMap}>
													<button
														disabled={true}
														onClick={() => toggleMap()}
														className={styles.mapButton}>
														{t('Find Place').toUpperCase()}
													</button>
													<div className={styles.dragDiv}>
														<BurgerMenu
															fill="black"
															height="100%"
															width="100%"
															stroke="#000"
														/>
													</div>
												</div>
											</div>
											<input
												value={googleLink || ''}
												className={styles.linkValue}
												disabled
												placeholder="URL"
												type="text"
											/>
										</div>
									);
								}
								if (item.name === 'download app') {
									return (
										<div
											className={`${styles.draggableItem} ${styles.bigger}`}
											id={item._id}
											key={item._id}>
											<div className={styles.header}>
												<p className={styles.title}>{item.name.toUpperCase()}</p>
												<div className={styles.dragDiv}>
													<BurgerMenu fill="black" height="100%" width="100%" stroke="#000" />
												</div>
											</div>
											<input
												className={styles.linkValue}
												value={iosLink?.link || ''}
												disabled
												onChange={(e) => withPrevState(item, e.target.value, 'iosLink')}
												onBlur={(e) =>
													saveLink(
														item.name,
														e.target.value,
														allLinksCopy?.find((e: any) => e.name === 'download app')?.links
															?.appStore,
														'ios',
													)
												}
												placeholder="APP STORE URL"
												type="text"
											/>
											<input
												className={styles.linkValue}
												onChange={(e) => withPrevState(item, e.target.value, 'android')}
												disabled
												onBlur={(e) =>
													saveLink(
														item.name,
														e.target.value,
														allLinksCopy?.find((e: any) => e.name === 'download app')?.links
															?.playStore,
														'android',
													)
												}
												value={playStoreLink?.link || ''}
												placeholder="PLAY STORE URL"
												type="text"
											/>
										</div>
									);
								}
								return (
									<div className={styles.draggableItem} id={item._id} key={item._id}>
										<div className={styles.header}>
											<p className={styles.title}>{item.name.toUpperCase()}</p>
											<div className={styles.dragDiv}>
												<BurgerMenu fill="black" height="100%" width="100%" stroke="#000" />
											</div>
										</div>

										<input
											className={styles.linkValue}
											value={item.link || ''}
											disabled
											onChange={(e) => withPrevState(item, e.target.value)}
											onBlur={(e) =>
												saveLink(
													item.name,
													e.target.value,
													allLinksCopy?.find((e: any) => e.name === item.name).link,
												)
											}
											placeholder="URL"
											type="text"
										/>
									</div>
								);
							})}
						</>
					)}
				</>
			) : null}
		</div>
	);
};
