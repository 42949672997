import { AxiosError } from 'axios';
import api from './api';

export const getClientByID = async (id: string) => {
	try {
		const res = api.get(`/clients/${id}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getClientByID', error);
		throw error;
	}
};

export const getAllClients = async (
	companyID?: string | null,
	skip?: number,
	limit?: number,
	search?: string | null,
	user?: string | null,
) => {
	try {
		let params: any = {};
		if (companyID) params = { ...params, company: companyID };
		if (skip) params = { ...params, skip };
		if (limit) params = { ...params, limit };
		if (search) params = { ...params, search };
		if (user) params = { ...params, user };

		const res = api.get('/clients', { params }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getAllClients', error);
		throw error;
	}
};

export const updateClient = async (clientID: string, data: any) => {
	try {
		const res = api.put(`/clients/${clientID}`, data).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('updateClient', error);
		throw error;
	}
};

export const deleteClient = async (clientID: string) => {
	try {
		const res = api.delete(`/clients/${clientID}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('deleteClient', error);
		throw error;
	}
};

export const createClient = async (data: any) => {
	try {
		const res = api.post('/clients', data).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('CreateClient', error);
		throw error;
	}
};

export const getClientNotes = async (clientID: string) => {
	try {
		const res = api.get(`/clients/${clientID}/notes`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getClientNotes', error);
		throw error;
	}
};

export const createNote = async (clientID: string, content: string) => {
	try {
		const res = await api.post(`/clients/${clientID}/notes`, { content }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('createNote', error);
		throw error;
	}
};

export const editNote = async (clientID: string, noteID: string, content: string) => {
	try {
		const res = await api.patch(`/clients/${clientID}/notes/${noteID}`, { content }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('editNote', error);
		throw error;
	}
};

export const deleteNote = async (clientID: string, noteID: string) => {
	try {
		const res = await api.delete(`/clients/${clientID}/notes/${noteID}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('deleteNote', error);
		throw error;
	}
};

export const getAllDocuments = async (clientID: string) => {
	try {
		const res = await api.get(`/clients/${clientID}/documents`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getAllDocuments', error);
		throw error;
	}
};

export const updateDocument = async (clientID: string, documentID: string, data: any) => {
	try {
		const res = await api.patch(`/clients/${clientID}/documents/${documentID}`, data).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('UpdateDocument', error);
		throw error;
	}
};

export const deleteDocument = async (clientID: string, documentID: string) => {
	try {
		const res = await api.delete(`/clients/${clientID}/documents/${documentID}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('DeleteDocument', error);
		throw error;
	}
};

export const uploadDocument = async (name: string, file: string, clientID: string) => {
	try {
		const data = {
			name,
			file,
		};
		const res = await api.post(`/clients/${clientID}/documents`, data).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('UploadDocument', error);
		throw error;
	}
};

export const getAllLocations = async (ID: string) => {
	try {
		const res = await api.get(`/clients/${ID}/locations`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getAllLocations', error);
		throw error;
	}
};

export const addLocation = async (ID: string, data: any) => {
	try {
		const res = await api.post(`/clients/${ID}/locations`, data).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('AddLocation', error);
		throw error;
	}
};

export const getOneLocation = async (ID: string, locationID: string) => {
	try {
		const res = await api.get(`/clients/${ID}/locations/${locationID}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('GetOneLocation', error);
		throw error;
	}
};

export const updateLocation = async (clientID: string, locationID: string, data: any) => {
	try {
		const res = await api.put(`/clients/${clientID}/locations/${locationID}`, data).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('UpdateLocation', error);
		throw error;
	}
};

export const deleteOneLocation = async (clientID: string, locationID: string) => {
	try {
		const res = await api.delete(`/clients/${clientID}/locations/${locationID}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('DeleteOneLocation', error);
		throw error;
	}
};
export const getAllDevices = async (clientID: string) => {
	try {
		const res = await api.get(`/clients/${clientID}/devices`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getAllDevices', error);
		throw error;
	}
};

export const updateLinkName = async (clientID: string, locationID: string, linkID: string, name: string) => {
	try {
		const res = await api
			.patch(`/clients/${clientID}/locations/${locationID}/links/${linkID}`, { name })
			.then((res) => res.data);
		return res;
	} catch (error) {
		console.log('updateLinkName', error);
		throw error;
	}
};
export const updateDownloadAppText = async (clientID: string, locationID: string, name: string) => {
	try {
		const res = await api
			.patch(`/clients/${clientID}/locations/${locationID}/links/downloadapp/name`, { name })
			.then((res) => res.data);
		return res;
	} catch (error) {
		console.log('updateDownloadAppText', error);
		throw error;
	}
};
export const assignClients = async (clientIds: any, agentId: any) => {
	try {
		const res = await api.post('/clients/reassign', { clientIds, agentId }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('assignClients', error);
		throw error;
	}
};
