import { atom } from 'recoil';

type IUser = {
	_id: string,
	email: string;
	gender: string;
	password: string;
	firstName: string;
	lastName: string;
	avatar: string | null;
	isAdmin: boolean;
	company: any;
	device: any;
	location: string;
	role: {
		_id: string,
		name: string
	};
	permissions: string[];
};

export const userState = atom<IUser | null>({ key: 'user', default: null });
