import api from './api';

export const getUserByID = async (id: string) => {
	try {
		const res = api.get(`/users/${id}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getUserByID', error);
		throw error;
	}
};

export const updateDevice = async (id: string, name: string, deviceId: string) => {
	try {
		const res = await api.patch(`/users/${id}/device`, { name, deviceId }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('updateDevice', error);
		throw error;
	}
};

export const addNewDevice = async (id: string, name: string, deviceId: string) => {
	try {
		const res = await api.put(`/users/${id}/device`, { name, deviceId }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('addNewDevice', error);
		throw error;
	}
};

export const removeDevice = async (id: string, deviceId: string) => {
	try {
		const res = await api.delete(`/users/${id}/device/${deviceId}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('removeDevice', error);
		throw error;
	}
};

export const getAllUsers = async (
	companyID?: string,
	skip?: number,
	limit?: number,
	search?: string,
	role?: string,
	sort?: string,
) => {
	try {
		let params: any = {};
		if (companyID) params = { ...params, company: companyID };
		if (skip || skip === 0) params = { ...params, skip };
		if (limit || limit === 0) params = { ...params, limit };
		if (search) params = { ...params, search };
		if (role) params = { ...params, role };
		if (sort) params = { ...params, sort };
		const res = api.get('/users', { params }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getAllUsers', error);
		throw error;
	}
};
export const getAllUsersList = async (companyID: string) => {
	try {
		let params: any = {};
		params = { ...params, company: companyID, limit: 0 };
		const res = api.get('/users', { params }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getAllUsersList', error);
		throw error;
	}
};

export const updateUser = async (userID: string, data: any) => {
	try {
		const res = api.put(`/users/${userID}`, data).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('updateUser', error);
		throw error;
	}
};

export const deleteUser = async (userID: string) => {
	try {
		const res = api.delete(`/users/${userID}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('deleteUser', error);
		throw error;
	}
};

export const createUser = async (data: any) => {
	try {
		const res = api.post('/users', data).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('createUser', error);
		throw error;
	}
};

export const getUserNotes = async (userID: string) => {
	try {
		const res = api.get(`/users/${userID}/notes`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getUserNotes', error);
		throw error;
	}
};

export const createNote = async (userID: string, content: string) => {
	try {
		const res = await api.post(`/users/${userID}/notes`, { content }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('createNote', error);
		throw error;
	}
};

export const editNote = async (userID: string, noteID: string, content: string) => {
	try {
		const res = await api.patch(`/users/${userID}/notes/${noteID}`, { content }).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('editNote', error);
		throw error;
	}
};

export const deleteNote = async (userID: string, noteID: string) => {
	try {
		const res = await api.delete(`/users/${userID}/notes/${noteID}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.log('deleteNote', error);
		throw error;
	}
};

export const getUsersBellowMe = async () => {
	try {
		const res = await api.get('/users/my').then((res) => res.data);
		return res;
	} catch (error) {
		console.log('getUsersBellowMe', error);
		throw error;
	}
};

export const getMyLocations = async () => {
	try {
		const res = await api.get('/auth/me/locations').then((res) => res.data);
		return res;
	} catch (error) {
		console.error('Get my locations', error);
		throw error;
	}
};
export const getMyOneLocation = async (locationId: string) => {
	try {
		const res = await api.get(`/auth/me/locations/${locationId}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.error('Get my one location', error);
		throw error;
	}
};
export const createMyLocation = async (name: string) => {
	try {
		const res = await api.post('/auth/me/locations/', { name }).then((res) => res.data);
		return res;
	} catch (error) {
		console.error('Create my one location', error);
		throw error;
	}
};
export const activateLocation = async (locationId: string) => {
	try {
		const res = await api.post(`/auth/me/locations/${locationId}/activate`).then((res) => res.data);
		return res;
	} catch (error) {
		console.error('Activate my one location', error);
		throw error;
	}
};
export const deleteOneLocation = async (locationId: string) => {
	try {
		const res = await api.delete(`/auth/me/locations/${locationId}`).then((res) => res.data);
		return res;
	} catch (error) {
		console.error('Delete my one location', error);
		throw error;
	}
};
// export const getAllDocuments = async (userID: string) => {
// 	try {
// 		const res = await api.get(`/users/${userID}/documents`).then((res) => res.data);
// 		return res;
// 	} catch (error) {
// 		console.log('getAllDocuments', error);
// 		throw error;
// 	}
// };

// export const updateDocument = async (userID:string, documentID: string, data:any) => {
// 	try {
// 		const res = await api.patch(`/users/${userID}/documents/${documentID}`, data).then((res) => res.data);
// 		return res;
// 	} catch (error) {
// 		console.log('UpdateDocument', error);
// 		throw error;
// 	}
// };

// export const deleteDocument = async(userID: string, documentID: string) => {
// 	try {
// 		const res = await api.delete(`/users/${userID}/documents/${documentID}`).then((res) => res.data);
// 		return res;
// 	} catch (error) {
// 		console.log('DeleteDocument', error);
// 		throw error;
// 	}
// };

// export const uploadDocument = async(name: string, file: string, userID: string) => {
// 	try {
// 		const data = {
// 			name,
// 			file
// 		};
// 		const res = await api.post(`/users/${userID}/documents`, data).then((res) => res.data);
// 		return res;
// 	} catch (error) {
// 		console.log('UploadDocument', error);
// 		throw error;
// 	}
// };

// export const updateUserNotes = async (userID, notes)
