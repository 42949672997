import { useTranslation } from 'react-i18next';
import styles from './ImagePreview.module.scss';

type ImagePreviewProps = {
	url: string;
	show: boolean;
	setShow: any;
};
const ImagePreview = ({ url, show, setShow }: ImagePreviewProps) => {
	const { t } = useTranslation('translation');
	return (
		<div onClick={() => setShow(false)} className={`${styles.modal} ${show ? styles.enterDone : styles.exit}`}>
			<div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
				<img src={url} className={styles.image} alt="" />

				<div className={styles.footer}>
					<button className={styles.closeBtn} onClick={() => setShow(false)}>
						{t('Close')}
					</button>
				</div>
			</div>
		</div>
	);
};

export default ImagePreview;
