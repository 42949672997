import { createContext, useEffect, useReducer } from 'react';
import { useState } from 'react';
import { Suspense, lazy } from 'react';
import { Routes, Route, Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { userState } from 'recoil/user';
import { getMyInfo } from 'services/auth.service';
import { toastError } from 'services/toast';
import Loading from 'components/Loading/Loading';
import Login from './pages/Login/Login';
import Header from 'components/Header/Header';
import Nav from 'components/Nav/Nav';
import { useTranslation } from 'react-i18next';
import MyDevice from 'pages/My-Device/MyDevice';
import { useLocalStorage } from 'hooks/useLocalStorage';
import LeadsStatistics from 'pages/LeadsStatistics/LeadsStatistics';
import { getAllLeads } from 'services/leads.service';
import SurveyStat from 'pages/My-Device/Surveys/SurveyStat/SurveyStat';

const Landing = lazy(() => import('./pages/Landing/Landing'));
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const Settings = lazy(() => import('./pages/Settings/Settings'));
const Documents = lazy(() => import('./pages/Documents/Documents/Documents'));
const Document = lazy(() => import('./pages/Documents/Document/Document'));
const Clients = lazy(() => import('./pages/Clients/Clients/Clients'));
const Client = lazy(() => import('./pages/Clients/Client/Client'));
const ClientNew = lazy(() => import('./pages/Clients/ClientNew/ClientNew'));
const Leads = lazy(() => import('./pages/Leads/Leads/Leads'));
const Lead = lazy(() => import('./pages/Leads/Lead/Lead'));
const LeadNew = lazy(() => import('./pages/Leads/LeadNew/LeadNew'));
const Users = lazy(() => import('./pages/Users/Users/Users'));
const User = lazy(() => import('./pages/Users/User/User'));
const UserNew = lazy(() => import('./pages/Users/UserNew/UserNew'));

const Companies = lazy(() => import('./pages/Companies/Companies/Companies'));
const CompanyNew = lazy(() => import('./pages/Companies/CompanyNew/CompanyNew'));
const Company = lazy(() => import('./pages/Companies/Company/Company'));
const Devices = lazy(() => import('./pages/Devices/Devices'));

const Router = () => {
	const [loading, setLoading] = useState(true);
	const [leadLoading, setLeadLoading] = useState(false);
	const [user, setUser] = useRecoilState(userState);
	const { t, i18n } = useTranslation('translation');

	const [leadStatusSelected, setLeadStatusSelected] = useState('New Lead');
	const [leadStatisticsStatusSelected, setLeadStatisticsStatusSelected] = useState('New Leads');
	const [storedValue, setStoredValue] = useLocalStorage('language', 'en');
	const [searchParams, setSearchParams] = useSearchParams();
	const [totalLeads, setTotalLeads] = useState(0);
	const [data, setData] = useState([]);
	const [allUsers, setAllUsers] = useState([]);
	const [allUsersCopy, setAllUsersCopy] = useState([]);
	const initialFilterState = {
		pageSize: JSON.parse(searchParams.get('pageSize') || '10') || 10,
		page: 0 || JSON.parse(searchParams.get('page') || '0'),
		agent: '' || searchParams.get('agent'),
		company: '' || searchParams.get('company'),
		search: '' || searchParams.get('search'),
		status: searchParams.get('status') || 'New Lead',
		notReachedStatus:
			searchParams.get('status') !== 'Not reached' ? '' : searchParams.get('notReachedStatus') || 'Not reached',
	};
	const [filters, setFilters] = useReducer(reducer, initialFilterState);

	function reducer(state: any, action: any) {
		if (action.type === 'changePageSize') {
			(async () => {
				setLeadLoading(true);
				let all: any;
				if (searchParams.get('status') === 'All Leads') {
					all = await getAllLeads(
						searchParams.get('company') || state.company,
						0,
						action.newPageSize,
						searchParams.get('search') || state.search,
						'',
						searchParams.get('agent') || state.agent,
					);
				} else {
					if (searchParams.get('status') === 'Not reached') {
						console.log(state.notReachedStatus, 'test');
						all = await getAllLeads(
							searchParams.get('company') || state.company,
							0,
							action.newPageSize,
							searchParams.get('search') || state.search,
							searchParams.get('notReachedStatus') || state.notReachedStatus,
							searchParams.get('agent') || state.agent,
						);
					} else {
						all = await getAllLeads(
							searchParams.get('company') || state.company,
							0,
							action.newPageSize,
							searchParams.get('search') || state.search,
							searchParams.get('status') || state.status,
							searchParams.get('agent') || state.agent,
						);
					}
				}
				setTotalLeads(all.totalDocuments);
				setData(all.data);
				setLeadLoading(false);
			})();
			return {
				...state,
				page: 0,
				pageSize: action.newPageSize,
			};
		}
		if (action.type === 'changePage') {
			(async () => {
				setLeadLoading(true);

				let all: any;
				if (searchParams.get('status') === 'All Leads') {
					all = await getAllLeads(
						searchParams.get('company') || state.company,
						action.newPage * state.pageSize,
						searchParams.get('pageSize') || state.pageSize,
						searchParams.get('search') || state.search,
						'',
						searchParams.get('agent') || state.agent,
					);
				} else {
					if (searchParams.get('status') === 'Not reached') {
						console.log(state.notReachedStatus, 'test');
						all = await getAllLeads(
							searchParams.get('company') || state.company,
							action.newPage * state.pageSize,
							searchParams.get('pageSize') || state.pageSize,
							searchParams.get('search') || state.search,
							searchParams.get('notReachedStatus') || state.notReachedStatus,
							searchParams.get('agent') || state.agent,
						);
					} else {
						all = await getAllLeads(
							searchParams.get('company') || state.company,
							action.newPage * state.pageSize,
							searchParams.get('pageSize') || state.pageSize,
							searchParams.get('search') || state.search,
							searchParams.get('status') || state.status,
							searchParams.get('agent') || state.agent,
						);
					}
				}
				setTotalLeads(all.totalDocuments);
				setData(all.data);
				setLeadLoading(false);
			})();
			return {
				...state,
				page: action.newPage,
			};
		}
		if (action.type === 'changeAgent') {
			(async () => {
				setLeadLoading(true);

				let all: any;
				if (searchParams.get('status') === 'All Leads') {
					all = await getAllLeads(
						searchParams.get('company') || state.company,
						0,
						searchParams.get('pageSize') || state.pageSize,
						searchParams.get('search') || state.search,
						'',
						action.newAgent,
					);
				} else {
					if (searchParams.get('status') === 'Not reached') {
						console.log(state.notReachedStatus, 'test');
						all = await getAllLeads(
							searchParams.get('company') || state.company,
							0,
							searchParams.get('pageSize') || state.pageSize,
							searchParams.get('search') || state.search,
							searchParams.get('notReachedStatus') || state.notReachedStatus,
							action.newAgent,
						);
					} else {
						all = await getAllLeads(
							searchParams.get('company') || state.company,
							0,
							searchParams.get('pageSize') || state.pageSize,
							searchParams.get('search') || state.search,
							searchParams.get('status') || state.status,
							action.newAgent,
						);
					}
				}
				setTotalLeads(all.totalDocuments);
				setData(all.data);
				setLeadLoading(false);
			})();
			return {
				...state,
				page: 0,
				agent: action.newAgent,
			};
		}
		if (action.type === 'changeCompany') {
			if (action.newCompany !== '') {
				const filtered = allUsersCopy?.filter((e: any) => e.company._id === action.newCompany);
				setAllUsers(filtered);
			} else {
				setAllUsers(allUsersCopy);
			}
			(async () => {
				setLeadLoading(true);

				let all: any;
				if (searchParams.get('status') === 'All Leads') {
					all = await getAllLeads(
						action.newCompany,
						0,
						searchParams.get('pageSize') || state.pageSize,
						searchParams.get('search') || state.search,
						'',
						searchParams.get('agent') || state.agent,
					);
				} else {
					if (searchParams.get('status') === 'Not reached') {
						console.log(state.notReachedStatus, 'test');
						all = await getAllLeads(
							action.newCompany,
							0,
							searchParams.get('pageSize') || state.pageSize,
							searchParams.get('search') || state.search,
							searchParams.get('notReachedStatus') || state.notReachedStatus,
							searchParams.get('agent') || state.agent,
						);
					} else {
						all = await getAllLeads(
							action.newCompany,
							0,
							searchParams.get('pageSize') || state.pageSize,
							searchParams.get('search') || state.search,
							searchParams.get('status') || state.status,
							searchParams.get('agent') || state.agent,
						);
					}
				}
				setTotalLeads(all.totalDocuments);
				setData(all.data);
				setLeadLoading(false);
			})();

			if (action.newCompany !== '') {
				return {
					...state,
					page: 0,
					agent: '',
					company: action.newCompany,
				};
			} else {
				return {
					...state,
					page: 0,
					company: action.newCompany,
				};
			}
		}
		if (action.type === 'changeSearch') {
			return {
				...state,
				page: 0,
				search: action.newSearch,
			};
		}
		if (action.type === 'changeStatus') {
			(async () => {
				setLeadLoading(true);

				let all: any;
				if (action.newStatus === 'All Leads') {
					all = await getAllLeads(
						searchParams.get('company') || state.company,
						0,
						searchParams.get('pageSize') || state.pageSize,
						searchParams.get('search') || state.search,
						'',
						searchParams.get('agent') || state.agent,
					);
				} else {
					if (action.newStatus === 'Not reached') {
						all = await getAllLeads(
							searchParams.get('company') || state.company,
							0,
							searchParams.get('pageSize') || state.pageSize,
							searchParams.get('search') || state.search,
							'Not reached',
							searchParams.get('agent') || state.agent,
						);
					} else {
						all = await getAllLeads(
							searchParams.get('company') || state.company,
							0,
							searchParams.get('pageSize') || state.pageSize,
							searchParams.get('search') || state.search,
							action.newStatus,
							searchParams.get('agent') || state.agent,
						);
					}
				}
				setTotalLeads(all.totalDocuments);
				setData(all.data);
				setLeadLoading(false);
			})();
			if (action.newStatus === 'Not reached') {
				return {
					...state,
					page: 0,
					notReachedStatus: 'Not reached',
					status: action.newStatus,
				};
			} else {
				return {
					...state,
					page: 0,
					notReachedStatus: '',
					status: action.newStatus,
				};
			}
		}
		if (action.type === 'changeNotReachedStatus') {
			(async () => {
				const all = await getAllLeads(
					searchParams.get('company') || state.company,
					0,
					searchParams.get('pageSize') || state.pageSize,
					searchParams.get('search') || state.search,
					action.newNotReachedStatus,
					searchParams.get('agent') || state.agent,
				);
				setTotalLeads(all.totalDocuments);
				setData(all.data);
			})();

			return {
				...state,
				page: 0,
				notReachedStatus: action.newNotReachedStatus,
			};
		}
		throw Error('Unknown action.');
	}

	useEffect(() => {
		if (localStorage.getItem('language')) {
			i18n.changeLanguage(storedValue);
		}
	}, [storedValue]);

	useEffect(() => {
		if (!user) {
			(async () => {
				const token = localStorage.getItem('token');
				if (token) {
					const myInfo = await getMyInfo();
					if (myInfo?.role?.name === 'SuperAdmin') {
						myInfo.isAdmin = true;
					} else {
						myInfo.isAdmin = false;
					}
					setUser(myInfo);
				}

				setLoading(false);
			})();
		}
	}, [user]);

	if (loading) return null;

	if (!user)
		return (
			<main className="noLogin">
				<ToastContainer newestOnTop pauseOnFocusLoss={false} />
				<Suspense fallback={<Loading />}>
					<Routes>
						<Route path="/login" element={<Login />} />
						<Route path="*" element={<Navigate to="/login" replace />} />
					</Routes>
				</Suspense>
			</main>
		);

	return (
		<>
			<main className="grid">
				<Header />
				<Nav
					leadStatusSelected={leadStatusSelected}
					setLeadStatusSelected={setLeadStatusSelected}
					setFilters={setFilters}
				/>
				<Suspense fallback={<Loading />}>
					<Routes>
						<Route path="/" element={<Navigate to="/dashboard" replace />} />

						<Route path="/dashboard" element={<Dashboard />} />
						<Route path="/settings" element={<Settings />} />
						<Route path="documents">
							<Route
								index
								element={
									<ProtectedRoute isAllowed={user.permissions.includes('document.read')}>
										<Documents />
									</ProtectedRoute>
								}
							/>
							<Route
								path=":documentID"
								element={
									<ProtectedRoute isAllowed={user.permissions.includes('document.read')}>
										<Document />
									</ProtectedRoute>
								}
							/>
						</Route>
						<Route path="clients">
							<Route
								index
								element={
									<ProtectedRoute isAllowed={user.permissions.includes('client.read')}>
										<Clients />
									</ProtectedRoute>
								}
							/>
							<Route
								path="new"
								element={
									<ProtectedRoute isAllowed={user.permissions.includes('client.create')}>
										<ClientNew />
									</ProtectedRoute>
								}
							/>
							<Route
								path=":clientID"
								element={
									<ProtectedRoute isAllowed={user.permissions.includes('client.read')}>
										<Client />
									</ProtectedRoute>
								}></Route>
						</Route>
						<Route path="leads">
							<Route
								index
								element={
									<ProtectedRoute isAllowed={user.permissions.includes('lead.read')}>
										<Leads
											allUsers={allUsers}
											setAllUsers={setAllUsers}
											allUsersCopy={allUsersCopy}
											setAllUsersCopy={setAllUsersCopy}
											data={data}
											setData={setData}
											totalLeads={totalLeads}
											setTotalLeads={setTotalLeads}
											filters={filters}
											setFilters={setFilters}
											leadLoading={leadLoading}
											setLeadLoading={setLeadLoading}
											leadStatusSelected={leadStatusSelected}
											setLeadStatusSelected={setLeadStatusSelected}
										/>
									</ProtectedRoute>
								}
							/>
							<Route
								path="new"
								element={
									<ProtectedRoute isAllowed={user.permissions.includes('lead.create')}>
										<LeadNew />
									</ProtectedRoute>
								}
							/>
							<Route
								path=":leadID"
								element={
									<ProtectedRoute isAllowed={user.permissions.includes('lead.read')}>
										<Lead />
									</ProtectedRoute>
								}></Route>
						</Route>

						<Route
							path="lead-statistics"
							element={
								<ProtectedRoute isAllowed={true}>
									<LeadsStatistics />
								</ProtectedRoute>
							}
						/>

						<Route path="users">
							<Route
								index
								element={
									<ProtectedRoute isAllowed={user.permissions.includes('user.read')}>
										<Users />
									</ProtectedRoute>
								}
							/>
							<Route
								path="new"
								element={
									<ProtectedRoute isAllowed={user.permissions.includes('user.create')}>
										<UserNew />
									</ProtectedRoute>
								}
							/>
							<Route
								path=":userID"
								element={
									<ProtectedRoute isAllowed={user.permissions.includes('user.read')}>
										<User />
									</ProtectedRoute>
								}
							/>
						</Route>

						<Route path="companies">
							<Route
								index
								element={
									<ProtectedRoute isAllowed={user.permissions.includes('company.read')}>
										<Companies />
									</ProtectedRoute>
								}
							/>
							<Route
								path="new"
								element={
									<ProtectedRoute isAllowed={user.permissions.includes('company.create')}>
										<CompanyNew />
									</ProtectedRoute>
								}
							/>
							<Route
								path=":companyId"
								element={
									<ProtectedRoute isAllowed={user.permissions.includes('company.read')}>
										<Company />
									</ProtectedRoute>
								}
							/>
						</Route>

						<Route path="devices">
							<Route
								index
								element={
									<ProtectedRoute isAllowed={user.permissions.includes('device.read')}>
										<Devices />
									</ProtectedRoute>
								}
							/>
						</Route>
						<Route path="surveys/:surveyId">
							<Route index element={<SurveyStat />} />
						</Route>
						<Route path="my-device">
							<Route index element={<MyDevice />} />
						</Route>

						<Route path="*" element={<Navigate to="/dashboard" replace />} />
					</Routes>
				</Suspense>
			</main>
			<ToastContainer newestOnTop pauseOnFocusLoss={false} />
		</>
	);
};

export default Router;

type ProtectedRouteProps = {
	isAllowed: boolean | undefined;
	redirectPath?: string;
	children?: JSX.Element;
};

const ProtectedRoute = ({ isAllowed, redirectPath = '/dashboard', children }: ProtectedRouteProps) => {
	const { t } = useTranslation('translation');

	if (!isAllowed) {
		toastError(t('You dont have a permission for that'));
		return <Navigate to={redirectPath} replace />;
	}

	return children ? children : <Outlet />;
};
