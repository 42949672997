import axios from 'axios';

const api = axios.create({
	baseURL: process.env.REACT_APP_API,
	headers: {
		'Content-Type': 'application/json',
	},
	// timeout: 30000,
});

api.interceptors.request.use(function (config) {
	const token = localStorage.getItem('token');
	if (config && config.headers && token) {
		config.headers['Authorization'] = token;
	}
	return config;
});

api.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response?.status === 401) {
			localStorage.removeItem('token');

			if (location.pathname !== '/login') {
				location.href = '/login';
			}
		}
		return Promise.reject(error);
	},
);

export default api;
