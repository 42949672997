import styles from './Loading.module.scss';

const Loading = () => {
	return (
		<div className={`${styles.loading}`}>
			<div className={`${styles.loadingio_spinner}`}>
				<div className={`${styles.ldio}`}>
					<div></div>
				</div>
			</div>
		</div>
	);
};

export default Loading;
