import { AxiosError } from 'axios';
import api from './api';

export const uploadFile = async (data: any) => {
	try {
		const res = api.post('/file', data ).then((res) => res.data);
		console.log('res', res);
		return res;
	} catch (error) {
		console.log('uploadFile', error);
		throw error;
	}
};